import toast from 'react-hot-toast';
import type { ICustomError, TRejectValue } from '../types';

export const ErrorChecker = (err: unknown): TRejectValue => {
  let error: TRejectValue = null;

  if (err instanceof Error) {
    const { response, name, message } = err as ICustomError;
    if (response) {
      const [newError] = response.data.errors;
      error = newError;
    }
    if (name && message) {
      error = {
        code: name,
        message,
      };
    }

    error && toast.error(error.message);
  }

  return error;
};
