import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { LoginPage } from '../../../pages/Login';
import { PrivateRoute } from '../../components/PrivateRoute';
import { MainRouter } from '../MainRouter';

export const AppRouter = () => (
  <Router>
    <Switch>
      <Route exact component={LoginPage} path="/login" />
      <PrivateRoute path="/">
        <MainRouter />
      </PrivateRoute>
    </Switch>
  </Router>
);
