import "reflect-metadata";
import React from "react";
import { Provider } from "react-redux";

import App from "./app/main/app";
import store from "./app/stores/store";
import { createRoot } from "react-dom/client";
import "./app/helpers/axios";
import "./styles.scss";

const container = document.getElementById("root");
const root = createRoot(container!);


root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  // </React.StrictMode>
);
