import { DocProperty } from 'typescript-openapi-router';
import { OrganizationRole, Role } from '../model';
import { enumToArray } from '@xtool/enum-to-array';

export class RegisterRequestBody {

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  email: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  password: string;

  @DocProperty({
    type: 'number',
    isRequired: true,
  })
  organizationId: number;

  @DocProperty({
    type: 'string',
    isRequired: true,
    enum: enumToArray(Role),
  })
  role: Role;


  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  firstName?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  lastName?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  phoneNumber?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
    enum: enumToArray(OrganizationRole),
  })
  organizationRole?: OrganizationRole;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  comment?: string;
}
