import React, { FC } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Card } from '../../components/widgets/Card';
import { useAppSelector } from '../../stores/hooks';
import { alertsTranslatePaths } from './translations/alerts';
import { AlertList } from './components/AlertList';
import { CustomCircularProgress } from '../../components/CustomCircularProgress';
import { navbarSelector } from '../Navbar/store/selectors';

import styles from './styles.module.scss';

export const Alerts: FC = (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();

  const { isFetching } = useAppSelector((state) => state.alertList);
  const { deviceInfo, isLoading } = useAppSelector(navbarSelector);

  const navigate = () => {
    history.push('/set-up-alerts');
  };

  return (
    <div>
      {(isLoading || isFetching) && <CustomCircularProgress />}

      <Card title={t(alertsTranslatePaths.alerts_received.title)}>
        {!deviceInfo?.sentAlerts?.length && (
          <div className={styles.alerts__empty_text}>
            <div>{t(alertsTranslatePaths.alerts_received.empty_text.first_sentence)}</div>
            <div>{t(alertsTranslatePaths.alerts_received.empty_text.second_sentence)}</div>
          </div>
        )}
        <Button
          className={styles.alerts__received_button}
          onClick={() => navigate()}
          variant="contained"
        >
          <span className={styles.alerts__received_button_text}>
            {t(alertsTranslatePaths.alerts_received.button)}
          </span>
        </Button>

        <AlertList sentAlerts={deviceInfo?.sentAlerts} />
      </Card>
    </div>
  );
};
