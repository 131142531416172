import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../../stores/types';
import { IInsect } from "../../../../interfaces/insect";

export type getAllInsectsDTO = IInsect[];

export const getAllInsectsAsync = createAsyncThunk<getAllInsectsDTO, void, ThunkConfig>(
  'insects/list',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<{ data: getAllInsectsDTO }>(`/private/insects`);

      return data.data;
    } catch (err) {
      return rejectWithValue(ErrorChecker(err));
    }
  },
);
