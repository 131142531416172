// File: i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';

import TRANSLATIONS_EN from './en/locale.json';
import TRANSLATIONS_RU from './ru/locale.json';
import TRANSLATIONS_UA from './ua/locale.json';
import TRANSLATIONS_RO from './ro/locale.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnEmptyString: false,
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      ru: {
        translation: TRANSLATIONS_RU,
      },
      ua: {
        translation: TRANSLATIONS_UA,
      },
      ro: {
        translation: TRANSLATIONS_RO,
      },
    },
  });

i18n.init({
  interpolation: {
    format: (value, format?: string, lng?: string) => {
      if (value instanceof Date) return moment(value).format(format);
      if (typeof value === 'number') return new Intl.NumberFormat().format(value);
      return value;
    },
  },
});

export { i18n };
