import React, { FC, useState } from "react";
import { Box, IconButton, List, ListItem, ListItemText, Paper, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import classNames from "classnames";

import { clearAlertAsync, deleteAlertAsync } from "../../store/actions";
import { useAppDispatch } from "../../../../stores/hooks";
import { AlertModal } from "../AlertModal";

import styles from "./styles.module.scss";
import { SentAlertModel } from "@fly-workspace/lib-api-interface";

type TProps = {
  sentAlerts?: SentAlertModel[];
};

export const AlertList: FC<TProps> = ({ sentAlerts }: TProps) => {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState<SentAlertModel>();
  const [openDelete, setOpenDelete] = useState(false);

  const cn = {
    alert_list_paper: (el: SentAlertModel) =>
      classNames(styles.alert_list__paper, {
        [styles.active]: el.status === "active"
      })
  };

  const openModalHandler = (element: SentAlertModel, isClear: boolean) => {
    setAlert(element);
    isClear ? setOpen(true) : setOpenDelete(true);
  };

  const onCloseModal = () => {
    setOpen(false);
    setOpenDelete(false);
  };

  const acceptModalClickHandler = async (isClear: boolean) => {
    const body = {
      id: alert?.id,
      type: alert?.type
    };

    if (isClear) {
      await dispatch(
        clearAlertAsync({
          body
        })
      );
    } else {
      await dispatch(
        deleteAlertAsync({
          body
        })
      );
    }

    onCloseModal();
  };

  return (
    <>
      <List className={styles.alert_list}>
        {sentAlerts?.map((el: SentAlertModel, index) => {
          const rows = el.content.split("\n");
          return (
            <Box className={styles.alert_list__container} key={`sentAlerts_${el.id}_${index}`}>
              <Paper className={cn.alert_list_paper(el)}>
                <ListItem
                  alignItems="flex-start"
                  secondaryAction={
                    <Box>
                      {el.status === "active" && (
                        <IconButton color="primary" onClick={() => openModalHandler(el, true)}>
                          <CheckIcon />
                        </IconButton>
                      )}

                      <IconButton onClick={() => openModalHandler(el, false)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  }
                >
                  <ListItemText
                    primary={
                      <Box color="text.primary" paddingRight="3rem">
                        {rows.map((row, ind) => (
                          <React.Fragment key={`row_${row + ind + index}`}>
                            <Typography component="span" variant="body2">
                              {row}
                            </Typography>
                            <br />
                          </React.Fragment>
                        ))}
                      </Box>
                    }
                  />
                </ListItem>
              </Paper>
            </Box>
          );
        })}
      </List>

      <AlertModal
        isOpen={open}
        onClose={onCloseModal}
        onAccept={() => acceptModalClickHandler(true)}
        isClear
      />

      <AlertModal
        isOpen={openDelete}
        onClose={onCloseModal}
        onAccept={() => acceptModalClickHandler(false)}
      />
    </>
  );
};
