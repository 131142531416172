import React, { useState, MouseEvent, ChangeEvent, useEffect } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import {
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TablePagination,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { capitalizeStr } from 'src/app/helpers';

import { IOrganization } from 'src/app/interfaces/organization';
import { OrganizationModal } from './components/organizationModal/index';
import { Card } from '../../components/widgets/Card';
import { TransparentButton } from '../../components/TransparentButton';
import { Button } from '../../components/widgets/Button';
import { EditOrganizationModal } from './components/editOrganizationModal/index';
import { useAppDispatch, useAppSelector } from '../../stores/hooks';
import { organizationListSelector } from './store/selectors';
import { getOrganizationListAsync } from './store/actions/getOrganizationListAsync';
import { useSeparateOrganizationPerPage } from './hooks/useSeparateOrganizationPerPage';
import { CustomCircularProgress } from '../../components/CustomCircularProgress';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Eye from '../../../assets/eye.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Notepad from '../../../assets/notepad.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from './styles.module.scss';

import { organizationTranslatePaths } from './translations';

import { SortDirection } from '../Crops/constants/sortDirection';

export const OrganizationPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { organizationList, isLoading } = useAppSelector(organizationListSelector);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpenOrganizationModal, setIsOpenOrganizationModal] = useState(false);
  const [isOpenEditOrganizationModal, setIsOpenEditOrganizationModal] = useState(false);
  const [currentOrganization, setCurrentOrganization] = useState<IOrganization | null>(null);
  const [isReadonly, setIsReadonly] = useState(false);
  const [sortBy, setSortBy] = useState('sort_by');
  const [direction, setDirection] = useState('direction');
  const [searchQuery, setSearchQuery] = useState('');
  const { organizationForAllPages } = useSeparateOrganizationPerPage({
    organizationList,
    rowsPerPage,
  });

  const handleSelectedOption = (event: SelectChangeEvent) => {
    setSortBy(event.target.value as string);

    dispatch(
      getOrganizationListAsync({
        sort: event.target.value as string,
        sortDirection: direction !== 'direction' ? direction : SortDirection.asc,
      }),
    );
  };

  const handleDirectionOption = (event: SelectChangeEvent) => {
    setDirection(event.target.value as string);

    dispatch(
      getOrganizationListAsync({
        sort: sortBy !== 'sort_by' ? sortBy : 'name',
        sortDirection: event.target.value,
      }),
    );
  };

  const handleSearchOption = (event: string) => {
    dispatch(
      getOrganizationListAsync({
        sort: sortBy !== 'sort_by' ? sortBy : 'name',
        sortDirection: direction !== 'direction' ? direction : SortDirection.asc,
        search: event,
      }),
    );
  };

  useEffect(() => {
    dispatch(getOrganizationListAsync());
  }, [dispatch]);

  const changePageHandler = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const changeRowsPerPageHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const editOrganizationHandler = (organization: IOrganization) => {
    setCurrentOrganization(organization);
    setIsOpenEditOrganizationModal(true);
  };

  const watchOrganizationHandler = (organization: IOrganization) => {
    editOrganizationHandler(organization);
    setIsReadonly(true);
  };

  const onCloseOrganizationModalHandler = () => {
    setIsOpenOrganizationModal(false);
    setCurrentOrganization(null);
    setIsReadonly(false);
  };

  const onCloseEditOrganizationModalHandler = () => {
    setIsOpenEditOrganizationModal(false);
    setCurrentOrganization(null);
    setIsReadonly(false);
  };

  const onAcceptOrganizationModalHandler = () => {
    onCloseOrganizationModalHandler();
  };

  const onAcceptEditOrganizationModalHandler = () => {
    onCloseEditOrganizationModalHandler();
  };

  return (
    <div className={styles.organization}>
      {isLoading && <CustomCircularProgress />}

      <div className={styles.organization__header}>
        <div className={styles.organization__search_container}>
          <Paper component="form" className={styles.organization__search}>
            <IconButton aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  handleSearchOption(event?.target?.value);
                }
              }}
              className={styles.organization__search_input}
              placeholder={t(organizationTranslatePaths.header.search)}
            />
          </Paper>

          <div className={styles.organization__select_container}>
            <Select
              className={styles.organization__select}
              labelId="sort-label"
              id="sort-select"
              label="sort_by"
              value={sortBy}
              onChange={handleSelectedOption}
            >
              <MenuItem value="createdAt">{t(organizationTranslatePaths.sort.created_at)}</MenuItem>
              <MenuItem value="updatedAt">{t(organizationTranslatePaths.sort.updated_at)}</MenuItem>
              <MenuItem value="name">{t(organizationTranslatePaths.sort.name)}</MenuItem>
              <MenuItem className={styles.organization__select_label} value="sort_by">
                {t(organizationTranslatePaths.header.sort_by)}
              </MenuItem>
            </Select>

            <Select
              className={styles.organization__select}
              labelId="direction-label"
              id="direction-select"
              value={direction}
              onChange={handleDirectionOption}
            >
              <MenuItem value={SortDirection.asc}>{capitalizeStr(SortDirection.asc)}</MenuItem>
              <MenuItem value={SortDirection.desc}>{capitalizeStr(SortDirection.desc)}</MenuItem>
              <MenuItem className={styles.organization__select_label} value="direction">
                {t(organizationTranslatePaths.header.direction)}
              </MenuItem>
            </Select>
          </div>
        </div>

        <div className={styles.organization__create_user_wrapper}>
          <Button
            className={styles.organization__create_user}
            onClick={() => setIsOpenOrganizationModal(true)}
          >
            {t(organizationTranslatePaths.header.create_organizations)}
          </Button>
        </div>
      </div>

      <Card className="organization__wrapper" title={t(organizationTranslatePaths.title)}>
        <div className={styles.organization__container}>
          <div className={styles.organization__card_list}>
            {!!organizationForAllPages.length &&
              organizationForAllPages[page].map((organization, index) => (
                <Card
                  className={classNames(styles.organization__card, 'insects__card')}
                  key={`${organization.createdAt}_${index}`}
                >
                  <div className={styles.organization__full_name}>
                    <div>{`${organization?.name}`}</div>
                    <div className={styles.organization__actions}>
                      <TransparentButton onClick={() => watchOrganizationHandler(organization)}>
                        <img src={Eye} alt="eye" />
                      </TransparentButton>
                      <TransparentButton onClick={() => editOrganizationHandler(organization)}>
                        <img src={Notepad} alt="notepad" />
                      </TransparentButton>
                    </div>
                  </div>
                  {organization?.createdAt && (
                    <div>
                      {t(organizationTranslatePaths.organizations_list.created, {
                        value: moment(organization.createdAt).format('DD.MM.YYYY - HH:mm:SS'),
                      })}
                    </div>
                  )}
                  {organization?.updatedAt && (
                    <div>
                      {t(organizationTranslatePaths.organizations_list.updated, {
                        value: moment(organization.updatedAt).format('DD.MM.YYYY - HH:mm:SS'),
                      })}
                    </div>
                  )}
                  {organization?.contractFirstName && organization?.contractLastName && (
                    <div>
                      {t(organizationTranslatePaths.organizations_list.contact_name, {
                        value: `${organization?.contractFirstName} ${organization?.contractLastName}`,
                      })}
                    </div>
                  )}
                  {organization.contractPhoneNumber && (
                    <div>
                      {t(organizationTranslatePaths.organizations_list.phone, {
                        value: organization.contractPhoneNumber,
                      })}
                    </div>
                  )}
                  {organization.contractEmail && (
                    <div>
                      {t(organizationTranslatePaths.organizations_list.email, {
                        value: organization.contractEmail,
                      })}
                    </div>
                  )}
                </Card>
              ))}
          </div>
          <OrganizationModal
            isOpen={isOpenOrganizationModal}
            onAccept={onAcceptOrganizationModalHandler}
            onClose={onCloseOrganizationModalHandler}
            currentOrganization={currentOrganization}
            isReadonly={isReadonly}
          />
          <EditOrganizationModal
            isOpen={isOpenEditOrganizationModal}
            onAccept={onAcceptEditOrganizationModalHandler}
            onClose={onCloseEditOrganizationModalHandler}
            currentOrganization={currentOrganization}
            isReadonly={isReadonly}
          />
          <div>
            <TablePagination
              component="div"
              count={organizationList.length}
              page={page}
              onPageChange={changePageHandler}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={changeRowsPerPageHandler}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
