import React from "react";
import { useAppSelector } from "../../../../stores/hooks";
import { navbarSelector } from "../../../Navbar/store/selectors";
import { GDDChartColor } from "./chart-colors";
import { ChartData } from "@fly-workspace/lib-api-interface";


export const CustomizedDot = (props: any) => {
  const { cx, cy, r, index } = props;
  const { deviceInfo } = useAppSelector(navbarSelector);
  const gddChart = deviceInfo?.charts?.find((chart) => chart.name === "gdd");
  const chartData = gddChart?.data ?? [];

  const getNewFillColor = (index: number, chartData: ChartData[]) => {
    let newFill = GDDChartColor.gddValueColor;
    const dataAtIndex = chartData[index];
    if (!dataAtIndex) {
      return newFill;
    }
    const info = dataAtIndex.info as string[];
    if (info && info.length) {
      return GDDChartColor.alertPredictionColor;
    }
    const sentAlerts = chartData[index]?.sentAlerts as string[];

    if (sentAlerts && sentAlerts.length) {
      return GDDChartColor.sentAlertColor;
    }
    return newFill;
  };

  const getNewR = (index: number, chartData: ChartData[]) => {
    let newR = r / 2;
    const dataAtIndex = chartData[index];
    if (!dataAtIndex) {
      return newR;
    }
    const info = dataAtIndex.info as string[];
    if (info && info.length) {
      return r;
    }
    const sentAlerts = dataAtIndex.sentAlerts as string[];

    if (sentAlerts) {
      return r * 2;
    }

    return newR;
  };

  const newFill = getNewFillColor(index, chartData);
  const newR = getNewR(index, chartData);
  return (
    <circle cx={cx} cy={cy} r={newR} offset={20} stroke={newFill} strokeWidth={1} fill={newFill} />
  );
};
