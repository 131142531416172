import { DocProperty } from 'typescript-openapi-router';

export class ResponseElements {
  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  'x-amz-request-id': string;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  'x-amz-id-2': string;
}
