import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { AddButtons } from "../../components/AddButtons";
import { useAppDispatch, useAppSelector } from "../../stores/hooks";
import { myDeviseListSelector } from "../ManageDevices/store/selectors";
import { getMyDevicesAsync } from "../ManageDevices/store/actions";
import { setAlertSelector } from "./store/selectors";
import { setAlertsAsync } from "./store/actions/setAlertsAsync";
import { getInsectAlerts, getWeatherAlerts, makeCustomInsectData, makeCustomWeatherAlerts } from "./helpers";
import { SetUpInsectAlerts } from "./components/SetUpInsectAlerts";
import { WeatherAlerts } from "./components/WeatherAlerts";
import { WillBeSentTo } from "./components/WillBeSentTo";
import { CustomCircularProgress } from "../../components/CustomCircularProgress";
import { mainSelector } from "../../main/store/selectors";
import { navbarSelector } from "../Navbar/store/selectors";
import { getDeviceInfoAsync } from "../Navbar/store/actions/getDeviceInfoAsync";
import { isEmpty, windowReload } from "../../helpers";
import { defaultWeatherAlerts } from "./constants";

import type { IWeatherAlerts, TCustomInsect } from "./interfaces";

import "./styles.scss";
import { DiseaseAlerts } from "./components/DiseaseAlerts";
import { DeviceInfoModel, DiseaseModel } from "@fly-workspace/lib-api-interface";
import { ICrop } from "../../interfaces/crop";

const hasDiseasesDefined = (crops: ICrop[], deviceInfo: DeviceInfoModel) => {
  const crop = crops.find(c => c.id === deviceInfo.crop?.id);
  if (!crop) {
    return false;
  }
  return crop.diseases?.length !== 0;
};

export const SetUpAlerts: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { isFetching, myDeviceList } = useAppSelector(myDeviseListSelector);
  const { errorMessage, isFetching: setAlertFetching } = useAppSelector(setAlertSelector);
  const { deviceId, language, organizationId, deviceInfo, isLoading } =
    useAppSelector(navbarSelector);
  const { crops } = useAppSelector(mainSelector);

  const [mobilePhoneRecipient, setMobilePhoneRecipient] = useState("");
  const [emailRecipients, setEmailRecipients] = useState("");
  const [sendRate, setSendRate] = useState(false);
  const [insectData, setInsectData] = useState<TCustomInsect[]>([]);
  const [diseaseData, setDiseaseData] = useState<DiseaseModel[]>([]);
  const [currentCrop, setCurrentCrop] = useState<string | undefined>(undefined);
  const [weatherAlerts, setWeatherAlerts] = useState<IWeatherAlerts>(defaultWeatherAlerts);

  useEffect(() => {
    dispatch(getMyDevicesAsync());
  }, [language, organizationId]);

  useEffect(() => {
    const deviceCropId = myDeviceList?.find((el) => el.id === deviceId)?.crop?.id;
    if (deviceCropId) {
      setCurrentCrop(deviceCropId);
    }
  }, [deviceId, myDeviceList]);

  useEffect(() => {
    if (deviceId) {
      dispatch(
        getDeviceInfoAsync({
          pathParams: {
            deviceId
          }
        })
      );
    }
  }, [deviceId]);

  useEffect(() => {
    if (deviceInfo) {
      const deviceCrop = crops.find((crop) => crop.id === deviceInfo?.crop?.id);
      if (!deviceCrop) {
        return;
      }
      const {insects, diseases} = deviceCrop;

      if (insects && diseases) {
        const customInsectData: TCustomInsect[] = [];
        for (const insect of insects) {
          const customData = makeCustomInsectData(insect, deviceInfo);
          customInsectData.push(customData);
        }
        const customWeatherAlerts = makeCustomWeatherAlerts(deviceInfo);

        setWeatherAlerts(isEmpty(customWeatherAlerts) ? defaultWeatherAlerts : customWeatherAlerts);
        setInsectData(customInsectData);
        setDiseaseData(diseases);
        setMobilePhoneRecipient(deviceInfo?.activeAlerts?.mobilePhoneRecipient ?? "");
        setEmailRecipients(deviceInfo?.activeAlerts?.emailRecipients?.[0] ?? "");
        setSendRate(deviceInfo?.activeAlerts?.sendRate === "DAILY");
      }
    }
  }, [deviceInfo]);

  useEffect(() => {
    if (errorMessage) {
      windowReload();
    }
  }, [errorMessage]);

  const handleSetAlerts = async () => {
    if (!deviceId) {
      return;
    }
    const result = await dispatch(
      setAlertsAsync({
        body: {
          alerts: [
            {
              mobilePhoneRecipient,
              emailRecipients: emailRecipients?.split(/[,;]/gm),
              sendRate: sendRate ? "DAILY" : "HOURLY",
              deviceId,
              sendSprayAlerts: true,
              insectAlerts: getInsectAlerts(insectData),
              weatherAlerts: getWeatherAlerts(weatherAlerts)
            }
          ]
        }
      })
    );

    if (setAlertsAsync.fulfilled.match(result)) {
      history.push("/device-data");
    }
  };

  return (
    <div>
      {(isFetching || isLoading || setAlertFetching) && <CustomCircularProgress />}

      <SetUpInsectAlerts
        currentCropId={currentCrop}
        insectData={insectData}
        setInsectData={setInsectData}
      />
      {deviceInfo && hasDiseasesDefined(crops, deviceInfo) && <DiseaseAlerts deviceInfo={deviceInfo} diseases={diseaseData} />}
      <WeatherAlerts weatherAlerts={weatherAlerts} setWeatherAlerts={setWeatherAlerts} />
      <WillBeSentTo
        emailRecipients={emailRecipients}
        mobilePhoneRecipient={mobilePhoneRecipient}
        setMobilePhoneRecipient={setMobilePhoneRecipient}
        sendRate={sendRate}
        setEmailRecipients={setEmailRecipients}
        setSendRate={setSendRate}
      />

      <AddButtons
        toSave={Boolean(deviceId)}
        pending={isFetching || isLoading || setAlertFetching}
        clear={windowReload}
        handleCreate={handleSetAlerts}
      />
    </div>
  );
};
