import { DocProperty, InheritedDoc } from 'typescript-openapi-router';

@InheritedDoc()
export class OrganizationRequestModel {
  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  name?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  createdAt?: Date;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  updatedAt?: Date;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  bankName?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  city?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  comment?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  contractEmail?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  contractFirstName?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  contractLastName?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  contractPhoneNumber?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  country?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  iban?: string;

  @DocProperty({
    type: 'number',
    isRequired: false,
  })
  registrationNumber?: number;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  streetAddress?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  vat?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  zip?: string;
}
