import React, { useState, MouseEvent, ChangeEvent, useEffect } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import {
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TablePagination,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

import { capitalizeStr } from 'src/app/helpers';
import { IInsect } from 'src/app/interfaces/insect';
import { Card } from '../../components/widgets/Card';
import { TransparentButton } from '../../components/TransparentButton';
import { Button } from '../../components/widgets/Button';

import { CropModal } from './components/CropsModal';
import { useAppDispatch, useAppSelector } from '../../stores/hooks';
import { cropListSelector } from './store/selectors';
import { getCropListAsync } from './store/actions/getCropsListAsync';
import { useSeparateCropsPerPage } from './hooks/useSeparateCropsPerPage';
import { CustomCircularProgress } from '../../components/CustomCircularProgress';

import type { ICrops } from './interfaces';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Eye from '../../../assets/eye.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Notepad from '../../../assets/notepad.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from './styles.module.scss';
import './styles.overload.scss';

import { cropTranslatePaths } from './translations';
import { EditCropModal } from './components/EditCropsModal';
import { getAllInsectsAsync } from '../DeviceData/store/actions/getAllInsectsAsync';
import { SortDirection } from './constants/sortDirection';

export const CropsPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { cropList, isLoading } = useAppSelector(cropListSelector);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpenCropModal, setIsOpenCropModal] = useState(false);
  const [isOpenEditCropModal, setIsOpenEditCropModal] = useState(false);
  const [currentCrop, setCurrentCrop] = useState<ICrops | null>(null);
  const [isReadonly, setIsReadonly] = useState(false);
  const [sortBy, setSortBy] = useState('name');
  const [direction, setDirection] = useState('asc');
  const [searchQuery, setSearchQuery] = useState('');
  const { cropForAllPages } = useSeparateCropsPerPage({ cropList, rowsPerPage });

  useEffect(() => {
    const rSort = sortBy;
    const rDirection = direction;
    const rSearch = searchQuery && searchQuery !== '' ? searchQuery : undefined;
    dispatch(
      getCropListAsync({
        sort: rSort,
        sortDirection: rDirection,
        search: rSearch,
      }),
    );
    dispatch(getAllInsectsAsync());
  }, [sortBy, direction, searchQuery, isOpenCropModal, isOpenEditCropModal, page, rowsPerPage]);

  const handleSelectedOption = (event: SelectChangeEvent) => {
    setSortBy(event.target.value as string);
  };

  const handleDirectionOption = (event: SelectChangeEvent) => {
    setDirection(event.target.value as string);
  };

  const handleSearchOption = (event: string) => {
    setSearchQuery(event);
  };

  const changePageHandler = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const changeRowsPerPageHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const editCropHandler = (crop: ICrops) => {
    setCurrentCrop(crop);
    setIsOpenEditCropModal(true);
  };

  const watchCropHandler = (crop: ICrops) => {
    editCropHandler(crop);
    setIsReadonly(true);
  };

  const onCloseCropModalHandler = () => {
    setIsOpenCropModal(false);
    setCurrentCrop(null);
    setIsReadonly(false);
  };
  const onCloseEditCropeModalHandler = () => {
    setIsOpenEditCropModal(false);
    setCurrentCrop(null);
    setIsReadonly(false);
  };

  const onAcceptCropModalHandler = () => {
    onCloseCropModalHandler();
  };
  const onAcceptEditCropModalHandler = () => {
    onCloseEditCropeModalHandler();
  };
  return (
    <div className={styles.crop}>
      {isLoading && <CustomCircularProgress />}

      <div className={styles.crop__header}>
        <div className={styles.crop__search_container}>
          <Paper component="form" className={styles.crop__search}>
            <IconButton aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  handleSearchOption(event?.target?.value);
                }
              }}
              className={styles.crop__search_input}
              placeholder={t(cropTranslatePaths.header.search)}
            />
          </Paper>

          <div className={styles.crop__select_container}>
            <Select
              className={styles.crop__select}
              labelId="sort-label"
              id="sort-select"
              label="sort_by"
              value={sortBy}
              onChange={handleSelectedOption}
            >
              <MenuItem value="createdAt">{t(cropTranslatePaths.sort.created_at)}</MenuItem>
              <MenuItem value="updatedAt">{t(cropTranslatePaths.sort.updated_at)}</MenuItem>
              <MenuItem value="name">{t(cropTranslatePaths.sort.name)}</MenuItem>
              <MenuItem className={styles.crop__select_label} value="sort_by">
                {t(cropTranslatePaths.header.sort_by)}
              </MenuItem>
            </Select>

            <Select
              className={styles.crop__select}
              labelId="direction-label"
              id="direction-select"
              value={direction}
              onChange={handleDirectionOption}
            >
              <MenuItem value={SortDirection.asc}>{capitalizeStr(SortDirection.asc)}</MenuItem>
              <MenuItem value={SortDirection.desc}>{capitalizeStr(SortDirection.desc)}</MenuItem>
              <MenuItem className={styles.crop__select_label} value="direction">
                {t(cropTranslatePaths.header.direction)}
              </MenuItem>
            </Select>
          </div>
        </div>

        <div className={styles.crop__create_user_wrapper}>
          <Button className={styles.crop__create_user} onClick={() => setIsOpenCropModal(true)}>
            {t(cropTranslatePaths.header.create_crop)}
          </Button>
        </div>
      </div>

      <Card className="users__wrapper" title={t(cropTranslatePaths.title)}>
        <div className={styles.crop__container}>
          <div className={styles.crop__card_list}>
            {!!cropForAllPages.length &&
              cropForAllPages[page].map((crop, index) => (
                <Card
                  className={classNames(styles.crop__card, 'users__card')}
                  key={`${crop.createdAt}_${index}`}
                >
                  <div className={styles.crop__full_name}>
                    <div>{`${crop?.name}`}</div>
                    <div className={styles.crop__actions}>
                      <TransparentButton onClick={() => watchCropHandler(crop)}>
                        <img src={Eye} alt="eye" />
                      </TransparentButton>
                      <TransparentButton onClick={() => editCropHandler(crop)}>
                        <img src={Notepad} alt="notepad" />
                      </TransparentButton>
                    </div>
                  </div>
                  {crop?.createdAt && (
                    <div>
                      {t(cropTranslatePaths.crops_list.created, {
                        value: moment(crop.createdAt).format('DD.MM.YYYY - HH:mm:SS'),
                      })}
                    </div>
                  )}
                  {crop?.updatedAt && (
                    <div>
                      {t(cropTranslatePaths.crops_list.updated, {
                        value: moment(crop.updatedAt).format('DD.MM.YYYY - HH:mm:SS'),
                      })}
                    </div>
                  )}
                  {crop?.insects && (
                    <div>
                      {t(cropTranslatePaths.crops_list.pest_insects, {
                        value: crop.insects.map((insect: Partial<IInsect>) => ` ${insect.name}`),
                      })}
                    </div>
                  )}
                </Card>
              ))}
          </div>

          <div>
            <TablePagination
              component="div"
              count={cropList.length}
              page={page}
              onPageChange={changePageHandler}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={changeRowsPerPageHandler}
            />
          </div>
        </div>
      </Card>

      <CropModal
        isOpen={isOpenCropModal}
        onAccept={onAcceptCropModalHandler}
        onClose={onCloseCropModalHandler}
        currentCrop={currentCrop}
      />
      <EditCropModal
        isOpen={isOpenEditCropModal}
        onAccept={onAcceptEditCropModalHandler}
        onClose={onCloseEditCropeModalHandler}
        currentCrop={currentCrop}
      />
    </div>
  );
};
