import type { IOrganization } from "../../../interfaces/organization";
import { usersTranslatePaths } from "../translations";
import { OrganizationRole, Role } from "@fly-workspace/lib-api-interface";

export const initialFields = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  initialPassword: '',
  comment: '',
  adminPassword: '',
  organizationRole: '',
  role: '',
};

export interface IField {
  id: keyof typeof usersTranslatePaths.add_user;
  key: keyof typeof initialFields;
}

export const userFields: IField[] = [
  {
    id: 'first_name',
    key: 'firstName',
  },
  {
    id: 'last_name',
    key: 'lastName',
  },
  {
    id: 'email',
    key: 'email',
  },
  {
    id: 'phone_number',
    key: 'phoneNumber',
  },
  {
    id: 'initial_password',
    key: 'initialPassword',
  }
];

export interface IInitialSelects {
  roleSelect: Role[];
  organizationRole: OrganizationRole[];
  organizations?: IOrganization[];
}

export const initialSelects: IInitialSelects = {
  roleSelect: [Role.user, Role.admin],
  organizationRole: [OrganizationRole.admin, OrganizationRole.editor, OrganizationRole.viewer],
  organizations: [],
};
