import { DocProperty, InheritedDoc } from 'typescript-openapi-router';
import { CropModel } from '../model';
import { ResponseEnvelope } from './response-envelope';

@InheritedDoc()
export class CropListResponseBody extends ResponseEnvelope {

  @DocProperty({
    type: 'object',
    objectType: CropModel.name,
    isRequired: false
  })
  data?: CropModel[];
}
