import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DialogActions, DialogContent, Typography } from '@mui/material';

import { BootstrapDialog, BootstrapDialogTitle } from '../../../../components/Modal';
import { alertsTranslatePaths } from '../../translations/alerts';

type TProps = {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
  isClear?: boolean;
};

export const AlertModal: FC<TProps> = ({ isOpen, onClose, onAccept, isClear }: TProps) => {
  const { t } = useTranslation();

  return (
    <BootstrapDialog aria-labelledby="dialog_clear_title" open={isOpen}>
      <BootstrapDialogTitle id="dialog_clear_title" onClose={onClose}>
        {t(alertsTranslatePaths.alerts_received.modal.title)}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          {isClear
            ? t(alertsTranslatePaths.alerts_received.modal.clear_text)
            : t(alertsTranslatePaths.alerts_received.modal.delete_text)}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          {t(alertsTranslatePaths.alerts_received.modal.buttons.cancel)}
        </Button>
        <Button color="warning" onClick={onAccept}>
          {t(alertsTranslatePaths.alerts_received.modal.buttons.ok)}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};
