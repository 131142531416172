import { cropVariantsTranslatePaths } from '../../../../translations/paths/cropVariants';

export const dashboardTranslatePaths = {
  legend: {
    title: 'dashboard.legend.title',
    pins: {
      success: 'dashboard.legend.pins.success',
      error: 'dashboard.legend.pins.error',
      no_connection: 'dashboard.legend.pins.no_connection',
      virtual: 'dashboard.legend.pins.virtual',
    },
  },
};

export const markerTranslatePaths = {
  ...cropVariantsTranslatePaths,
  marker: {
    serial_number: 'dashboard.marker.serial_number',
    crop: 'dashboard.marker.crop',
    alert_count: 'dashboard.marker.alert_count',
    buttery_level: 'dashboard.marker.buttery_level',
    last_location: 'dashboard.marker.last_location',
  },
};
