import React, { FC } from "react";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import { Button, FormControl, InputLabel, ListItemButton, MenuItem, Select } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import classNames from "classnames";

import * as Styled from "../../styled/DrawerHeader";
import { adminPageNames, ELanguages, languages, pageNames } from "../../constants";
import { navbarTranslatePaths } from "../../translations/navbar";
import { Card } from "../../../../components/widgets/Card";
import { clearNavbarState, setLanguage } from "../../store";
import { i18n } from "../../../../../translations/i18n";
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks";
import { clearState } from "../../../Login/store";
import { navbarSelector } from "../../store/selectors";
import { mainSelector } from "../../../../main/store/selectors";
import { getUserRoleLocalStorage, removeTokenLocalStorage } from "../../../../helpers/localStorage";
import { putPreferLanguageAsync } from "../../store/actions/putPreferLanguageAsync";
import { AdminAccordion } from "../AdminAccordion";

import styles from "./styles.module.scss";

type TProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

export const NavbarDrawer: FC<TProps> = ({ isOpen, setIsOpen }: TProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation();

  const { language, organization } = useAppSelector(navbarSelector);
  const { organizations } = useAppSelector(mainSelector);

  const cn = {
    navbar_drawer: classNames(styles.drawer, 'navbar-drawer'),
  };

  const handleSelectLanguage = (e: SelectChangeEvent<string>) => {
    dispatch(setLanguage(e.target.value as ELanguages));
    dispatch(putPreferLanguageAsync({ preferLanguage: e.target.value }));
    i18n.changeLanguage(e.target.value);
    axios.defaults.headers.common['Accept-Language'] = e.target.value;
  };

  const logoutHandler = () => {
    removeTokenLocalStorage();
    dispatch(clearState());
    dispatch(clearNavbarState());
    history.push('/');
  };

  const handleDrawerClose = () => setIsOpen(false);

  return (
    <Drawer
      className={cn.navbar_drawer}
      variant="temporary"
      anchor="right"
      open={isOpen}
      onClose={handleDrawerClose}
    >
      <Styled.DrawerHeader>
        <IconButton color="inherit" onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Styled.DrawerHeader>
      <Divider />
      <List className={styles.drawer__list_wrapper}>
        <div className={styles.drawer__list}>
          <div>
            <ListItemButton>
              <FormControl fullWidth>
                <InputLabel id="select_language_label">
                  {t(navbarTranslatePaths.language_select.label)}
                </InputLabel>
                <Select
                  labelId="select_language_label"
                  id="select_language"
                  value={language ?? ELanguages.english}
                  label="Language"
                  onChange={handleSelectLanguage}
                >
                  {Object.keys(languages).map((key) => (
                    <MenuItem key={`languages_${key}`} value={key}>
                      {t(navbarTranslatePaths.language_select.languages[key as ELanguages])}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ListItemButton>
            {pageNames.map((item) => (
              <ListItemButton key={`page_names_${item.key}`}>
                <Link to={item.route} onClick={handleDrawerClose} className={styles.drawer__link}>
                  <Card className={styles.drawer__page_name_card}>
                    <Button>{t(navbarTranslatePaths.pages[item.key])}</Button>
                  </Card>
                </Link>
              </ListItemButton>
            ))}

            {getUserRoleLocalStorage() === 'ADMIN' && (
              <AdminAccordion>
                {adminPageNames.map((item) => (
                  <ListItemButton
                    className={styles.drawer__accordion_content}
                    key={`page_names_${item.key}`}
                  >
                    <Link
                      to={item.route}
                      onClick={handleDrawerClose}
                      className={styles.drawer__link}
                    >
                      <Card className={styles.drawer__admin_page_name_card}>
                        <Button>{t(navbarTranslatePaths.admin_pages[item.key])}</Button>
                      </Card>
                    </Link>
                  </ListItemButton>
                ))}
              </AdminAccordion>
            )}
          </div>
          <div>
            <ListItemButton>
              <Card onClick={logoutHandler} className={styles.drawer__page_name_card}>
                <Button>{t(navbarTranslatePaths.log_out)}</Button>
              </Card>
            </ListItemButton>
          </div>
        </div>
      </List>
      <Divider />
    </Drawer>
  );
};
