import { DocProperty } from "typescript-openapi-router";

export class UpdateInsectDataRequest {

  @DocProperty({
    description: "The ISO date when the insect data was recorded",
    type: 'string',
    isRequired: true
  })
  recordedAt: string;

  @DocProperty({
    description: "The new id of the insect",
    type: 'string',
    isRequired: true
  })
  insectId: string;
}
