import React from "react";
import GoogleMapReact, { MapOptions, Maps } from "google-map-react";

import { TeraseyaMarker } from "../TeraseyaMarker";
import { getMapOptions } from "../../helpers/getMapOptions";
import { useAppSelector } from "../../../../stores/hooks";
import { myDeviseListSelector } from "../../../ManageDevices/store/selectors";

import styles from "./styles.module.scss";
import { IMyDeviceData } from "../../../ManageDevices/interfaces/myDeviceData";

const getDefaultCoordinates = (myDeviceList: IMyDeviceData[]) => {
  // the default coordinates is the center of all the devices
  const devicesWithLocation = myDeviceList.filter(d => d.latitude && !isNaN(d.latitude) && d.longitude && !isNaN(d.longitude));
  if (devicesWithLocation.length === 0) {
    return null;
  }
  const latitudes = devicesWithLocation.map((el) => el.latitude!);
  const longitudes = devicesWithLocation.map((el) => el.longitude!);
  const latSum = latitudes.reduce((acc, el) => acc + el, 0);
  const lngSum = longitudes.reduce((acc, el) => acc + el, 0);
  const lat = latSum / latitudes.length;
  const lng = lngSum / longitudes.length;
  return { lat, lng };
};

const getDefaultZoom = (myDeviceList: IMyDeviceData[]) => {
  // the default zoom is the maximum distance between the devices
  const devicesWithLocation = myDeviceList.filter(d => d.latitude && !isNaN(d.latitude) && d.longitude && !isNaN(d.longitude));
  if (devicesWithLocation.length === 0) {
    return null;
  }
  const latitudes = devicesWithLocation.map((el) => el.latitude!);
  const longitudes = devicesWithLocation.map((el) => el.longitude!);
  const latMax = Math.max(...latitudes);
  const latMin = Math.min(...latitudes);
  const lngMax = Math.max(...longitudes);
  const lngMin = Math.min(...longitudes);
  const latDistance = latMax - latMin;
  const lngDistance = lngMax - lngMin;
  const distance = Math.max(latDistance, lngDistance);
  return Math.floor(Math.log2(360 / distance)) + 2;
}

export const Map = () => {

  const { myDeviceList } = useAppSelector(myDeviseListSelector);

  const handleGoogleApiLoaded = (
    map: MapOptions & { setOptions: (maps: MapOptions) => void },
    maps: Maps
  ) => {
    map.setOptions(getMapOptions(maps));
  };

  const defaultCoords = getDefaultCoordinates(myDeviceList);
  console.log(defaultCoords);
  const defaultZoom = getDefaultZoom(myDeviceList);
  console.log(defaultZoom);

  return (
    <div className={styles.map__wrapper}>
      {defaultCoords && defaultZoom && <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.NX_GOOGLE_API_KEY } as { key: string }}
        defaultZoom={defaultZoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleGoogleApiLoaded(map, maps)}
        defaultCenter={defaultCoords}
        hoverDistance={40}
      >
        {myDeviceList.map((el, index) => {
          if (el.latitude && el.longitude) {
            return (
              <TeraseyaMarker
                lat={el.latitude}
                lng={el.longitude}
                key={`${index + el.id}`}
                item={el}
              />
            );
          }
          return null;
        })}
      </GoogleMapReact>}
    </div>
  );
};
