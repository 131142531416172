export const cropTranslatePaths = {
  title: 'crops.title',
  header: {
    search: 'crops.header.search',
    sort_by: 'crops.header.sort_by',
    direction: 'crops.header.direction',
    create_crop: 'crops.header.create_crop',
  },
  sort: {
    created_at: 'crops.sort.created_at',
    updated_at: 'crops.sort.updated_at',
    name: 'crops.sort.name',
  },
  crops_list: {
    created: 'crops.crop_list.created_at',
    updated: 'crops.crop_list.updated_at',
    name: 'crops.crop_list.name',
    size: 'crops.crop_list.size',
    pest_insects: 'crops.crop_list.pest_insects',
  },
  keys: {
    ADMIN: 'crops.keys.ADMIN',
    USER: 'crops.keys.USER',
    VIEWER: 'crops.keys.VIEWER',
    EDITOR: 'crops.keys.EDITOR',
  },
  add_crop: {
    title: 'crops.add_crop.title',
    ok: 'crops.add_crop.ok',
    cancel: 'crops.add_crop.cancel',
    name: 'crops.add_crop.name',
    pest_insects: 'crops.add_crop.pest_insects',
  },
  edit_crop: {
    title: 'crops.edit_crop.title',
    ok: 'crops.edit_crop.ok',
    cancel: 'crops.edit_crop.cancel',
    name: 'crops.edit_crop.name',
    pest_insects: 'crops.edit_crop.pest_insects',
  },
};
