/* eslint-disable  @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { environment } from '../../../environments/environment';

import {
  getLanguageLocalStorage,
  getOrganizationIdLocalStorage,
  getTokenLocalStorage,
} from '../../helpers/localStorage';

export const deviceApiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.apiUrl,
    prepareHeaders(headers) {
      const token = getTokenLocalStorage();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      headers.set('x-api-key', environment.apiKey);

      return headers;
    },
  }),
  endpoints(builder) {
    const organizationId = getOrganizationIdLocalStorage();
    const language = getLanguageLocalStorage();

    return {
      deviceInfo: builder.query<any, any & { deviceId: string }>({
        query(request) {
          const { deviceId, insect, relativeDate } = request;
          return {
            url: `/private/organizations/${organizationId}/devices/${deviceId}`,
            params: {
              insect,
              relativeDate,
            },
            headers: {
              'Accept-Language': language,
            },
          };
        },
      }),
    };
  },
});

export const { useDeviceInfoQuery } = deviceApiSlice;
