import { DocProperty, InheritedDoc } from 'typescript-openapi-router';
import { DeviceCropChangeLogModel } from '../model';
import { ResponseEnvelope } from './response-envelope';

@InheritedDoc()
export class DeviceCropChangeLogResponseBody extends ResponseEnvelope {
  @DocProperty({
    type: 'array',
    objectType: DeviceCropChangeLogModel.name,
    isRequired: false,
  })
  data?: DeviceCropChangeLogModel[];
}
