import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../stores/hooks";
import { navbarSelector } from "../../../Navbar/store/selectors";
import { downloadCSV } from "../../helpers/download-manager";
import { Card } from "../../../../components/widgets/Card";
import { Button } from "@mui/material";
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { CustomTooltip } from "./custom-tooltip";
import { CustomizedDot } from "./custom-dot";
import { CustomLegend } from "./custom-legend";
import { GDDChartColor } from "./chart-colors";
import { Chart, ChartData } from "@fly-workspace/lib-api-interface";

export interface IBasicChartProps {
  deviceId: string;
  chart: Chart;
}

export const GDDChart: FC<IBasicChartProps> = (props:IBasicChartProps) => {
  const { deviceId, chart } = props;
  const { t } = useTranslation();
  const { deviceInfo } = useAppSelector(navbarSelector);

  const onDownloadData = () => {
    if (deviceInfo) {
      downloadCSV("gdd", deviceInfo, deviceId);
    }
  };

  const chartData: ChartData[] = chart?.data ?? [];

  return (
    <Card title={t(`device_data.charts.gdd`)}>
      <Button
        onClick={onDownloadData}
        disabled={!chartData?.length}
      >Download Data</Button>
      {chart && (
        <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
              height={300}
              data={chartData}
              stackOffset="sign"
              margin={{
                top: 5,
                right: 5,
                left: -25,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="5 5" />
              <XAxis dataKey={chart.xKey} />
              <YAxis interval={"preserveStart"} />
              <Tooltip content={<CustomTooltip />} />
              <Legend content={<CustomLegend/>}/>
              <ReferenceLine y={0} stroke="#000" />
              <Line
                strokeWidth="2"
                type="monotone"
                stroke={GDDChartColor.gddValueColor}
                name={t(`device_data.charts.gdd`)}
                dot={<CustomizedDot />}
                dataKey={"gdd"}
              />;
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
    </Card>
  );
};
