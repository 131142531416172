import { createSlice, isPending, isRejectedWithValue } from '@reduxjs/toolkit';

import { getUsersListAsync } from './actions/getUsersListAsync';

import type { TRejectValue } from '../../../stores/types';
import { deleteUserAsync } from './actions/deleteUserAsync';
import { updateUserAsync } from './actions/updateUserAsync';
import { UserModel } from "@fly-workspace/lib-api-interface";

interface IInitialState {
  usersList: UserModel[];
  error?: TRejectValue;
  isLoading: boolean;
}

const initialState: IInitialState = {
  usersList: [],
  error: undefined,
  isLoading: false,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clearUsersState: (state) => {
      state.usersList = [];
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsersListAsync.fulfilled, (state, { payload }) => {
      state.usersList = payload;
      state.isLoading = false;
      state.error = null;
    });

    builder.addMatcher(
      isRejectedWithValue(getUsersListAsync, deleteUserAsync, updateUserAsync),
      (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
      },
    );

    builder.addMatcher(isPending(getUsersListAsync, deleteUserAsync, updateUserAsync), (state) => {
      state.isLoading = true;
      state.error = null;
    });
  },
});

export const { clearUsersState } = usersSlice.actions;
