import React, { FC, PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';

import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';

type TabPanelProps = {
  index: string;
  value: string;
};

export const TabPanel: FC<PropsWithChildren<TabPanelProps>> = ({
  index,
  value,
  children,
  ...rest
}: PropsWithChildren<TabPanelProps>) => {
  const { width } = useWindowDimensions();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}
    >
      {value === index && (
        // Padding around the card
        <Box sx={{ p: width >= 768 ? 2 : 0 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};
