import { ELanguages } from '../pages/Navbar/constants/languages';
import { TUserRoleUnion } from '../pages/Login/dto/loginUser.dto';

export const setTokenLocalStorage = (value: string) => localStorage.setItem('token', value);
export const getTokenLocalStorage = () => localStorage.getItem('token');
export const removeTokenLocalStorage = () => localStorage.removeItem('token');

export const getOrganizationIdLocalStorage = () =>
  Number(localStorage.getItem('organizationId')) ?? -1;
export const setOrganizationIdLocalStorage = (value: string) =>
  localStorage.setItem('organizationId', value);
export const removeOrganizationIdLocalStorage = () => {
  localStorage.removeItem('organizationId');
};

export const getLanguageLocalStorage = (): ELanguages =>
  (localStorage.getItem('i18nextLng')?.split('-')[0] as ELanguages) ?? ELanguages.english;

export const getDeviceIdLocalStorage = () => localStorage.getItem('deviceId') || '';
export const setDeviceIdLocalStorage = (value: string) => localStorage.setItem('deviceId', value);
export const removeDeviceIdLocalStorage = () => {
  localStorage.removeItem('deviceId');
};

export const getUserRoleLocalStorage = (): TUserRoleUnion =>
  localStorage.getItem('userRole') as TUserRoleUnion;
export const setUserRoleLocalStorage = (value: TUserRoleUnion) =>
  localStorage.setItem('userRole', value);
export const removeUserRoleLocalStorage = () => localStorage.removeItem('userRole');
