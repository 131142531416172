import { DocProperty } from 'typescript-openapi-router';

export class UpdateDeviceRequestBody {
  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  alias?: string;

  @DocProperty({
    type: 'number',
    isRequired: false,
  })
  organizationId?: number;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  cropId?: string;

  @DocProperty({
    type: 'boolean',
    isRequired: false,
  })
  isManagedByMe?: boolean;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  comment?: string;

  @DocProperty({
    type: 'string',
    isRequired: false
  })
  initialLocation?: string;
}
