import { DocProperty } from 'typescript-openapi-router';
import { S3EventRecord } from '../model';

export class S3EventRequestBody {
  @DocProperty({
    type: 'array',
    objectType: S3EventRecord.name,
    isRequired: true,
  })
  Records: S3EventRecord[];
}
