import { createSlice, isAsyncThunkAction, isPending, isRejectedWithValue } from '@reduxjs/toolkit';

import { getDeviceListAsync } from './actions/getDeviceListAsync';
import { createDeviceAsync } from './actions/createDeviceAsync';
import type { TRejectValue } from '../../../stores/types';
import { deleteDeviceAsync } from './actions/deleteDeviceAsync';
import { updateDeviceAsync } from './actions/updateDeviceAsync';
import { IDeviceData } from "../../../interfaces/device";

interface IInitialState {
  deviceList: IDeviceData[];
  error?: TRejectValue;
  isLoading: boolean;
}

const initialState: IInitialState = {
  deviceList: [],
  error: undefined,
  isLoading: false,
};

export const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    clearUsersState: (state) => {
      state.deviceList = [];
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDeviceListAsync.fulfilled, (state, { payload }) => {
      state.deviceList = payload;
      state.isLoading = false;
      state.error = null;
    });

    builder.addMatcher(
      isRejectedWithValue(
        getDeviceListAsync,
        deleteDeviceAsync,
        updateDeviceAsync,
        createDeviceAsync,
      ),
      (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
      },
    );

    builder.addMatcher(
      isPending(getDeviceListAsync, deleteDeviceAsync, updateDeviceAsync, createDeviceAsync),
      (state) => {
        state.isLoading = true;
        state.error = null;
      },
    );
    builder.addMatcher(isAsyncThunkAction(createDeviceAsync, updateDeviceAsync), (state) => {
      state.isLoading = false;
      state.error = null;
    });
  },
});

export const { clearUsersState } = deviceSlice.actions;
