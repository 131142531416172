import { EAdminPagesKeys, EPagesKeys } from '../../app/pages/Navbar/constants';

export const mainTranslatePaths = {
  [EPagesKeys.dashboard]: 'dashboard.title',
  [EPagesKeys.manage_devices]: 'manage_devices.title',
  [EPagesKeys.device_data]: 'device_data.title',
  [EPagesKeys.set_up_alerts]: 'set_up_alerts.title',
  [EPagesKeys.alerts]: 'alerts.title',
  [EPagesKeys.pheromones]: 'pheromones.title',
};

export const mainAdminTranslatePaths = {
  [EAdminPagesKeys.admin]: '',
  [EAdminPagesKeys.organization]: 'organization.title',
  [EAdminPagesKeys.users]: 'users.title',
  [EAdminPagesKeys.devices]: 'devices.title',
  [EAdminPagesKeys.crops]: 'crops.title',
  [EAdminPagesKeys.insects]: 'insects.title',
};
