import React, { CSSProperties, FC, PropsWithChildren } from 'react';
import { Box, Divider, Paper, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import SvgIcon from '@mui/material/SvgIcon/SvgIcon';

export type CardProps = {
  Icon: typeof SvgIcon;
  title?: string;
  titleVariant?: Variant;
  margin?: string;
  paddingLeft?: string;
  paddingRight?: string;
  minHeight?: string;
  minWidth?: string;
  width?: string;
  style?: CSSProperties;
  className?: string;
};

export const InfoCard: FC<PropsWithChildren<CardProps>> = ({
  children,
  title,
  titleVariant,
  margin,
  paddingLeft,
  paddingRight,
  minHeight,
  minWidth,
  width,
  style,
  Icon,
  className,
}: PropsWithChildren<CardProps>) => {
  const mt = margin || '0.5rem';
  const mb = margin || '0.5rem';
  const mr = margin || '0rem';
  const ml = margin || '0rem';

  return (
    <Box
      className={className}
      marginTop={mt}
      marginRight={mr}
      marginBottom={mb}
      marginLeft={ml}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      minHeight={minHeight}
      minWidth={minWidth}
      style={style}
      width={width}
      flex="1 0 auto"
    >
      <Paper
        style={{
          backgroundColor: '#f8f8f8',
        }}
      >
        <Box padding="0.5rem" minHeight={minHeight}>
          {title && (
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box mr="0.5rem">
                <Icon />
              </Box>
              <Typography align="left" variant={titleVariant ?? 'h5'}>
                {title}
              </Typography>
              <Divider />
            </Box>
          )}
          {children}
        </Box>
      </Paper>
    </Box>
  );
};
