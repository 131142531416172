import { EAdminPagesKeys, EPagesKeys, ELanguages } from '../constants';

export const navbarTranslatePaths = {
  language_select: {
    label: 'navbar.language_select.label',
    languages: {
      [ELanguages.english]: 'navbar.language_select.languages.english',
      [ELanguages.russian]: 'navbar.language_select.languages.russian',
      [ELanguages.ukrainian]: 'navbar.language_select.languages.ukrainian',
      [ELanguages.romanian]: 'navbar.language_select.languages.romanian',
    },
  },
  pages: {
    [EPagesKeys.dashboard]: 'navbar.pages.dashboard',
    [EPagesKeys.manage_devices]: 'navbar.pages.manage_devices',
    [EPagesKeys.device_data]: 'navbar.pages.device_data',
    [EPagesKeys.set_up_alerts]: 'navbar.pages.set_up_alerts',
    [EPagesKeys.alerts]: 'navbar.pages.alerts',
    [EPagesKeys.pheromones]: 'navbar.pages.pheromones',
  },
  admin_pages: {
    [EAdminPagesKeys.admin]: 'navbar.admin_pages.admin',
    [EAdminPagesKeys.organization]: 'navbar.admin_pages.organization',
    [EAdminPagesKeys.users]: 'navbar.admin_pages.users',
    [EAdminPagesKeys.devices]: 'navbar.admin_pages.devices',
    [EAdminPagesKeys.crops]: 'navbar.admin_pages.crops',
    [EAdminPagesKeys.insects]: 'navbar.admin_pages.insects',
  },
  device_select: {
    organization: 'navbar.device_select.organization',
    select: 'navbar.device_select.select',
    from: 'navbar.device_select.from',
    to: 'navbar.device_select.to',
    helper_text: 'navbar.device_select.helper_text',
  },
  log_out: 'navbar.log_out',
};
