import { DocProperty } from 'typescript-openapi-router';
import { InsectModel } from '../model';
import { ResponseEnvelope } from './response-envelope';

export class InsectListResponse extends ResponseEnvelope {

  @DocProperty({
    type: 'array',
    objectType: InsectModel.name,
    isRequired: false
  })
  data?: InsectModel[];

}
