import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { ErrorChecker } from "../../../stores/helpers/ErrorChecker";

import type { ThunkConfig } from "../../../stores/types";
import { DiseaseModel } from "@fly-workspace/lib-api-interface";

export const getAllDiseasesAsync = createAsyncThunk<DiseaseModel[], void, ThunkConfig>(
  'main/disease-list',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<{ data: DiseaseModel[] }>(`/private/disease`);

      return data.data;
    } catch (err) {
      return rejectWithValue(ErrorChecker(err));
    }
  },
);
