import React from "react";
import { DatePicker, DatePickerProps, LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";

const dateInputFormat = 'dd/MM/yyyy';
const dateInputMask = '__/__/____';

export const CustomDatePicker = <TInputDate, TDate>(props: DatePickerProps<TInputDate, TDate>) => {
  const { width } = useWindowDimensions();
  return <LocalizationProvider dateAdapter={AdapterDateFns}>
    {width < 900 ?
      <MobileDatePicker
        {...props}
        inputFormat={dateInputFormat}
        mask={dateInputMask} /> :
      <DatePicker
        {...props}
        inputFormat={dateInputFormat}
        mask={dateInputMask} />}
  </LocalizationProvider>;
};
