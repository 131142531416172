import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { downloadChartCSV } from "../../helpers/download-manager";
import { Card } from "../../../../components/widgets/Card";
import { Button } from "@mui/material";
import { LineChart } from "./line-chart";
import { Chart, ChartData } from "@fly-workspace/lib-api-interface";
import { MultiLineChart } from "./multi-line-chart";

export interface IBasicChartProps {
  deviceId: string;
  chart: Chart;
  ident?: string;
  chartType?: string;
}

const getChart = (type: string, ident: string, chart: Chart) => {
  switch (type) {
    // by default, it will display line chart
    case "rainGaugeDiff" : {
      return <MultiLineChart chart={chart} />;
    }
    default: {
      return <LineChart chart={chart} ident = {ident}/>;
    }
  }
};

export const BasicChart: FC<IBasicChartProps> = (props: IBasicChartProps) => {
  const { deviceId, chart, ident = chart.ident ? chart.ident : chart.name, chartType = "line"} = props;
  const { t } = useTranslation();

  const onDownloadData = () => {
      downloadChartCSV(chart, deviceId, ident);
  };

  const chartData: ChartData[] = chart?.data;
  return (
    <Card title={t(`device_data.charts.${ident}`)}>
      <Button
        onClick={onDownloadData}
        disabled={!chartData?.length}
      >
        Download Data
      </Button>
      {chart && (
        <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
          {getChart(chartType, ident, chart)}
        </div>
      )}
    </Card>
  );
};
