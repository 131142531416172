export const setUpAlertsInsectAlertsTranslatePaths = {
  title_no_crop: 'set_up_alerts.insect_alerts.title_zero',
  title_plural: 'set_up_alerts.insect_alerts.title_plural',
  title: 'set_up_alerts.insect_alerts.title',
  text: 'set_up_alerts.insect_alerts.text',
  at_first_occurrence: 'set_up_alerts.insect_alerts.at_first_occurrence',
  threshold: 'set_up_alerts.insect_alerts.threshold',
  conditions: 'set_up_alerts.insect_alerts.conditions',
  pheromones: 'set_up_alerts.insect_alerts.pheromones',
  egg_laying: 'set_up_alerts.insect_alerts.egg_laying',
  spray: 'set_up_alerts.insect_alerts.spray',
};
