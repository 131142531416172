import { createSlice } from '@reduxjs/toolkit';

import { getMyDevicesAsync, addMyDevicesAsync } from './actions';

import type { TRejectValue } from '../../../stores/types';
import type { IMyDeviceData } from '../interfaces/myDeviceData';

interface IInitialState {
  myDeviceList: IMyDeviceData[];
  isFetching: boolean;
  errorMessage?: TRejectValue;
  isAdding: boolean;
  isSuccessAdded: boolean;
  isFailedToAdd: boolean;
}

const initialState: IInitialState = {
  myDeviceList: [],
  isFetching: false,
  errorMessage: undefined,
  isAdding: false,
  isSuccessAdded: false,
  isFailedToAdd: false,
};

export const myDevicesListSlice = createSlice({
  name: 'myDevicesList',
  initialState,
  reducers: {
    clearMyDevicesState: (state) => {
      state.isFetching = false;
      state.errorMessage = null;
      state.isFetching = false;
      state.isSuccessAdded = false;
      state.isFailedToAdd = false;
    },
    resetMyDevicesData: (state) => {
      state.myDeviceList = [...state.myDeviceList];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyDevicesAsync.fulfilled, (state, { payload }) => {
      state.myDeviceList = payload.map((el) => ({
        ...el,
        cropName: el.crop ? el.crop.name : null,
      }));
      state.isFetching = false;
      state.errorMessage = null;
    });

    builder.addCase(getMyDevicesAsync.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.errorMessage = payload;
    });

    builder.addCase(getMyDevicesAsync.pending, (state) => {
      state.isFetching = true;
      state.errorMessage = null;
    });

    builder.addCase(addMyDevicesAsync.fulfilled, (state, { payload }) => {
      state.myDeviceList = payload.map((el) => ({
        ...el,
        cropName: el.crop ? el.crop.name : null,
      }));
      state.isAdding = false;
      state.isSuccessAdded = true;
    });

    builder.addCase(addMyDevicesAsync.rejected, (state, { payload }) => {
      state.isAdding = false;
      state.isFailedToAdd = true;
      state.errorMessage = payload;
    });

    builder.addCase(addMyDevicesAsync.pending, (state) => {
      state.isAdding = true;
    });
  },
});

export const { clearMyDevicesState, resetMyDevicesData } = myDevicesListSlice.actions;
