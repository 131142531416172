import { DocProperty, InheritedDoc } from 'typescript-openapi-router';
import { ResponseEnvelope } from ".";
import { DeviceInfoModel } from '../model';

@InheritedDoc()
export class DeviceInfoResponseBody extends ResponseEnvelope {

  @DocProperty({
    type: 'object',
    objectType: DeviceInfoModel.name,
    isRequired: false
  })
  data?: DeviceInfoModel;
}
