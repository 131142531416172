import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails, { AccordionDetailsProps } from '@mui/material/AccordionDetails';
import { Button, ListItemButton } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import { useTranslation } from 'react-i18next';
import { navbarTranslatePaths } from '../../translations/navbar';
import { EAdminPagesKeys } from '../../constants';

import styles from './styles.module.scss';
import './style.overload.scss';

const Accordion = (props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
);
const AccordionSummary = (props: AccordionSummaryProps) => (
  <ListItemButton>
    <MuiAccordionSummary
      className={classNames('custom-accordion__summary', styles.accordion_summary)}
      expandIcon={<ExpandMore />}
      {...props}
    />
  </ListItemButton>
);
const AccordionDetails = (props: AccordionDetailsProps) => (
  <MuiAccordionDetails style={{ padding: 0 }} {...props} />
);

type TProps = {
  children: ReactNode;
};

export const AdminAccordion: FC<TProps> = ({ children }: TProps) => {
  const { t } = useTranslation();

  return (
    <Accordion className="custom-accordion">
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Button>{t(navbarTranslatePaths.admin_pages[EAdminPagesKeys.admin])}</Button>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
