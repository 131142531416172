import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChartManager } from "../../helpers";
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { Chart, SensorSource } from "@fly-workspace/lib-api-interface";
import { ChartColor } from "../chart-color";

interface ILineChartProps {
  chart: Chart;
}

export const MultiLineChart: FC<ILineChartProps> = (props: ILineChartProps) => {
  const { chart } = props;
  const { t } = useTranslation();

  const [minValue, setMinValue] = useState(Infinity);
  const [maxValue, setMaxValue] = useState(-Infinity);

  const chartData = useMemo(() => {
    let min = 0;
    let max = 0;

    const data = chart.data.map((item) => {
      if (item.minValue && item.minValue < min) {
        min = item.minValue;
      }

      if (item.maxValue && item.maxValue > max) {
        max = item.maxValue;
      }

      return item;
    });

    setMinValue(min);
    setMaxValue(max);
    return data;
  }, [chart.data]);

  const interval = ChartManager.getSizeBasedInterval(maxValue);
  const ticks = ChartManager.getSizeBasedTicks(interval, minValue, maxValue);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart
        height={300}
        data={chartData}
        stackOffset="sign"
        margin={{
          top: 5,
          right: 5,
          left: -25,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="5 5" />
        <XAxis dataKey={chart.xKey} />
        <YAxis interval={"preserveStart"} ticks={ticks} />
        <Tooltip />
        <Legend />
        <ReferenceLine y={0} stroke="#000" />
        <Line
          strokeWidth="2"
          type="monotone"
          stroke={ChartColor.BASE_COLOR}
          name={t(`device_data.charts.${chart.name}_external`)}
          dataKey={SensorSource.external}
        />
        <Line
          strokeWidth="2"
          type="monotone"
          stroke={ChartColor.DEVICE_SENSOR_COLOR}
          name={t(`device_data.charts.${chart.name}_device`)}
          dataKey={SensorSource.device}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
