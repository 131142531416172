import React, { FC, SyntheticEvent, useEffect } from "react";

import { clearMyDevicesState } from "../ManageDevices/store/myDevicesListSlice";
import { useAppDispatch, useAppSelector } from "../../stores/hooks";
import { myDeviseListSelector } from "../ManageDevices/store/selectors";
import { getMyDevicesAsync } from "../ManageDevices/store/actions";
import { DeviceAlerts } from "./Components/AlertDeviceComponent";
import { InsectChartCard } from "./Components/InsectDeviceComponent";
import { DeviceInfo } from "./Components/InfoDeviceComponent";
import { getAllInsectsAsync } from "./store/actions/getAllInsectsAsync";
import { navbarSelector } from "../Navbar/store/selectors";
import { clearNavbarState } from "../Navbar/store";
import { CustomCircularProgress } from "../../components/CustomCircularProgress";

import styles from "./styles.module.scss";
import { BasicChart } from "./Components/BasicChart";
import { deviceDataSelector, sensorSourceSelector } from "./store/selectors";
import { selectDeviceInsect, selectSensorSource } from "./store";
import { GDDChart } from "./Components/GDDChart";
import { Chart, DeviceInfoModel, SensorSource } from "@fly-workspace/lib-api-interface";
import { Card } from "../../components/widgets/Card";
import { enumToArray } from "@xtool/enum-to-array";
import { Autocomplete, Popper, TextField } from "@mui/material";
import { deviceWeatherTranslatePaths } from "./translations";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { useTranslation } from "react-i18next";

const shouldDisplayChart = (chart?: Chart) => {
  return !!chart?.data?.length;
};

const shouldDisplayInsectChart = (insect: string, deviceInfo: DeviceInfoModel, isLoading: boolean) => {
  const data = deviceInfo.charts?.find((chart) => chart.name === insect)?.data;
  return (insect === "all" && data && data.length > 0 || isLoading) || insect !== "all";
};

export const DeviceData: FC = () => {
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  const { errorMessage } = useAppSelector(myDeviseListSelector);
  const { deviceInfo, isLoading, language, organizationId, error, deviceId } = useAppSelector(navbarSelector);
  const sensorSource = useAppSelector(sensorSourceSelector);
  const { insect } = useAppSelector(deviceDataSelector);

  useEffect(() => {
    dispatch(getMyDevicesAsync());
  }, [language, organizationId]);

  useEffect(() => {
    dispatch(getAllInsectsAsync());
  }, [language]);

  useEffect(
    () => () => {
      dispatch(clearMyDevicesState());
    },
    []
  );

  useEffect(() => {
    if (errorMessage || error) {
      dispatch(clearMyDevicesState());
      dispatch(clearNavbarState());
      dispatch(selectDeviceInsect(null));
    }
  }, [errorMessage, error]);

  const onSensorSourceChanged = (event: SyntheticEvent, value: string | null) => {
    if (value) {
      dispatch(selectSensorSource(value));
    }
  };

  return (
    <div className={styles.device_data}>
      {isLoading && <CustomCircularProgress />}

      {deviceInfo && deviceId ? (
        <>
          <DeviceInfo />
          <DeviceAlerts />
          <Card>
            <Autocomplete
              id="data_source"
              value={sensorSource}
              fullWidth={true}
              size={width < 900 ? "small" : "medium"}
              onChange={onSensorSourceChanged}
              options={enumToArray(SensorSource).filter((source) => source !== SensorSource.mixed)}
              PopperComponent={(props) => (
                <Popper {...props} style={{ width: width < 900 ? width : 400 }} placement={"auto-start"} />
              )}
              renderInput={(params) => <TextField {...params} label={t(deviceWeatherTranslatePaths.dataSource)} />}
            />

          </Card>
          {deviceInfo.charts?.map((chart) => {
            // if (chart.name !== 'dswrfMax' && chart.name !== 'dswrfNet' && chart.name !== 'dswrfAvg') {
            //   return null;
            // }
            if (!shouldDisplayChart(chart)) {
              return null;
            }
            if (chart.name === "insect") {
              return <InsectChartCard />;
            }
            if (chart.name === "gdd") {
              return <GDDChart deviceId={deviceId} chart={chart} />;
            }
            return <BasicChart deviceId={deviceId} chart={chart} />;
          })}
        </>
      ) : (
        !isLoading && <div>Please select a device or change an existing one</div>
      )}
    </div>
  );
};
