import { DocProperty, InheritedDoc } from 'typescript-openapi-router';
import { ResponseEnvelope } from './response-envelope';
import { AuthenticationModel } from '../model';

@InheritedDoc()
export class RegisterResponseBody extends ResponseEnvelope {
  @DocProperty({
    type: 'object',
    objectType: AuthenticationModel.name,
    isRequired: true
  })
  data?: AuthenticationModel;
}
