import React, { useMemo } from 'react';
import { CssBaseline } from '@mui/material';
import * as locales from '@mui/material/locale';
import { Toaster } from 'react-hot-toast';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { theme } from '../helpers/theme';
import { useAppSelector } from '../stores/hooks';
import { AppRouter } from './router/AppRouter';
import { ELocalesLanguages } from './constants';
import { navbarSelector } from '../pages/Navbar/store/selectors';

import './app.scss';

function App() {
  const { language } = useAppSelector(navbarSelector);

  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[ELocalesLanguages[language]]),
    [language, theme],
  );

  return (
    <ThemeProvider theme={themeWithLocale}>
      <div className="App">
        <CssBaseline />
        <AppRouter />
      </div>
      <Toaster />
    </ThemeProvider>
  );
}

export default App;
