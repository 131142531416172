import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { setTokenLocalStorage } from '../../../../helpers/localStorage';
import { setOrganizationId } from '../../../Navbar/store';
import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../../stores/types';
import type { loginUserDTO, loginUserRequestDTO } from '../../dto/loginUser.dto';

export const loginUserAsync = createAsyncThunk<loginUserDTO, loginUserRequestDTO, ThunkConfig>(
  'user/login',
  async ({ email, password }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.post<{ data: loginUserDTO }>(`/private/auth/login`, {
        email,
        password,
      });

      setTokenLocalStorage(data.data.authToken);
      dispatch(setOrganizationId(data.data.organizationId.toString()));

      return data.data;
    } catch (err) {
      return rejectWithValue(ErrorChecker(err));
    }
  },
);
