import { DocProperty } from "typescript-openapi-router";
import { enumToArray } from "@xtool/enum-to-array";
import { SendRate } from "./alert-send-rate";
import { InsectAlertModel } from "./insect-alert-model";
import { WeatherAlertModel } from "./weather-alert-model";
import { DiseaseAlertModel } from "./disease-alert-model";

export class ActiveAlertModel {

  @DocProperty({
    type: "string",
    isRequired: false
  })
  mobilePhoneRecipient?: string;

  @DocProperty({
    type: "array",
    objectType: "string",
    isRequired: false
  })
  emailRecipients?: string[];

  @DocProperty({
    type: "string",
    isRequired: true,
    enum: enumToArray(SendRate)
  })
  sendRate: SendRate;

  @DocProperty({
    type: "string",
    isRequired: true
  })
  deviceId: string;

  @DocProperty({
    type: "array",
    objectType: InsectAlertModel.name,
    isRequired: false
  })
  insectAlerts?: InsectAlertModel[];

  @DocProperty({
    type: "array",
    objectType: WeatherAlertModel.name,
    isRequired: false
  })
  weatherAlerts?: WeatherAlertModel[];

  @DocProperty({
    type: "array",
    objectType: DiseaseAlertModel.name,
    isRequired: false
  })
  diseaseAlerts?: DiseaseAlertModel[];
}
