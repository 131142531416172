import React, { FC } from 'react';
import { CircularProgress } from '@mui/material';
import { CircularProgressProps } from '@mui/material/CircularProgress/CircularProgress';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from './styles.module.scss';

export const CustomCircularProgress: FC<CircularProgressProps> = ({
  ...props
}: CircularProgressProps) => (
  <div className={styles.progress__wrapper}>
    <CircularProgress className={styles.progress__circle} {...props} />
  </div>
);
