export enum EPagesKeys {
  dashboard = 'dashboard',
  manage_devices = 'manage_devices',
  device_data = 'device_data',
  set_up_alerts = 'set_up_alerts',
  alerts = 'alerts',
  pheromones = 'pheromones',
}

export enum EAdminPagesKeys {
  organization = 'organization',
  admin = 'admin',
  users = 'users',
  devices = 'devices',
  crops = 'crops',
  insects = 'insects',
}

export const pageNames = [
  {
    key: EPagesKeys.dashboard,
    route: '/',
  },
  {
    key: EPagesKeys.manage_devices,
    route: '/manage-devices/my-devices',
  },
  {
    key: EPagesKeys.device_data,
    route: '/device-data',
    withDeviceSelect: true,
  },
  {
    key: EPagesKeys.set_up_alerts,
    route: '/set-up-alerts',
    withDeviceSelect: true,
  },
  {
    key: EPagesKeys.alerts,
    route: '/alerts',
    withDeviceSelect: true,
  },
  // TODO: add the pheromones page back after fix
  // {
  //   key: EPagesKeys.pheromones,
  //   route: '/pheromones',
  //   withDeviceSelect: true,
  // },
];

export const adminPageNames = [
  {
    key: EAdminPagesKeys.organization,
    route: '/organization',
  },
  {
    key: EAdminPagesKeys.users,
    route: '/users',
  },
  {
    key: EAdminPagesKeys.devices,
    route: '/devices',
  },
  {
    key: EAdminPagesKeys.crops,
    route: '/crops',
  },
  {
    key: EAdminPagesKeys.insects,
    route: '/insects',
  },
];
