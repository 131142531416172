import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../../stores/types';
import { ICreateCropRequestDTO } from '../../dto/createCropsRequest.dto';

export const createCropsAsync = createAsyncThunk<null, ICreateCropRequestDTO, ThunkConfig>(
  'crop/createCrop',
  async (cropData, { rejectWithValue }) => {
    try {
      await axios.post(`/private/crops`, {
        ...cropData,
      });

      return null;
    } catch (err) {
      return rejectWithValue(ErrorChecker(err));
    }
  },
);
