import { DocProperty } from 'typescript-openapi-router';

export class LoginRequestBody {

  @DocProperty({
    type: 'string',
    isRequired: true,
    minSize: 5,
    maxSize: 50
  })
  email: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
    minSize: 5,
    maxSize: 50
  })
  password: string;
}
