import { OrganizationRole } from "./organization-role";
import { enumToArray } from "@xtool/enum-to-array";
import { DocProperty } from "typescript-openapi-router";
import { Role } from "./role";

export class UserModel {
  @DocProperty({
    type: "number",
    isRequired: false
  })
  id?: number;

  @DocProperty({
    type: "string",
    isRequired: false
  })
  firstName?: string;

  @DocProperty({
    type: "string",
    isRequired: false
  })
  lastName?: string;

  @DocProperty({
    type: "string",
    isRequired: false
  })
  createdAt?: string | null;

  @DocProperty({
    type: "string",
    isRequired: false
  })
  updatedAt?: string;

  @DocProperty({
    type: "string",
    isRequired: false,
    enum: enumToArray(Role)
  })
  role: Role;

  @DocProperty({
    type: "string",
    isRequired: false,
    enum: enumToArray(OrganizationRole)
  })
  organizationRole: OrganizationRole;

  @DocProperty({
    type: "number",
    isRequired: true
  })
  organizationId: number;

  @DocProperty({
    type: "string",
    isRequired: false
  })
  phoneNumber?: string;

  @DocProperty({
    type: "string",
    isRequired: false
  })
  comment?: string;

  @DocProperty({
    type: "string",
    isRequired: true
  })
  email: string;

  @DocProperty({
    type: "string",
    isRequired: false
  })
  password?: string;

}
