import { DocProperty } from "typescript-openapi-router";

export class DiseaseAlertModel {
  @DocProperty({
    type: "string",
    isRequired: true
  })
  diseaseId: string;

  @DocProperty({
    type: "string",
    isRequired: true
  })
  name: string;

  @DocProperty({
    type: "boolean",
    isRequired: true
  })
  initial?: boolean;

  @DocProperty({
    type: "boolean",
    isRequired: true
  })
  secondary?: boolean;
}
