import React, { FC, useEffect, useState } from 'react';
import { useHistory, Route, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { Card } from '../../components/widgets/Card';
import { tabsNamesTranslatePaths } from './translations';
import { a11yProps } from './helpers';
import { TabPanel } from './components/TabPanel';
import { tabs } from './constants';

import styles from './styles.module.scss';

export const ManageDevices: FC<RouteComponentProps> = ({ match }: RouteComponentProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  const navigation = history.location.pathname.split('/');
  const tab =
    `/${navigation[navigation.length - 1]}` === '/manage-devices'
      ? ''
      : `/${navigation[navigation.length - 1]}`;

  const [value, setValue] = useState(tab);

  useEffect(() => {
    setValue(tab);
  }, [history.location]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    history.push(match.path + newValue);
  };

  return (
    <div className="manage-devices-container">
      <Card minHeight="85vh">
        <Box className={styles.manage_devices__tabs_wrapper}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="tabs-container"
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs.map(({ name, path }) => (
              <Tab
                key={`${name}_${path}`}
                label={t(tabsNamesTranslatePaths[name])}
                value={path}
                className={styles.manage_devices__tab}
                {...a11yProps(path)}
              />
            ))}
          </Tabs>
        </Box>
        {tabs.map(({ path, component }) => (
          <TabPanel key={`tab_panel_${path}`} value={value} index={path}>
            <div className={styles.manage_devices__tab_panel}>
              <Route exact component={component as any} path={`/manage-devices${path}`} />
            </div>
          </TabPanel>
        ))}
      </Card>
    </div>
  );
};
