import { useMemo } from "react";
import moment from "moment";
import { ONE_DAY } from "../constants";
import { IMyDeviceData } from "../../ManageDevices/interfaces/myDeviceData";
import { formatToDate, shiftDateByTZ } from "../../../../../../../libs/common-utils/src";

export const useMemoizedMarkerValues = (item: IMyDeviceData) => {
  const text = useMemo(() => item?.alias ?? item?.id, [item?.alias, item?.id]);
  const alertCount = useMemo(
    () => Number(Number(item?.alertCount) > 0 ? item?.alertCount : null),
    [item?.alertCount]
  );
  const lastLocationRecorded = useMemo(
    () => formatToDate(shiftDateByTZ(new Date(item?.lastLocationRecorded))),
    [item?.lastLocationRecorded]
  );

  const lastLocationRecordedDate = useMemo(
    () => item?.lastLocationRecorded ? new Date(item?.lastLocationRecorded) : undefined,
    [item?.lastLocationRecorded]
  );
  const hasUpdatedLocation = useMemo(
    () =>
      lastLocationRecordedDate &&
      lastLocationRecordedDate.getTime() > new Date().getTime() - ONE_DAY,
    [lastLocationRecorded]
  );

  const hasRedIcon = useMemo(() => alertCount > 0 && !item?.id.includes("VF"), [alertCount, item]);
  const hasGreenIcon = useMemo(
    () => !alertCount && hasUpdatedLocation && !item?.id.includes("VF"),
    [alertCount, hasUpdatedLocation, item]
  );
  const hasGreyIcon = useMemo(
    () => !alertCount && !hasUpdatedLocation && !item?.id.includes("VF"),
    [alertCount, hasUpdatedLocation, item]
  );

  const hasBlueIcon = useMemo(() => item?.id.includes("VF"), [item]);

  return {
    text,
    alertCount,
    lastLocationRecorded,
    lastLocationRecordedDate,
    hasUpdatedLocation,
    hasRedIcon,
    hasGreenIcon,
    hasGreyIcon,
    hasBlueIcon
  };
};
