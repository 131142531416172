import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { getLanguageLocalStorage, getOrganizationIdLocalStorage } from "../../../../helpers/localStorage";
import { ErrorChecker } from "../../../../stores/helpers/ErrorChecker";

import type { ThunkConfig } from "../../../../stores/types";
import type { getDeviceInfoRequestDTO } from "../../dto";
import { DeviceInfoModel, SensorSource } from "@fly-workspace/lib-api-interface";

export const getDeviceInfoAsync = createAsyncThunk<
  DeviceInfoModel,
  getDeviceInfoRequestDTO,
  ThunkConfig
>('devices/info', async ({ pathParams, params }, { rejectWithValue }) => {
  try {
    const organizationId = getOrganizationIdLocalStorage();
    const language = getLanguageLocalStorage();
    if (params) {
      if (!params.source) {
        params.source = SensorSource.external;
      }
    }

    const { data } = await axios.get<{ data: DeviceInfoModel }>(
      `/private/organizations/${organizationId}/devices/${pathParams.deviceId}`,
      {
        params: params || {
          relativeDate: 'today',
        },
        headers: {
          'Accept-Language': language,
        },
      },
    );

    return data.data;
  } catch (err) {
    return rejectWithValue(ErrorChecker(err));
  }
});
