import { DocProperty } from 'typescript-openapi-router';
import { enumToArray } from "@xtool/enum-to-array";
import { SensorSource } from "./sensor-source";

export class ChartData {

  @DocProperty({
    type: 'string',
    isRequired: true
  })
  date: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
    enum: enumToArray(SensorSource)
  })
  source?: SensorSource;

  @DocProperty({
    type: 'number',
    isRequired: false
  })
  minValue?: number;

  @DocProperty({
    type: 'number',
    isRequired: false
  })
  maxValue?: number;

  @DocProperty({
    type: 'number',
    isRequired: false
  })
  avgValue?: number;

  @DocProperty({
    type: 'number',
    isRequired: false
  })
  value?: number;

  [key: string]: any;
}
