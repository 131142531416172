import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Theme,
  useTheme,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { useTranslation } from 'react-i18next';

import { BootstrapDialog, BootstrapDialogTitle } from '../../../../components/Modal';
import { useAppDispatch, useAppSelector } from '../../../../stores/hooks';
import { initialFields, initialSelects } from '../../constants/cropsModal';
import { useSetTouched } from '../../hooks/useSetTouched';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { cropTranslatePaths } from '../../translations';
import type { ICrops } from '../../interfaces';
import type { IInitialSelects } from '../../constants/cropsModal';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from './styles.module.scss';
import { ICreateCropRequestDTO } from '../../dto/createCropsRequest.dto';
import { insectListSelector } from '../../store/selectors';
import { updateCropAsync } from '../../store/actions/updateCropAsync';
import { updateCropRequestDTO } from '../../dto/updateCrops.dto';
import { IInsect } from "../../../../interfaces/insect";

type TProps = {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
  currentCrop?: ICrops | null;
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: Array<string>, theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export const EditCropModal: FC<TProps> = ({ isOpen, onClose, onAccept, currentCrop }: TProps) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { insects } = useAppSelector(insectListSelector);
  const [insectName, setInsectName] = React.useState<string[]>([]);
  const [fields, setFields] = useState(initialFields);
  const [selectedInsects, setSelectedInsects] = useState<Partial<IInitialSelects>>(initialSelects);
  const [currentInsectId, setCurrentInsectId] = useState<any>();

  const { isTouched } = useSetTouched({ fields });

  useEffect(() => {
    if (currentCrop) {
      // @ts-ignores
      setFields((prevState) => ({
        ...prevState,
        currentCrop,
        name: currentCrop.name,
      }));
      // @ts-ignore
      const insectName = currentCrop.insects.map((el) => el.id);
      setInsectName(insectName);
    }
  }, [currentCrop]);

  useEffect(() => {
    setSelectedInsects((prevState) => ({
      ...prevState,
      insectsList: insects,
    }));
  }, [insects]);

  const updateFieldsHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: string,
  ) => {
    setFields((prevState) => ({
      ...prevState,
      [field]: event.target.value,
    }));
  };

  const updateCurrentSelectHandler = (event: SelectChangeEvent<any>, setSelect: any) => {
    setSelect((prevState: any) => ({
      ...prevState,
      selectedInsect: event.target.value,
    }));
  };

  const submit = async () => {
    const data: updateCropRequestDTO | any = {
      cropData: {
        insects: insectName,
        name: fields.name,
      },
      cropId: fields.currentCrop.id,
    };

    await dispatch(updateCropAsync(data));
    onAccept();
  };

  const handleChange = (event: SelectChangeEvent<typeof insectName>) => {
    const {
      target: { value },
    } = event;
    setInsectName(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <BootstrapDialog
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      aria-labelledby="edit_crop_title"
      open={isOpen}
      fullScreen={width <= 768}
    >
      <BootstrapDialogTitle id="edit_crop_title" onClose={onClose}>
        {t(cropTranslatePaths.edit_crop.title)}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <div className={styles.crop_modal__field_container}>
          <TextField
            key="name"
            id="name"
            placeholder={t(cropTranslatePaths.edit_crop.name)}
            required
            onChange={(event) => updateFieldsHandler(event, 'name')}
            value={fields.name}
            fullWidth
          />
        </div>

        <div className={styles.crop_modal__select_wrapper}>
          <div className={styles.crop_modal__select_label}>
            {t(cropTranslatePaths.edit_crop.pest_insects)}
          </div>
          <Select
            fullWidth
            labelId="organization-label"
            id="organization-select"
            multiple
            value={insectName}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
            onChange={(event: any) => {
              updateCurrentSelectHandler(event, setCurrentInsectId);
              handleChange(event);
            }}
          >
            {selectedInsects.insectsList?.map((insect: IInsect) => (
              <MenuItem
                key={insect.id}
                value={insect.id}
                style={getStyles(
                  insect.id,
                  selectedInsects.insectsList as unknown as Array<string>,
                  theme,
                )}
              >
                {insect.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </DialogContent>
      <DialogActions>
        {isTouched && (
          <>
            <Button onClick={submit}>{t(cropTranslatePaths.edit_crop.ok)}</Button>
            <Button color="warning" onClick={onClose}>
              {t(cropTranslatePaths.edit_crop.cancel)}
            </Button>
          </>
        )}
      </DialogActions>
    </BootstrapDialog>
  );
};
