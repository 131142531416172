import { createSlice, isFulfilled, isPending, isRejectedWithValue } from '@reduxjs/toolkit';

import { clearAlertAsync, deleteAlertAsync } from './actions';
import { TRejectValue } from '../../../stores/types';

interface IInitialState {
  isFetching: boolean;
  errorMessage: TRejectValue;
}

const initialState: IInitialState = {
  isFetching: false,
  errorMessage: null,
};

export const alertSlice = createSlice({
  name: 'alertList',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isFetching = false;
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isFulfilled(clearAlertAsync, deleteAlertAsync), (state) => {
      state.isFetching = false;
    });

    builder.addMatcher(isPending(clearAlertAsync, deleteAlertAsync), (state) => {
      state.isFetching = true;
    });

    builder.addMatcher(
      isRejectedWithValue(clearAlertAsync, deleteAlertAsync),
      (state, { payload }) => {
        state.isFetching = false;
        state.errorMessage = payload;
      },
    );
  },
});

export const { clearState } = alertSlice.actions;
