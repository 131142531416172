import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Button, DialogActions, DialogContent, MenuItem, Select, TextField } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { useTranslation } from "react-i18next";

import { BootstrapDialog, BootstrapDialogTitle } from "../../../../components/Modal";
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks";
import { mainSelector } from "../../../../main/store/selectors";
import type { IInitialSelects } from "../../constants/deviceModal";
import { deviceFields, initialFields, initialSelects } from "../../constants/deviceModal";
import { useSetTouched } from "../../hooks/useSetTouched";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { deviceTranslatePaths } from "../../translations";
import { createDeviceAsync } from "../../store/actions/createDeviceAsync";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from "./styles.module.scss";
import { ICreateDeviceRequestDTO } from "../../dto/createDeviceRequest.dto";
import { updateDeviceAsync } from "../../store/actions/updateDeviceAsync";
import { IDeviceData } from "../../../../interfaces/device";

type TProps = {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
  currentDevice?: IDeviceData | null;
  isReadonly?: boolean;
};

export const DeviceModal: FC<TProps> = ({
  isOpen,
  onClose,
  onAccept,
  currentDevice,
  isReadonly,
}: TProps) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const dispatch = useAppDispatch();

  const { organizations, crops } = useAppSelector(mainSelector);

  const [fields, setFields] = useState(initialFields);
  const [selects, setSelects] = useState<IInitialSelects>(initialSelects);
  const [currentOrgId, setCurrentOrgId] = useState<number>();
  const [currentCropId, setCurrentCropId] = useState<string>();

  const { isTouched } = useSetTouched({ fields });

  useEffect(() => {
    if (currentDevice) {
      setFields((prevState) => ({
        ...prevState,
        alias: currentDevice?.alias ?? '',
        serialNumber: currentDevice?.serialNumber ?? '',
        comment: currentDevice?.comment ?? '',
        initialLocation: ''
      }));
    }
  }, [currentDevice]);

  useEffect(() => {
    setSelects((prev) => ({
      ...prev,
      organizations,
      crops,
    }));
    if (organizations) setCurrentOrgId(currentDevice?.organizationId ?? organizations[0]?.id);
    if (crops) setCurrentCropId(currentDevice?.crop?.id ?? crops[0]?.id);
  }, [organizations, currentDevice, crops]);

  const updateFieldsHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: string,
  ) => {
    setFields((prevState) => ({
      ...prevState,
      [field]: event.target.value,
    }));
  };

  const updateCurrentSelectHandler = <T,>(
    event: SelectChangeEvent<T>,
    setSelect: (value: T) => void,
  ) => {
    setSelect(event.target.value as T);
  };

  const submit = async () => {
    const data: ICreateDeviceRequestDTO = {
      deviceData: { ...fields, cropId: currentCropId },
      organizationId: currentOrgId ?? 1,
    };
    if (currentDevice?.id) {
      await dispatch(updateDeviceAsync({
        deviceId: currentDevice?.id,
        device: {
          alias: data.deviceData.alias,
          comment: data.deviceData.comment,
          initialLocation: data.deviceData.initialLocation,
          cropId: data.deviceData.cropId,
          organizationId: data.organizationId
        }
      }));
    } else {
      await dispatch(createDeviceAsync(data));
    }
    onAccept();
  };

  return (
    <BootstrapDialog
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      aria-labelledby="create_device_title"
      open={isOpen}
      fullScreen={width <= 768}
    >
      <BootstrapDialogTitle id="create_device_title" onClose={onClose}>
        {t(deviceTranslatePaths.add_device.title)}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <div className={styles.device_modal__field_container}>
          {deviceFields.map((field) => {
            if (field.id !== 'crop' && field.id !== 'comment')
              return (
                <TextField
                  key={field.id}
                  id={field.id}
                  placeholder={t(deviceTranslatePaths.add_device[field.id])}
                  required={field.isRequired}
                  value={fields[field.key]}
                  onChange={(event) => updateFieldsHandler(event, field.key)}
                  fullWidth
                  disabled={isReadonly}
                />
              );
          })}
        </div>
        <div className={styles.device_modal__field_container}>
          <Select
            fullWidth
            labelId="crop-label"
            id="crop-select"
            disabled={isReadonly || !isTouched}
            value={currentCropId}
            onChange={(event: SelectChangeEvent<string>) => {
              updateCurrentSelectHandler(event, setCurrentCropId);
            }}
          >
            {selects?.crops?.map((crop) => (
              <MenuItem key={crop?.id} value={crop?.id}>
                {crop?.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={styles.device_modal__select_wrapper}>
          <div className={styles.device_modal__select_label}>
            {t(deviceTranslatePaths.add_device.organization)}
          </div>
          <Select
            fullWidth
            labelId="organization-label"
            id="organization-select"
            value={currentOrgId}
            disabled={isReadonly || !isTouched}
            onChange={(event: SelectChangeEvent<number>) => {
              updateCurrentSelectHandler(event, setCurrentOrgId);
            }}
          >
            {selects?.organizations?.map((org) => (
              <MenuItem key={org?.id} value={org?.id}>
                {org?.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div>
          <TextField
            id="comment"
            placeholder={t(deviceTranslatePaths.add_device.comment)}
            value={fields.comment}
            onChange={(event) => updateFieldsHandler(event, 'comment')}
            fullWidth
            multiline
            disabled={isReadonly}
            rows={4}
          />
        </div>
      </DialogContent>
      <DialogActions>
        {isTouched && (
          <>
            <Button onClick={submit}>{t(deviceTranslatePaths.add_device.ok)}</Button>
            <Button color="warning" onClick={onClose}>
              {t(deviceTranslatePaths.add_device.cancel)}
            </Button>
          </>
        )}
      </DialogActions>
    </BootstrapDialog>
  );
};
