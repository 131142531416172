import React, { FC, ReactNode, useMemo } from 'react';
import { Box, CircularProgress, Container } from '@mui/material';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { Navbar } from '../../../pages/Navbar';
import { pageNames } from '../../../pages/Navbar/constants';

import styles from './main.module.scss';
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";

type TProps = {
  children: ReactNode;
  isLoading: boolean;
};

export const MainLayout: FC<TProps> = ({ isLoading, children }: TProps) => {
  const { pathname } = useLocation();
  const { width } = useWindowDimensions();

  const currentPage = useMemo(
    () => pageNames.find((p) => p.route.startsWith(pathname)),
    [pathname],
  );

  const cn = {
    main_container: classNames(styles.main__container, {
      [styles.with_select]: currentPage ? currentPage.withDeviceSelect : false,
    }),
  };

  return (
    <Box marginTop={width < 900? '4rem' : '1rem'}>
      <Navbar />
      <Container className={cn.main_container} maxWidth="xl">
        {isLoading ? (
          <div className={styles.main__progress_wrapper}>
            <CircularProgress className={styles.main__progress} />
          </div>
        ) : (
          children
        )}
      </Container>
    </Box>
  );
};
