import { DocProperty, InheritedDoc } from "typescript-openapi-router";
import { BaseCronRequest } from "./base-cron-request";

@InheritedDoc()
export class SendAlertSummaryRequest extends BaseCronRequest {
  @DocProperty({
    type: "number",
    isRequired: false
  })
  userId?: number;
}
