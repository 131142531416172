import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getOrganizationIdLocalStorage,
  getLanguageLocalStorage,
} from '../../../../helpers/localStorage';
import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../../stores/types';
import type { getMyDevicesDTO } from '../../dto/getMyDevices.dto';

export const getMyDevicesAsync = createAsyncThunk<getMyDevicesDTO, void, ThunkConfig>(
  'devices/organization/my',
  async (_, { rejectWithValue }) => {
    const organizationId = getOrganizationIdLocalStorage();
    const language = getLanguageLocalStorage();

    try {
      const { data } = await axios.get<{ data: getMyDevicesDTO }>(
        `/private/organizations/${organizationId}/devices/managed`,
        {
          headers: {
            'Accept-Language': language,
          },
        },
      );

      return data.data;
    } catch (err) {
      return rejectWithValue(ErrorChecker(err));
    }
  },
);
