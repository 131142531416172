import { createSlice } from "@reduxjs/toolkit";

import { getAllInsectsAsync } from "./actions/getAllInsectsAsync";

import type { TRejectValue } from "../../../stores/types";
import type { RootState } from "../../../stores/store";
import { IInsect } from "../../../interfaces/insect";

const defaultInsects: IInsect[] = [
  {
    id: "all",
    name: "all",
    display: "All",
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    size: 0,
    image: "",
    availableAlerts: []
  },
  {
    id: "pest",
    name: "pest",
    display: "Pest",
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    size: 0,
    image: "",
    availableAlerts: []
  }
];

interface IInitialState {
  insects: Array<IInsect & { display?: string }>;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage?: TRejectValue;
}

const initialState: IInitialState = {
  insects: defaultInsects,
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: null
};

export const insectListSlice = createSlice({
  name: "insectsList",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = null;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllInsectsAsync.fulfilled, (state, { payload }) => {
      state.insects = [...defaultInsects, ...payload.map((el) => ({ ...el, display: el.name }))];
      state.isFetching = false;
      state.isSuccess = true;
    });
    builder.addCase(getAllInsectsAsync.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    });
    builder.addCase(getAllInsectsAsync.pending, (state) => {
      state.isFetching = true;
    });
  }
});

export const { clearState } = insectListSlice.actions;

export const insectListSelector = (state: RootState) => state.insectsList;
