export const setUpAlertsAlertsTranslatePaths = {
  title: 'set_up_alerts.alerts.title',
  mobile_phone: 'set_up_alerts.alerts.mobile_phone',
  mobile_phone_placeholder: 'set_up_alerts.alerts.mobile_phone_placeholder',
  mobile_phone_text: 'set_up_alerts.alerts.mobile_phone_text',
  email: 'set_up_alerts.alerts.email',
  email_placeholder: 'set_up_alerts.alerts.email_placeholder',
  email_text: 'set_up_alerts.alerts.email_text',
  alerts: 'set_up_alerts.alerts.alerts',
  alerts_text: 'set_up_alerts.alerts.alerts_text',
};
