import { Box, Divider, Paper, Typography } from '@mui/material';
import React, { CSSProperties, FC, PropsWithChildren } from 'react';
import { Variant } from '@mui/material/styles/createTypography';

export type CardProps = {
  title?: string;
  titleVariant?: Variant;
  margin?: string;
  minHeight?: string;
  minWidth?: string;
  width?: string;
  style?: CSSProperties;
  className?: string;
  onClick?: () => void;
};

export const Card: FC<PropsWithChildren<CardProps>> = ({
  title,
  titleVariant,
  margin,
  minHeight,
  minWidth,
  width,
  style,
  className,
  onClick,
  children,
}: PropsWithChildren<CardProps>) => {
  const mt = margin || '0.5rem';
  const mb = margin || '0.5rem';
  const mr = margin || '0rem';
  const ml = margin || '0rem';

  return (
    <Box
      className={className}
      onClick={onClick}
      marginTop={mt}
      marginRight={mr}
      marginBottom={mb}
      marginLeft={ml}
      minHeight={minHeight}
      minWidth={minWidth}
      style={style}
      width={width}
    >
      <Paper
        style={{
          backgroundColor: '#f8f8f8',
        }}
      >
        <Box padding="0.5rem" minHeight={minHeight}>
          {title && (
            <Box>
              <Typography className="divider" align="left" variant={titleVariant ?? 'h5'}>
                {title}
              </Typography>
              <Divider />
            </Box>
          )}
          {children}
        </Box>
      </Paper>
    </Box>
  );
};
