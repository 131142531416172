import { DocProperty } from 'typescript-openapi-router';

export class S3Object {
  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  key: string;
  @DocProperty({
    type: 'number',
    isRequired: true,
  })
  size: number;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  eTag: string;
  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  versionId?: string;
  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  sequencer: string;
}
