import { DocProperty } from 'typescript-openapi-router';

export class AddCropRequestBody {
  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  name: string;

  @DocProperty({
    type: 'array',
    objectType: 'number',
    isRequired: false
  })
  insects?: number[];
}
