import { createSlice } from "@reduxjs/toolkit";

import { getDeviceInfoAsync } from "../../Navbar/store/actions/getDeviceInfoAsync";
import { InsectChart, InsectDetectionModel, SensorSource } from "@fly-workspace/lib-api-interface";

interface IInitialState {
  images: InsectDetectionModel[];
  insect: string;
  isActivity: boolean;
  isEggLaying: boolean;
  isSpray: boolean;
  isPheromones: boolean;
  sensorSource: SensorSource;
}

const initialState: IInitialState = {
  images: [],
  insect: 'all',
  isActivity: false,
  isEggLaying: false,
  isSpray: false,
  isPheromones: false,
  sensorSource: SensorSource.device
};

export const deviceInfoSlice = createSlice({
  name: 'deviceInfo',
  initialState,
  reducers: {
    clearState: (state) => {
      state.insect = 'all';
      state.isActivity = true;
      state.isEggLaying = true;
      state.isSpray = true;
      state.isPheromones = true;
    },
    selectDeviceInsect: (state, { payload }) => {
      state.insect = payload;
    },
    selectSensorSource: (state, { payload }) => {
      state.sensorSource = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDeviceInfoAsync.fulfilled, (state, { payload }) => {
      const insectChart: InsectChart | undefined = payload.charts?.find(chart => chart.name === 'insect') as InsectChart;
      state.images = insectChart?.allDetections ?? [];
    });
  },
});

export const { clearState, selectDeviceInsect, selectSensorSource } = deviceInfoSlice.actions;
