export enum EInsectsDataKeys {
  alertAtFirstDetection = 'alertAtFirstDetection',
  isThreshold = 'isThreshold',
  isActivity = 'isActivity',
  isPheromone = 'isPheromone',
  isEggLaying = 'isEggLaying',
  isSpray = 'isSpray',
}

export const defaultWeatherAlerts = {
  minTemperature: { value: '', isEnable: false },
  maxTemperature: { value: '', isEnable: false },
  minHumidity: { value: '', isEnable: false },
  maxHumidity: { value: '', isEnable: false },
};
