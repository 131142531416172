import { ComponentType } from 'react';

import { tabsNamesTranslatePaths } from '../translations';
import { AllDevices } from '../components/AllDevices';
import { MyDevices } from '../components/MyDevices';
import { AddDevice } from '../components/AddDevice';

export type TTabs = {
  path: string;
  name: keyof typeof tabsNamesTranslatePaths;
  component: ComponentType;
};

export const tabs: TTabs[] = [
  {
    path: '',
    name: 'all_devices',
    component: AllDevices,
  },
  {
    path: '/my-devices',
    name: 'my_devices',
    component: MyDevices,
  },
  {
    path: '/add-device',
    name: 'add_device',
    component: AddDevice,
  },
];
