import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Container, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router-dom';

import { clearState, userSelector } from '../../store';
import { useAppDispatch, useAppSelector } from '../../../../stores/hooks';
import { loginUserAsync } from '../../store/actions/loginUserAsync';
import { getAllDevicesAsync, getMyDevicesAsync } from '../../../ManageDevices/store/actions';
import { CustomCircularProgress } from '../../../../components/CustomCircularProgress';

import text from '../../../../../assets/text/text.json';

import logo from '../../../../../assets/logo.png';
import './styles.scss';

export const LoginComponent = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { isFetching } = useAppSelector(userSelector);

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();

  const onSubmit = async () => {
    const result = await dispatch(loginUserAsync({ email, password }));

    if (loginUserAsync.fulfilled.match(result)) {
      setIsLoading(true);

      dispatch(getMyDevicesAsync())
        .unwrap()
        .then(async (payloadMyDevices) => {
          if (payloadMyDevices.length) {
            history.push('/');
          } else {
            await dispatch(getAllDevicesAsync())
              .unwrap()
              .then((payloadAllDevices) => {
                if (payloadAllDevices.length) {
                  history.push('/manage-devices');
                } else {
                  history.push('/manage-devices/add-device');
                }
              });
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(
    () => () => {
      dispatch(clearState());
    },
    [],
  );

  return (
    <>
      {(isLoading || isFetching) && <CustomCircularProgress />}

      <Container maxWidth="lg">
        <div className="login-container">
          <Box component="form" noValidate autoComplete="off" className="login-form-box">
            <img src={logo} alt="" />
            <div className="login-form-container">
              <div className="login-form-input-container">
                <p>{text.Login.Email}</p>
                <TextField
                  id="outlined-password-input"
                  label="Enter your email address"
                  type="email"
                  autoComplete="current-password"
                  className="login-form-input"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="login-form-input-container">
                <p>{text.Login.Password}</p>
                <TextField
                  id="outlined-pass-input"
                  label="Enter your password"
                  type="password"
                  autoComplete="current-password"
                  className="login-form-input"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <p>{text.Login.Forgot}</p>
            </div>

            <Button
              variant="contained"
              color="success"
              onClick={() => {
                onSubmit();
              }}
              disabled={isFetching}
            >
              {isFetching || isLoading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: 'white',
                    position: 'relative',
                  }}
                />
              ) : (
                <>{text.Login.Login}</>
              )}
            </Button>
          </Box>
        </div>
      </Container>
    </>
  );
};
