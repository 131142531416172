import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DialogActions, DialogContent } from '@mui/material';

import { BootstrapDialog, BootstrapDialogTitle } from '../../../../components/Modal';
import { changeCropModalPaths } from '../../translations';

type TProps = {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
};

export const ChangeCropModal: FC<TProps> = ({ isOpen, onClose, onAccept }: TProps) => {
  const { t } = useTranslation();

  return (
    <BootstrapDialog maxWidth={false} aria-labelledby="dialog_clear_title" open={isOpen}>
      <BootstrapDialogTitle id="dialog_clear_title" onClose={onClose}>
        {t(changeCropModalPaths.title)}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <ul>
          {Object.keys(changeCropModalPaths.texts).map((text) => (
            <li key={text}>
              {t(changeCropModalPaths.texts[text as keyof typeof changeCropModalPaths.texts])}
            </li>
          ))}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          {t(changeCropModalPaths.buttons.cancel)}
        </Button>
        <Button color="warning" onClick={onAccept}>
          {t(changeCropModalPaths.buttons.agree)}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};
