import { DocProperty } from 'typescript-openapi-router';

export class UpdateCropRequestBody {
  @DocProperty({
    type: 'string',
    isRequired: false
  })
  name?: string;

  @DocProperty({
    type: 'array',
    objectType: 'string',
    isRequired: false
  })
  insects?: string[];
}
