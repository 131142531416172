import { Buffer } from 'buffer';

export class ImageManager {
  public static getThumbnailUrl = (image: string, height: number, width?: number): string => {
    const thumbnailOptions = JSON.stringify({
      bucket: 'prod-teraseya-fly-ftp-sync',
      key: image,
      edits: {
        resize: {
          height,
          width: width ?? height,
          fit: 'contain',
        },
      },
    });
    return `https://cdn.fly.teraseya.com/${Buffer.from(thumbnailOptions).toString('base64')}`;
  };

  public static getFullImageUrl = (image: string): string => `https://cdn.fly.teraseya.com/${image}`;
}
