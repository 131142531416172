import { DocProperty } from 'typescript-openapi-router';
import { UpdateDevicesRequestBodyItem } from './update-devices-request-body-item';

export class UpdateDevicesRequestBody {

  @DocProperty({
    type: 'array',
    objectType: UpdateDevicesRequestBodyItem.name,
    isRequired: true,
    minSize: 1
  })
  items: UpdateDevicesRequestBodyItem[];
}
