import { DocProperty } from 'typescript-openapi-router';

export class InsectAlertModel {
  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  insectId: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  name?: string;

  @DocProperty({
    type: 'boolean',
    isRequired: false,
  })
  alertAtFirstDetection?: boolean;

  @DocProperty({
    type: 'number',
    isRequired: false,
  })
  alertAtThreshold?: number;

  @DocProperty({
    type: 'boolean',
    isRequired: false,
  })
  sendSprayAlerts?: boolean;

  @DocProperty({
    type: 'boolean',
    isRequired: false,
  })
  sendActivityAlerts?: boolean;

  @DocProperty({
    type: 'boolean',
    isRequired: false,
  })
  sendEggLayingAlerts?: boolean;

  @DocProperty({
    type: 'boolean',
    isRequired: false,
  })
  sendPheromoneAlerts?: boolean;
}
