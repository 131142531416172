import { ResponseEnvelope } from './response-envelope';
import { DocProperty, InheritedDoc } from 'typescript-openapi-router';
import { UserModel } from "../model";

@InheritedDoc()
export class UserListResponse extends ResponseEnvelope {
  @DocProperty({
    type: 'array',
    isRequired: false,
    objectType: UserModel.name,
  })
  data?: UserModel[];
}
