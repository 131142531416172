import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorChecker } from '../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../stores/types';
import type { getAllOrganizationRequestDTO, getAllOrganizationDTO } from '../../dto';

export const getAllOrganizationsAsync = createAsyncThunk<
  getAllOrganizationDTO,
  getAllOrganizationRequestDTO,
  ThunkConfig
>('main/organizations_list', async (request, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<{ data: getAllOrganizationDTO }>(
      `/private/organizations/get`,
      {
        params: request ? request.params : {},
      },
    );

    return data.data;
  } catch (err) {
    return rejectWithValue(ErrorChecker(err));
  }
});
