import { enumToArray } from '@xtool/enum-to-array';
import { DocProperty } from 'typescript-openapi-router';
import { AlertType } from '../model/alert-type';

export class ClearAlertRequestBody {
  @DocProperty({
    type: 'string',
    enum: enumToArray(AlertType),
    isRequired: true,
  })
  type: AlertType;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  deviceId: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  insectId?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  sensorType?: 'temperature' | 'humidity';

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  recordedAt: string;
}
