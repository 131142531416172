import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../../stores/types';
import type { deleteUserRequestDTO } from '../../dto/deleteUser.dto';

export const deleteUserAsync = createAsyncThunk<null, deleteUserRequestDTO, ThunkConfig>(
  'users/deleteUser',
  async ({ userId }, { rejectWithValue }) => {
    try {
      await axios.delete(`/private/user/${userId}`);

      return null;
    } catch (err) {
      return rejectWithValue(ErrorChecker(err));
    }
  },
);
