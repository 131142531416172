import { createSlice, isPending, isRejectedWithValue } from "@reduxjs/toolkit";

import { getAllCropsAsync, getAllDiseasesAsync, getAllOrganizationsAsync } from "./actions";
import type { TRejectValue } from "../../stores/types";
import type { ICrop } from "../../interfaces/crop";
import type { IOrganization } from "../../interfaces/organization";
import { DiseaseModel } from "@fly-workspace/lib-api-interface";

interface IInitialState {
  crops: ICrop[];
  diseases: DiseaseModel[];
  organizations: IOrganization[];
  isLoading: boolean;
  errorMessage?: TRejectValue;
}

const initialState: IInitialState = {
  crops: [],
  diseases: [],
  organizations: [],
  isLoading: false,
  errorMessage: null,
};

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCropsAsync.fulfilled, (state, { payload }) => {
      state.crops = payload;
      state.isLoading = false;
    });

    builder.addCase(getAllDiseasesAsync.fulfilled, (state, { payload }) => {
      state.diseases = payload;
      state.isLoading = false;
    });

    builder.addCase(getAllOrganizationsAsync.fulfilled, (state, { payload }) => {
      state.organizations = payload;
      state.isLoading = false;
    });

    builder.addMatcher(isPending(getAllCropsAsync, getAllOrganizationsAsync, getAllDiseasesAsync), (state) => {
      state.isLoading = true;
    });

    builder.addMatcher(
      isRejectedWithValue(getAllCropsAsync, getAllOrganizationsAsync, getAllDiseasesAsync),
      (state, { payload }) => {
        state.isLoading = false;
        state.errorMessage = payload;
      },
    );
  },
});

export const { clearState } = mainSlice.actions;
