import { insectTranslatePaths } from '../translations';
import { IInsect } from '../../../interfaces/insect';

export const initialFields: IInsect = {
  name: '',
  image: '',
  id: '',
  size: 0,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  availableAlerts: [],
};

export interface IField {
  id: keyof typeof insectTranslatePaths.add_insect;
  key: keyof typeof initialFields;
}

export const insectFields: IField[] = [
  {
    id: 'name',
    key: 'name',
  },
  {
    id: 'image',
    key: 'image',
  },
];

export interface IInitialSelects {
  insectsList: Array<IInsect>;
  insect: Array<IInsect>;
}

export const initialSelects: IInitialSelects = {
  insectsList: [],
  insect: [],
};
