import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { getOrganizationIdLocalStorage } from '../../../../helpers/localStorage';
import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { changeAlertRequest } from '../../dto/changeAlert.request.dto';
import type { ThunkConfig } from '../../../../stores/types';

export const clearAlertAsync = createAsyncThunk<null, changeAlertRequest, ThunkConfig>(
  'alert/list/clear',
  async ({ body }, { rejectWithValue }) => {
    const organizationId = getOrganizationIdLocalStorage();

    try {
      await axios.post<{ data: unknown }>(
        `/private/organizations/${organizationId}/devices/notifications`,
        body,
      );

      return null;
    } catch (err) {
      return rejectWithValue(ErrorChecker(err));
    }
  },
);
