export const addDeviceTranslatePaths = {
  header: 'manage_devices.add_device.header',
  nfc: 'manage_devices.add_device.nfc',
  qr: 'manage_devices.add_device.qr',
  serial_number: 'manage_devices.add_device.serial_number',
  serial_number_placeholder: 'manage_devices.add_device.serial_number_placeholder',
  name_device: 'manage_devices.add_device.name_device',
  name_device_placeholder: 'manage_devices.add_device.name_device_placeholder',
  added: 'manage_devices.add_device.added',
  crop: 'manage_devices.add_device.crop',
  ok: 'manage_devices.add_device.ok',
  device: 'manage_devices.add_device.device',
  great: 'manage_devices.add_device.great',
  crop_select_label: 'manage_devices.add_device.crop_select_label',
};
