import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../../stores/types';
import type { putPreferLanguageRequestDTO } from '../../dto';

export const putPreferLanguageAsync = createAsyncThunk<
  null,
  putPreferLanguageRequestDTO,
  ThunkConfig
>('changePreferLanguage', async ({ preferLanguage }, { rejectWithValue }) => {
  try {
    await axios.put(`/private/user/lang`, {
      preferLanguage,
    });

    return null;
  } catch (err) {
    return rejectWithValue(ErrorChecker(err));
  }
});
