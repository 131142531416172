import { DocProperty, InheritedDoc } from 'typescript-openapi-router';
import { PheromoneLogModel } from '../model';
import { ResponseEnvelope } from './response-envelope';

@InheritedDoc()
export class PheromoneLogsListResponse extends ResponseEnvelope {

  @DocProperty({
    type: 'object',
    objectType: PheromoneLogModel.name,
    isRequired: false
  })
  data?: PheromoneLogModel[];
}
