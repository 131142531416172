import { enumToArray } from '@xtool/enum-to-array';
import { DocProperty } from 'typescript-openapi-router';
import { OrganizationRole, Role } from '../model';

export class UpdateUserRequestBody {
  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  firstName?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  lastName?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  password?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
    enum: enumToArray(Role),
  })
  role?: Role;

  @DocProperty({
    type: 'string',
    isRequired: false,
    enum: enumToArray(OrganizationRole),
  })
  organizationRole?: OrganizationRole;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  phoneNumber?: string;
}
