import { enumToArray } from '@xtool/enum-to-array';
import { DocProperty } from 'typescript-openapi-router';
import { Supplier } from '../model/pheromone-supplier';

export class AddPheromoneRequestBody {
  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  name: string;

  @DocProperty({
    type: 'number',
    isRequired: true,
  })
  lifespan: number;

  @DocProperty({
    type: 'string',
    isRequired: true,
    enum: enumToArray(Supplier),
  })
  supplier: Supplier;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  comment?: string;

  @DocProperty({
    type: 'number',
    isRequired: false,
  })
  organizationId?: number;

  @DocProperty({
    type: 'array',
    objectType: 'string',
    isRequired: true,
    minSize: 1,
  })
  insectIds?: string[];

  @DocProperty({
    type: 'array',
    objectType: 'string',
    isRequired: true,
    minSize: 1,
  })
  deviceId?: string[];
}
