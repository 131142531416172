import { UpdateDeviceRequestBody } from './update-device-request-body';
import { DocProperty, InheritedDoc } from 'typescript-openapi-router';

@InheritedDoc()
export class UpdateDevicesRequestBodyItem extends UpdateDeviceRequestBody {
  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  id: string;
}
