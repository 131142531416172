import { DocProperty, InheritedDoc } from 'typescript-openapi-router';
import { AuthenticationModel } from '../model';
import { ResponseEnvelope } from './response-envelope';

@InheritedDoc()
export class LoginResponseBody extends ResponseEnvelope {

  @DocProperty({
    type: 'object',
    objectType: AuthenticationModel.name,
    isRequired: true
  })
  data?: AuthenticationModel;
}
