import { DocProperty } from "typescript-openapi-router";
import { CropModel } from "./crop-model";
import { DetectionThresholdModel } from "./detection-threshold-model";
import { SentAlertModel } from "./sent-alert-model";
import { Chart } from "./chart";
import { DeviceStatusModel } from "./device-status-model";
import { ActiveAlertModel } from "./active-alert-model";
import { PheromoneModel } from "./pheromone-model";
import { InsectModel } from "./insect-model";

export class DeviceInfoModel {

  @DocProperty({
    type: "array",
    objectType: Chart.name,
    isRequired: false
  })
  charts?: Chart[];

  @DocProperty({
    type: "object",
    objectType: CropModel.name,
    isRequired: false
  })
  crop?: CropModel;

  @DocProperty({
    type: "array",
    objectType: DetectionThresholdModel.name,
    isRequired: false
  })
  detectionThresholds?: DetectionThresholdModel[];

  @DocProperty({
    type: "object",
    objectType: SentAlertModel.name,
    isRequired: false,
  })
  sentAlerts?: SentAlertModel[];

  @DocProperty({
    type: 'object',
    objectType: DeviceStatusModel.name,
    isRequired: true,
  })
  status: DeviceStatusModel;

  @DocProperty({
    type: 'object',
    objectType: ActiveAlertModel.name,
    isRequired: false,
  })
  activeAlerts?: ActiveAlertModel;

  @DocProperty({
    type: 'object',
    objectType: PheromoneModel.name,
    isRequired: false,
  })
  pheromone?: PheromoneModel;

  @DocProperty({
    type: 'array',
    objectType: InsectModel.name,
    isRequired: false,
  })
  allInsectDetections?: InsectModel[];
}
