import { DocProperty } from 'typescript-openapi-router';

export class AddDeviceRequestModel {

  @DocProperty({
    type: 'string',
    isRequired: true
  })
  serialNumber: string;

  @DocProperty({
    type: 'string',
    isRequired: false
  })
  alias?: string;

  @DocProperty({
    type: 'string',
    isRequired: false
  })
  cropId?: string;

  @DocProperty({
    type: 'string',
    isRequired: false
  })
  comment?: string;

  @DocProperty({
    type: 'string',
    isRequired: false
  })
  initialLocation?: string;
}
