import { DocProperty } from 'typescript-openapi-router';

export class ContactPerson {
  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  firstName: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  lastName: string;
  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  email: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  phoneNumber: string;
}
