import type { ISendAlert, IWeatherAlerts, TCustomInsect, TWeatherAlert } from "../interfaces";
import { IInsect } from "src/app/interfaces/insect";
import { DeviceInfoModel } from "@fly-workspace/lib-api-interface";

const isEnabled = (value: any): boolean => value !== "" && value !== undefined && value !== null;

export const enum AvailableAlert {
  FIRST = "first",
  ACTIVITY = "activity",
  EGGS = "eggs",
  SPRAY = "spray",
  THRESHOLD = "threshold",
  PHEROMONES = "pheromones",
}

export const isAlertAvailable = (availableAlerts: string[] | undefined, availableAlert: AvailableAlert) => {
  if (!availableAlerts || !availableAlerts.length) {
    return false;
  }
  return availableAlerts.includes("all") || availableAlerts.includes(availableAlert);
};

export const getWeatherAlerts = ({
                                   minTemperature,
                                   maxTemperature,
                                   minHumidity,
                                   maxHumidity
                                 }: IWeatherAlerts): TWeatherAlert[] => {

  const weatherAlerts: TWeatherAlert[] = [];
  const temp: TWeatherAlert = {
    sensor: "temperature"
  };
  if (minTemperature.isEnable && isEnabled(minTemperature.value)) {
    temp.min = Number(minTemperature.value);
  }
  if (maxTemperature.isEnable && isEnabled(maxTemperature.value)) {
    temp.max = Number(maxTemperature.value);
  }
  if (temp.min || temp.max) {
    weatherAlerts.push(temp);
  }

  const hum: TWeatherAlert = {
    sensor: "humidity"
  };
  if (minHumidity.isEnable && isEnabled(minHumidity.value)) {
    hum.min = Number(minHumidity.value);
  }
  if (maxHumidity.isEnable && isEnabled(maxHumidity.value)) {
    hum.max = Number(maxHumidity.value);
  }
  if (hum.min || hum.max) {
    weatherAlerts.push(hum);
  }
  return weatherAlerts;
};

export const getInsectAlerts = (insectsData: TCustomInsect[]): ISendAlert[] => {
  return insectsData.map((insect) => ({
    insectId: insect.id,
    alertAtFirstDetection: insect.alertAtFirstDetection,
    alertAtThreshold: insect.alertAtThreshold !== 0 ? insect.alertAtThreshold : undefined,
    sendSprayAlerts: insect.isSpray,
    sendActivityAlerts: insect.isActivity,
    sendEggLayingAlerts: insect.isEggLaying
  }));
};


export const makeCustomInsectData = (insect: IInsect, deviceInfo?: DeviceInfoModel): TCustomInsect => {
  const insectAlert = deviceInfo?.activeAlerts?.insectAlerts?.find((el) => el.insectId === insect.id);
  if (insectAlert) {
    const customInsect = {
      ...insect
    } as TCustomInsect;
    customInsect.alertAtFirstDetection = insectAlert.alertAtFirstDetection ?? false;
    customInsect.alertAtThreshold = insectAlert.alertAtThreshold ?? 5;
    customInsect.isThreshold = Boolean(insectAlert.alertAtThreshold);
    customInsect.isSpray = insectAlert.sendSprayAlerts ?? false;
    customInsect.isActivity = insectAlert.sendActivityAlerts ?? false;
    customInsect.isEggLaying = insectAlert.sendEggLayingAlerts ?? false;
    customInsect.isPheromone = insectAlert.sendPheromoneAlerts ?? false;
    customInsect.availableAlerts = insect.availableAlerts;

    return customInsect;
  }
  return {
    ...insect,
    alertAtFirstDetection: isAlertAvailable(insect.availableAlerts, AvailableAlert.FIRST),
    alertAtThreshold: isAlertAvailable(insect.availableAlerts, AvailableAlert.THRESHOLD) ? 5 : 0,
    isThreshold: isAlertAvailable(insect.availableAlerts, AvailableAlert.THRESHOLD),
    isActivity: isAlertAvailable(insect.availableAlerts, AvailableAlert.ACTIVITY),
    isEggLaying: isAlertAvailable(insect.availableAlerts, AvailableAlert.EGGS),
    isSpray: isAlertAvailable(insect.availableAlerts, AvailableAlert.SPRAY),
    isPheromone: isAlertAvailable(insect.availableAlerts, AvailableAlert.PHEROMONES),
  };
};

export const makeCustomWeatherAlerts = (deviceInfo?: DeviceInfoModel): IWeatherAlerts => {
  const weatherAlerts = {} as IWeatherAlerts;

  deviceInfo?.activeAlerts?.weatherAlerts?.forEach((alert) => {
    if (alert.sensor === "temperature") {
      weatherAlerts.minTemperature = {
        value: isEnabled(alert.min) ? String(alert.min) : "",
        isEnable: isEnabled(alert.min)
      };
      weatherAlerts.maxTemperature = {
        value: isEnabled(alert.max) ? String(alert.max) : "",
        isEnable: isEnabled(alert.max)
      };
    } else {
      weatherAlerts.minHumidity = {
        value: isEnabled(alert.min) ? String(alert.min) : "",
        isEnable: isEnabled(alert.min)
      };
      weatherAlerts.maxHumidity = {
        value: isEnabled(alert.max) ? String(alert.max) : "",
        isEnable: isEnabled(alert.max)
      };
    }
  });

  return weatherAlerts;
};
