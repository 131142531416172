export const deviceWeatherTranslatePaths = {
  title: "device_data.weather.title",
  dataSource: "device_data.weather.data_source"
};

export const deviceWeatherChartTranslatePaths = {
  weather: {
    temperature: "device_data.weather.temperature",
    average_temperature: "device_data.weather.average_temperature",
    humidity: "device_data.weather.humidity",
    average_humidity: "device_data.weather.average_humidity"
  }
};
