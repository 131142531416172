import moment from "moment";

export const formatToDate = (date: Date): string => {
  const DD = date.getDate().toString().padStart(2, "0");
  const MM = (date.getMonth() + 1).toString().padStart(2, "0");
  const YYYY = date.getFullYear();
  const hh = date.getHours().toString().padStart(2, "0");
  const mm = date.getMinutes().toString().padStart(2, "0");
  const ss = date.getSeconds().toString().padStart(2, "0");
  return `${DD}.${MM}.${YYYY} - ${hh}:${mm}:${ss}`;
};

export const formatToDateEOD = (date: Date): string => {
  date = moment(date).endOf("day").utc().toDate();
  return formatToDate(date);
}

export const getFirstDayOfJanuary = (executionYear: number): Date => {
  return new Date(executionYear, 0, 1, 0, 0, 0, 0);
};

/*
* if the executionYear is the current year, then the function returns the current date
* if the executionYear is not the current year, then the function returns the last day of the year with the time set to 23:59:59:999
 */
export const getCurrentDate = (executionYear: number): Date => {
  const today = new Date();
  if (executionYear === today.getFullYear()) {
    return today;
  }
  return new Date(executionYear, 11, 31, 23, 59, 59, 999);
};

export const formatToDay = (date: Date): string => {
  const DD = date.getUTCDate().toString().padStart(2, "0");
  const MM = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const YYYY = date.getUTCFullYear();
  return `${DD}.${MM}.${YYYY}`;
};

export const fromDDMMYYYToDate = (day: string): Date => {
  const [DD, MM, YYYY] = day.split(".");
  return new Date(Number(YYYY), Number(MM) - 1, Number(DD));
};

export const fromDDMMYYY_HHMMSSToDate = (day: string): Date => {
  const [date, time] = day.split(" - ");
  const [DD, MM, YYYY] = date.split(".");
  const [hh, mm, ss] = time.split(":");
  return new Date(Number(YYYY), Number(MM) - 1, Number(DD), Number(hh), Number(mm), Number(ss));
};

export const formatToHour = (date: Date): string => {
  const DD = date.getDate().toString().padStart(2, "0");
  const MM = (date.getMonth() + 1).toString().padStart(2, "0");
  const YYYY = date.getFullYear();
  const hh = date.getHours().toString().padStart(2, "0");
  return `${DD}.${MM}.${YYYY} - ${hh}:00:00`;
};

export const formatWeatherbitAPIDate = (YYYY: number, MM: number, DD: number, hh: number) => {
  console.log("Formatting date for weatherbit API", YYYY, MM, DD, hh);
  const result = [
    YYYY.toString(),
    MM.toString().padStart(2, "0"),
    DD.toString().padStart(2, "0")
  ].join("-");
  console.log("Formatted date for weatherbit API", result);
  return result;
};

export const shiftDateByTZ = (date: Date): Date => {
  const offset = date.getTimezoneOffset();
  return new Date(date.getTime() + offset * 60 * 1000);
}

