import { DocProperty, InheritedDoc } from 'typescript-openapi-router';
import { ResponseEnvelope } from './response-envelope';
import { StatusModel } from '../model';

@InheritedDoc()
export class StatusResponseBody extends ResponseEnvelope {
  @DocProperty({
    type: 'object',
    objectType: StatusModel.name,
    isRequired: true,
  })
  data: StatusModel;
}
