import React, { useState, MouseEvent, ChangeEvent, useEffect } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import {
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TablePagination,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

import { capitalizeStr } from 'src/app/helpers';
import { Card } from '../../components/widgets/Card';
import { TransparentButton } from '../../components/TransparentButton';
import { Button } from '../../components/widgets/Button';
import { UserModal } from './components/UserModal';
import { EditUserModal } from './components/editUserModal';
import { useAppDispatch, useAppSelector } from '../../stores/hooks';
import { usersListSelector } from './store/selectors';
import { getUsersListAsync } from './store/actions/getUsersListAsync';
import { useSeparateUsersPerPage } from './hooks/useSeparateUsersPerPage';
import { CustomCircularProgress } from '../../components/CustomCircularProgress';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Eye from '../../../assets/eye.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Notepad from '../../../assets/notepad.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from './styles.module.scss';
import './styles.overload.scss';

import { usersTranslatePaths } from './translations';
import { sortDirection } from './constants/sortDirection';
import { UserModel } from "@fly-workspace/lib-api-interface";

export const UsersPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { usersList, isLoading } = useAppSelector(usersListSelector);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpenUserModal, setIsOpenUserModal] = useState(false);
  const [isOpenEditUserModal, setIsOpenEditUserModal] = useState(false);
  const [currentUser, setCurrentUser] = useState<UserModel | null>(null);
  const [isReadonly, setIsReadonly] = useState(false);
  const [isSelected, setIsSelected] = useState('sort_by');
  const [direction, setDirection] = useState('direction');
  const { usersForAllPages } = useSeparateUsersPerPage({ usersList, rowsPerPage });

  const handleSelectedOption = (event: SelectChangeEvent) => {
    setIsSelected(event.target.value as string);

    dispatch(
      getUsersListAsync({
        sort: event.target.value as string,
        sortDirection: direction !== 'direction' ? direction : sortDirection.asc,
      }),
    );
  };

  const handleDirectionOption = (event: SelectChangeEvent) => {
    setDirection(event.target.value as string);

    dispatch(
      getUsersListAsync({
        sort: isSelected !== 'sort_by' ? isSelected : 'firstName',
        sortDirection: event.target.value,
      }),
    );
  };

  const handleSearchOption = (event: string) => {
    dispatch(
      getUsersListAsync({
        sort: isSelected !== 'sort_by' ? isSelected : 'firstName',
        sortDirection: direction !== 'direction' ? direction : sortDirection.asc,
        search: event,
      }),
    );
  };

  useEffect(() => {
    dispatch(getUsersListAsync());
  }, [dispatch]);

  const changePageHandler = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const changeRowsPerPageHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const editUserHandler = (user: UserModel) => {
    setCurrentUser(user);
    setIsOpenEditUserModal(true);
  };

  const watchUserHandler = (user: UserModel) => {
    editUserHandler(user);
    setIsReadonly(true);
  };

  const onCloseUserModalHandler = () => {
    setIsOpenUserModal(false);
    setCurrentUser(null);
    setIsReadonly(false);
  };

  const onCloseEditUserModalHandler = () => {
    setIsOpenEditUserModal(false);
    setCurrentUser(null);
    setIsReadonly(false);
  };

  const onAcceptUserModalHandler = () => {
    onCloseUserModalHandler();
  };

  const onAcceptEditUserModalHandler = () => {
    onCloseEditUserModalHandler();
  };

  return (
    <div className={styles.users}>
      {isLoading && <CustomCircularProgress />}

      <div className={styles.users__header}>
        <div className={styles.users__search_container}>
          <Paper component="form" className={styles.users__search}>
            <IconButton aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  event.preventDefault();

                  handleSearchOption(event?.target?.value);
                }
              }}
              className={styles.users__search_input}
              placeholder={t(usersTranslatePaths.header.search)}
            />
          </Paper>

          <div className={styles.users__select_container}>
            <Select
              className={styles.users__select}
              labelId="sort-label"
              id="sort-select"
              label="sort_by"
              value={isSelected}
              onChange={handleSelectedOption}
            >
              <MenuItem value="createdAt">{t(usersTranslatePaths.sort.created_at)}</MenuItem>
              <MenuItem value="updatedAt">{t(usersTranslatePaths.sort.updated_at)}</MenuItem>
              <MenuItem value="role">{t(usersTranslatePaths.sort.app_role)}</MenuItem>
              <MenuItem value="firstName">{t(usersTranslatePaths.sort.firstName)}</MenuItem>
              <MenuItem value="lastName">{t(usersTranslatePaths.sort.lastName)}</MenuItem>
              <MenuItem className={styles.users__select_label} value="sort_by">
                {t(usersTranslatePaths.header.sort_by)}
              </MenuItem>
            </Select>

            <Select
              className={styles.users__select}
              labelId="direction-label"
              id="direction-select"
              value={direction}
              onChange={handleDirectionOption}
            >
              <MenuItem value={sortDirection.asc}>{capitalizeStr(sortDirection.asc)}</MenuItem>
              <MenuItem value={sortDirection.desc}>{capitalizeStr(sortDirection.desc)}</MenuItem>
              <MenuItem value="direction">{t(usersTranslatePaths.header.direction)}</MenuItem>
            </Select>
          </div>
        </div>

        <div className={styles.users__create_user_wrapper}>
          <Button className={styles.users__create_user} onClick={() => setIsOpenUserModal(true)}>
            {t(usersTranslatePaths.header.create_user)}
          </Button>
        </div>
      </div>

      <Card className="users__wrapper" title={t(usersTranslatePaths.title)}>
        <div className={styles.users__container}>
          <div className={styles.users__card_list}>
            {!!usersForAllPages.length &&
              usersForAllPages[page].map((user, index) => (
                <Card
                  className={classNames(styles.users__card, 'users__card')}
                  key={`${user.createdAt}_${index}`}
                >
                  <div className={styles.users__full_name}>
                    <div>{`${user?.firstName ?? ''} ${user?.lastName ?? ''}`}</div>
                    <div className={styles.users__actions}>
                      <TransparentButton onClick={() => watchUserHandler(user)}>
                        <img src={Eye} alt="eye" />
                      </TransparentButton>
                      <TransparentButton onClick={() => editUserHandler(user)}>
                        <img src={Notepad} alt="notepad" />
                      </TransparentButton>
                    </div>
                  </div>
                  {user?.createdAt && (
                    <div>
                      {t(usersTranslatePaths.users_list.created, {
                        value: moment(user.createdAt).format('DD.MM.YYYY - HH:mm:SS'),
                      })}
                    </div>
                  )}
                  {user?.updatedAt && (
                    <div>
                      {t(usersTranslatePaths.users_list.updated, {
                        value: moment(user.updatedAt).format('DD.MM.YYYY - HH:mm:SS'),
                      })}
                    </div>
                  )}
                  {user?.createdAt && (
                    <div>
                      {t(usersTranslatePaths.users_list.email, {
                        value: user.email,
                      })}
                    </div>
                  )}

                  {user?.role && (
                    <div>
                      {t(usersTranslatePaths.users_list.app_role, {
                        value: t(usersTranslatePaths.keys[user.role]),
                      })}
                    </div>
                  )}
                  {user?.comment && (
                    <div>
                      {t(usersTranslatePaths.users_list.comment, {
                        value: user.comment,
                      })}
                    </div>
                  )}
                </Card>
              ))}
          </div>

          <div>
            <TablePagination
              component="div"
              count={usersList.length}
              page={page}
              onPageChange={changePageHandler}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={changeRowsPerPageHandler}
            />
          </div>
        </div>
      </Card>

      <UserModal
        isOpen={isOpenUserModal}
        onAccept={onAcceptUserModalHandler}
        onClose={onCloseUserModalHandler}
        currentUser={currentUser}
        isReadonly={isReadonly}
      />

      <EditUserModal
        isOpen={isOpenEditUserModal}
        onAccept={onAcceptEditUserModalHandler}
        onClose={onCloseEditUserModalHandler}
        currentUser={currentUser}
        isReadonly={isReadonly}
      />
    </div>
  );
};
