import { DocProperty } from 'typescript-openapi-router';
import { S3Bucket } from './s3-bucket';
import { S3Object } from './s3-object';

export class S3 {
  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  s3SchemaVersion: string;
  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  configurationId: string;

  @DocProperty({
    type: 'object',
    objectType: S3Bucket.name,
    isRequired: true,
  })
  bucket: S3Bucket;

  @DocProperty({
    type: 'object',
    objectType: S3Object.name,
    isRequired: true,
  })
  object: S3Object;
}
