import React from 'react';

import notFound from '../../../assets/404_cactus.png';
import styles from './styles.module.scss';

export const NotFoundPage = () => (
  <div className={styles.not_found_page__wrapper}>
    <img className={styles.not_found_page__image} src={notFound} alt="not_found" />
  </div>
);
