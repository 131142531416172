import { OrganizationRole } from '@fly-workspace/lib-api-interface';
import { enumToArray } from '@xtool/enum-to-array';
import { DocProperty } from 'typescript-openapi-router';

export class AddUserToOrganizationRequestBody {
  @DocProperty({
    type: 'number',
    isRequired: true,
  })
  userId: number;
  @DocProperty({
    type: 'string',
    isRequired: true,
    enum: enumToArray(OrganizationRole)
  })
  role: OrganizationRole;
}
