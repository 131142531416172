import { SensorSource } from "@fly-workspace/lib-api-interface";

export enum ChartColor {
  AVG_COLOR = "#000000",
  MIXED_SENSOR_COLOR = "#92d050",
  DEVICE_SENSOR_COLOR = "#92d050",
  BASE_COLOR = "#0A4166",
  EXTERNAL_FORECAST_SENSOR_COLOR = "rgba(10,65,102,0.44)"
}

export const getBarChartColor = (source?: SensorSource) => {
  switch (source) {
    case SensorSource.mixed:
      return ChartColor.MIXED_SENSOR_COLOR;
    case SensorSource.device:
      return ChartColor.DEVICE_SENSOR_COLOR;
    case SensorSource.external:
      return ChartColor.BASE_COLOR;
    case SensorSource.forecast:
      return ChartColor.EXTERNAL_FORECAST_SENSOR_COLOR;
    default:
      return ChartColor.DEVICE_SENSOR_COLOR;
  }
};
