import { useEffect, useState } from 'react';
import { IDeviceData } from "../../../interfaces/device";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSeparateDevicePerPage = (data: any) => {
  const [deviceForAllPages, setDeviceForAllPages] = useState<IDeviceData[][]>([]);

  useEffect(() => {
    const chunksArray: IDeviceData[][] = [];
    for (let i = 0; i < data.deviceList?.length; i += data.rowsPerPage) {
      const chunk = data.deviceList.slice(i, i + data.rowsPerPage);
      chunksArray.push(chunk);
    }

    setDeviceForAllPages(chunksArray);
  }, [data.rowsPerPage, data.deviceList]);

  return {
    deviceForAllPages,
  };
};
