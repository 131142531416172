import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import moment from "moment";
import classNames from "classnames";
import { IconButton, InputBase, MenuItem, Paper, Select, SelectChangeEvent, TablePagination } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

import { capitalizeStr } from "src/app/helpers";
import { Card } from "../../components/widgets/Card";
import { TransparentButton } from "../../components/TransparentButton";
import { Button } from "../../components/widgets/Button";
import { DeviceModal } from "./components/DeviceModal";
import { useAppDispatch, useAppSelector } from "../../stores/hooks";
import { deviceListSelector } from "./store/selectors";
import { getDeviceListAsync } from "./store/actions/getDeviceListAsync";
import { useSeparateDevicePerPage } from "./hooks/useSeparateDevicePerPage";
import { CustomCircularProgress } from "../../components/CustomCircularProgress";
import Eye from "../../../assets/eye.svg";
import Notepad from "../../../assets/notepad.svg";
import styles from "./styles.module.scss";
import "./styles.overload.scss";

import { deviceTranslatePaths } from "./translations";
import { sortDirection } from "./constants/sortDirection";
import { IDeviceData } from "../../interfaces/device";

export const DevicePage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { deviceList, isLoading } = useAppSelector(deviceListSelector);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpendeviceModal, setIsOpenDeviceModal] = useState(false);
  // const [isOpenEditDeviceModal, setIsOpenEditDeviceModal] = useState(false);
  const [currentDevice, setCurrentDevice] = useState<IDeviceData | null>(null);
  const [isReadonly, setIsReadonly] = useState(false);
  const [isSelected, setIsSelected] = useState('sort_by');
  const [direction, setDirection] = useState('direction');
  const { deviceForAllPages } = useSeparateDevicePerPage({ deviceList, rowsPerPage });

  const handleSelectedOption = (event: SelectChangeEvent) => {
    setIsSelected(event.target.value as string);

    dispatch(
      getDeviceListAsync({
        sort: event.target.value as string,
        sortDirection: direction !== 'direction' ? direction : sortDirection.asc,
      }),
    );
  };

  const handleDirectionOption = (event: SelectChangeEvent) => {
    setDirection(event.target.value as string);

    dispatch(
      getDeviceListAsync({
        sort: isSelected !== 'sort_by' ? isSelected : 'alias',
        sortDirection: event.target.value,
      }),
    );
  };

  const handleSearchOption = (event: string) => {
    dispatch(
      getDeviceListAsync({
        sort: isSelected !== 'sort_by' ? isSelected : 'alias',
        sortDirection: direction !== 'direction' ? direction : sortDirection.asc,
        search: event,
      }),
    );
  };

  useEffect(() => {
    dispatch(getDeviceListAsync());
  }, [dispatch]);

  const changePageHandler = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const changeRowsPerPageHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const editDeviceHandler = (device: IDeviceData) => {
    setCurrentDevice(device);
    setIsOpenDeviceModal(true);
  };

  const onCloseDeviceModalHandler = () => {
    setIsOpenDeviceModal(false);
    setCurrentDevice(null);
    setIsReadonly(false);
  };

  const watchDeviceHandler = (device: IDeviceData) => {
    editDeviceHandler(device);
    setIsReadonly(true);
  };

  const onAcceptDeviceModalHandler = () => {
    onCloseDeviceModalHandler();
  };

  return (
    <div className={styles.device}>
      {isLoading && <CustomCircularProgress />}

      <div className={styles.device__header}>
        <div className={styles.device__search_container}>
          <Paper component="form" className={styles.device__search}>
            <IconButton aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  event.preventDefault();

                  handleSearchOption(event?.target?.value);
                }
              }}
              className={styles.device__search_input}
              placeholder={t(deviceTranslatePaths.header.search)}
            />
          </Paper>

          <div className={styles.device__select_container}>
            <Select
              className={styles.device__select}
              labelId="sort-label"
              id="sort-select"
              label="sort_by"
              value={isSelected}
              onChange={handleSelectedOption}
            >
              <MenuItem value="createdAt">{t(deviceTranslatePaths.sort.created_at)}</MenuItem>
              <MenuItem value="updatedAt">{t(deviceTranslatePaths.sort.updated_at)}</MenuItem>
              <MenuItem value="alias">{t(deviceTranslatePaths.sort.alias)}</MenuItem>
              <MenuItem value="cropId">{t(deviceTranslatePaths.sort.cropId)}</MenuItem>
              <MenuItem value="serialNumber">{t(deviceTranslatePaths.sort.serialNumber)}</MenuItem>
              <MenuItem value="comment">{t(deviceTranslatePaths.sort.comment)}</MenuItem>
              <MenuItem className={styles.device__select_label} value="sort_by">
                {t(deviceTranslatePaths.header.sort_by)}
              </MenuItem>
            </Select>

            <Select
              className={styles.device__select}
              labelId="direction-label"
              id="direction-select"
              value={direction}
              onChange={handleDirectionOption}
            >
              <MenuItem value={sortDirection.asc}>{capitalizeStr(sortDirection.asc)}</MenuItem>
              <MenuItem value={sortDirection.desc}>{capitalizeStr(sortDirection.desc)}</MenuItem>
              <MenuItem className={styles.device__select_label} value="direction">
                {t(deviceTranslatePaths.header.direction)}
              </MenuItem>
            </Select>
          </div>
        </div>

        <div className={styles.device__create_user_wrapper}>
          <Button className={styles.device__create_user} onClick={() => setIsOpenDeviceModal(true)}>
            {t(deviceTranslatePaths.header.create_device)}
          </Button>
        </div>
      </div>

      <Card className="users__wrapper" title={t(deviceTranslatePaths.title)}>
        <div className={styles.device__container}>
          <div className={styles.device__card_list}>
            {!!deviceForAllPages.length &&
              deviceForAllPages[page].map((device, index) => (
                <Card
                  className={classNames(styles.device__card, 'users__card')}
                  key={`${device.createdAt}_${index}`}
                >
                  <div className={styles.device__full_name}>
                    <div>{`${device?.alias}`}</div>
                    <div className={styles.device__actions}>
                      <TransparentButton onClick={() => watchDeviceHandler(device)}>
                        <img src={Eye} alt="eye" />
                      </TransparentButton>
                      <TransparentButton onClick={() => editDeviceHandler(device)}>
                        <img src={Notepad} alt="notepad" />
                      </TransparentButton>
                    </div>
                  </div>
                  {device?.createdAt && (
                    <div>
                      {t(deviceTranslatePaths.devices_list.created, {
                        value: moment(device.createdAt).format('DD.MM.YYYY - HH:mm:SS'),
                      })}
                    </div>
                  )}
                  {device?.updatedAt && (
                    <div>
                      {t(deviceTranslatePaths.devices_list.updated, {
                        value: moment(device.updatedAt).format('DD.MM.YYYY - HH:mm:SS'),
                      })}
                    </div>
                  )}

                  {device?.cropId && (
                    <div>
                      {t(deviceTranslatePaths.devices_list.cropId, {
                        value: capitalizeStr(device.cropId),
                      })}
                    </div>
                  )}
                  {device?.serialNumber && (
                    <div>
                      {t(deviceTranslatePaths.devices_list.serialNumber, {
                        value: device.serialNumber,
                      })}
                    </div>
                  )}
                  {device.comment && (
                    <div>
                      {t(deviceTranslatePaths.devices_list.comment, {
                        value: device.comment,
                      })}
                    </div>
                  )}
                </Card>
              ))}
          </div>

          <div>
            <TablePagination
              component="div"
              count={deviceList.length}
              page={page}
              onPageChange={changePageHandler}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={changeRowsPerPageHandler}
            />
          </div>
        </div>
      </Card>

      <DeviceModal
        isOpen={isOpendeviceModal}
        onAccept={onAcceptDeviceModalHandler}
        onClose={onCloseDeviceModalHandler}
        currentDevice={currentDevice}
        isReadonly={isReadonly}
      />
    </div>
  );
};
