import { DocProperty } from 'typescript-openapi-router';
import { enumToArray } from '@xtool/enum-to-array';
import { DetectionThresholdType } from './detection-threshold-type';

export class DetectionThresholdModel {

  @DocProperty({
    type: 'string',
    isRequired: true,
    enum: enumToArray(DetectionThresholdType)
  })
  type: DetectionThresholdType;

  @DocProperty({
    type: 'string',
    isRequired: true
  })
  name: string;

  @DocProperty({
    type: 'number',
    isRequired: false
  })
  value?: number;

  @DocProperty({
    type: 'number',
    isRequired: false
  })
  min?: number;

  @DocProperty({
    type: 'number',
    isRequired: false
  })
  max?: number;
}
