import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import GoogleMapReact, { MapOptions, Maps } from "google-map-react";

import { Card } from "../../../../components/widgets/Card";
import { TextLabel } from "../../../../components/widgets/TextLabel";
import { TeraseyaMarker } from "../../../Dashboard/components/TeraseyaMarker";
import { Button } from "../../../../components/widgets/Button";
import { getMapOptions } from "../../../Dashboard/helpers/getMapOptions";
import { StyledInfoCard } from "../../../../components/widgets/StyledInfoCard";
import { InfoBox } from "../../../../components/widgets/InfoBox";
import { useAppSelector } from "../../../../stores/hooks";
import { deviceStatusTranslatePaths } from "../../translations";
import { navbarSelector } from "../../../Navbar/store/selectors";
import { infoCards, TCardValue } from "../../constants/infoDevice";
import { getSensorDate, getSensorValue } from "../../helpers";

import styles from "./styles.module.scss";
import { DeviceInfoModel } from "@fly-workspace/lib-api-interface";

const getDisplayDate = (el: TCardValue, deviceInfo?: DeviceInfoModel) => {
  if (!deviceInfo) {
    return "N/A";
  }
  if (deviceInfo.status[el.value]) {
    return getSensorDate(deviceInfo.status[el.value]);
  }
  return deviceInfo.status.lastUpdate
};

export const DeviceInfo = () => {
  const { t } = useTranslation();

  const { deviceInfo, currentDevice } = useAppSelector(navbarSelector);

  const onOpenInMaps = () => {
    const url = `https://www.google.com/maps/search/?api=1&query=${deviceInfo?.status.latitude},${deviceInfo?.status.longitude}`;
    window.open(url, "_blank");
  };

  const onCopyCoordinates = async () => {
    await navigator.clipboard.writeText(
      `${deviceInfo?.status.latitude},${deviceInfo?.status.longitude}`
    );
  };

  const handleGoogleApiLoaded = (
    map: MapOptions & { setOptions: (maps: MapOptions) => void },
    maps: Maps
  ) => {
    map.setOptions(getMapOptions(maps));
  };

  const cardTitle = useMemo(() => {
    const title = deviceInfo?.crop?.id
      ? `${t(deviceStatusTranslatePaths.title, {
        crop: t(`crops.${deviceInfo.crop.id}`)
      })}`
      : `${t(deviceStatusTranslatePaths.title_zero)}`;
    return title.toUpperCase();
  }, [deviceInfo?.crop, t]);

  return (
    <Card title={cardTitle}>
      <InfoBox className={styles.device_status}>
        <Box className={styles.map__wrapper}>
          <StyledInfoCard Icon={LocationOnIcon} title={t(deviceStatusTranslatePaths.location)}>
            <Box className={styles.map__actions}>
              <Box className={styles.map__action_btn}>
                <Button onClick={onOpenInMaps}>
                  {t(deviceStatusTranslatePaths.buttons.open_in_maps)}
                </Button>
              </Box>
              <Box className={styles.map__action_btn}>
                <Button variant="contained" onClick={onCopyCoordinates}>
                  {t(deviceStatusTranslatePaths.buttons.copy_coordinates)}
                </Button>
              </Box>
            </Box>
            <Box className={styles.map}>
              {deviceInfo && (
                <GoogleMapReact
                  bootstrapURLKeys={{ key: process.env.NX_GOOGLE_API_KEY } as { key: string }}
                  defaultZoom={12}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({ map, maps }) => handleGoogleApiLoaded(map, maps)}
                  center={{
                    lat: deviceInfo.status.latitude ?? 0,
                    lng: deviceInfo.status.longitude ?? 0
                  }}
                >
                  {currentDevice && <TeraseyaMarker
                    lat={deviceInfo.status.latitude ?? 0}
                    lng={deviceInfo.status.longitude ?? 0}
                    item={currentDevice}
                  />}

                </GoogleMapReact>
              )}
            </Box>
          </StyledInfoCard>
        </Box>
        <Box className={styles.statuses}>
          {infoCards.map((card) => (
            <StyledInfoCard
              key={`info_cards_${String(card.title)}_${card.separator}`}
              Icon={card.icon}
              title={t(deviceStatusTranslatePaths.cards[card.title])}
            >
              <Box>
                {card.card_fields.map((el) => (
                  <TextLabel key={`card_fields_${el.value}_${String(card.title)}`}>
                    {t(deviceStatusTranslatePaths.cards[el.translate_path], {
                      value: getSensorValue(deviceInfo?.status[el.value]),
                      separator: card.separator,
                      date: getDisplayDate(el, deviceInfo)
                    })}
                  </TextLabel>
                ))}
              </Box>
            </StyledInfoCard>
          ))}
          <StyledInfoCard
            Icon={AccessTimeFilledIcon}
            title={t(deviceStatusTranslatePaths.cards.last_update)}
          >
            <Box>
              <TextLabel>{deviceInfo?.status.lastUpdate ?? "N/A"}</TextLabel>
            </Box>
          </StyledInfoCard>
        </Box>
      </InfoBox>
    </Card>
  );
};
