import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { clearMyDevicesState } from "../../store/myDevicesListSlice";
import { Card } from "../../../../components/widgets/Card";
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks";
import { myDeviseListSelector, updateDeviseListSelector } from "../../store/selectors";
import { getMyDevicesAsync, updateDeviceListAsync } from "../../store/actions";
import { clearUpdateDevicesState } from "../../store/updateDeviceListSlice";
import { myDevicesTranslatePaths } from "../../translations";
import { navbarSelector } from "../../../Navbar/store/selectors";
import { CustomCircularProgress } from "../../../../components/CustomCircularProgress";
import { CustomDataGrid } from "../CustomDataGrid";

import type { IDeviceData } from "../../interfaces/deviceData";

import styles from "./styles.module.scss";

export const MyDevices = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { isFetching, errorMessage, myDeviceList } = useAppSelector(myDeviseListSelector);
  const { language, organizationId } = useAppSelector(navbarSelector);
  const updateDevices = useAppSelector(updateDeviseListSelector);

  const [rows, setRows] = useState<IDeviceData[]>([]);

  useEffect(() => {
    setRows(myDeviceList.map((item) => {
      return {
        ...item,
        cropName: item.crop?.id ? t(`crops.${item.crop.id}`) : ""
      };
    }));
  }, [myDeviceList]);

  useEffect(() => {
    dispatch(getMyDevicesAsync());
  }, [language, organizationId]);

  useEffect(
    () => () => {
      dispatch(clearMyDevicesState());
    },
    []
  );

  useEffect(() => {
    if (errorMessage) {
      dispatch(clearMyDevicesState());
      dispatch(clearUpdateDevicesState());
    }
  }, [errorMessage, updateDevices.isError, updateDevices.isSuccess]);

  const handleClear = () => {
    dispatch(getMyDevicesAsync());
  };

  const handleCreate = async () => {
    await dispatch(
      updateDeviceListAsync({
        body: {
          items: rows.filter(el => el.dirty).map((el) => ({
            id: el.id,
            alias: el.alias,
            cropId: el.cropId,
            cropName: el.cropName,
            isManagedByMe: el.isManagedByMe
          }))
        }
      })
    );

    await dispatch(getMyDevicesAsync());
  };

  return (
    <>
      {(isFetching || updateDevices.isFetching) && <CustomCircularProgress />}

      <Card className={styles.my_devices}>
        <div className={styles.my_devices__wrapper}>
          <p>{t(myDevicesTranslatePaths.name)}</p>
          <p>
            <span>{t(myDevicesTranslatePaths.warning)}</span>
          </p>
        </div>
        <CustomDataGrid
          rows={rows}
          updateRows={setRows}
          handleCreate={handleCreate}
          handleClear={handleClear}
          className={styles.my_devices__table}
        />
      </Card>
    </>
  );
};
