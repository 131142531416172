import { createSlice, isAsyncThunkAction, isPending, isRejectedWithValue } from '@reduxjs/toolkit';

import { getCropListAsync } from './actions/getCropsListAsync';
import { createCropsAsync } from './actions/createCropsAsync';
import type { TRejectValue } from '../../../stores/types';
import type { ICrops } from '../interfaces';
import { deleteCropsAsync } from './actions/deleteCropsAsync';
import { updateCropAsync } from './actions/updateCropAsync';

interface IInitialState {
  cropList: ICrops[];
  error?: TRejectValue;
  isLoading: boolean;
}

const initialState: IInitialState = {
  cropList: [],
  error: undefined,
  isLoading: false,
};

export const cropSlice = createSlice({
  name: 'crop',
  initialState,
  reducers: {
    clearCropsState: (state) => {
      state.cropList = [];
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCropListAsync.fulfilled, (state, { payload }) => {
      state.cropList = payload;
      state.isLoading = false;
      state.error = null;
    });

    builder.addMatcher(
      isRejectedWithValue(getCropListAsync, deleteCropsAsync, updateCropAsync, createCropsAsync),
      (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
      },
    );

    builder.addMatcher(
      isPending(getCropListAsync, deleteCropsAsync, updateCropAsync, createCropsAsync),
      (state) => {
        state.isLoading = true;
        state.error = null;
      },
    );
    builder.addMatcher(isAsyncThunkAction(createCropsAsync, updateCropAsync), (state) => {
      state.isLoading = false;
      state.error = null;
    });
  },
});

export const { clearCropsState } = cropSlice.actions;
