import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { getLanguageLocalStorage } from '../../../../helpers/localStorage';
import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../../stores/types';
import type { getDeviceDTO } from '../../dto/getDevice.dto';

type sort = { sort?: string; sortDirection?: string; search?: string };
export const getDeviceListAsync = createAsyncThunk<getDeviceDTO, sort | void, ThunkConfig>(
  'device/getDevice',

  async (params: sort | void, { rejectWithValue }) => {
    try {
      const language = getLanguageLocalStorage();

      const { data } = await axios.get<{ data: getDeviceDTO }>(
        `/private/organization/devices/list-of-devices`,
        {
          headers: {
            'Accept-Language': language,
          },
          params,
        },
      );

      return data.data;
    } catch (err) {
      return rejectWithValue(ErrorChecker(err));
    }
  },
);
