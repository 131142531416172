export const cutString = (str: string, limit: number) => {
  let sliced = str.slice(0, limit);
  if (sliced.length < str.length) {
    sliced += '...';
  }
  return sliced;
};

export const windowReload = () => window.location.reload();

export const isEmpty = <T>(obj: T) =>
  obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;

export const capitalizeStr = (str: string) => {
  const newStr = str.toLowerCase();
  return newStr.charAt(0).toUpperCase() + newStr.slice(1);
};
