import { DocProperty } from 'typescript-openapi-router';
import { SensorRecord } from './sensor-record';

export class DeviceStatusModel {

  @DocProperty({
    type: 'number',
    isRequired: false
  })
  latitude?: number | null;

  @DocProperty({
    type: 'number',
    isRequired: false
  })
  longitude?: number | null;

  @DocProperty({
    type: 'number',
    isRequired: false
  })
  altitude?: number | null;

  @DocProperty({
    type: 'object',
    objectType: SensorRecord.name,
    isRequired: false
  })
  currentBatteryLevel?: SensorRecord | null;

  @DocProperty({
    type: 'object',
    objectType: SensorRecord.name,
    isRequired: false
  })
  minBatteryLevel?: SensorRecord | null;

  @DocProperty({
    type: 'object',
    objectType: SensorRecord.name,
    isRequired: false
  })
  maxBatteryLevel?: SensorRecord | null;

  @DocProperty({
    type: 'object',
    objectType: SensorRecord.name,
    isRequired: false
  })
  currentTemperature?: SensorRecord | null;

  @DocProperty({
    type: 'object',
    objectType: SensorRecord.name,
    isRequired: false
  })
  minTemperature?: SensorRecord | null;

  @DocProperty({
    type: 'object',
    objectType: SensorRecord.name,
    isRequired: false
  })
  maxTemperature?: SensorRecord | null;

  @DocProperty({
    type: 'object',
    objectType: SensorRecord.name,
    isRequired: false
  })
  currentHumidity?: SensorRecord | null;

  @DocProperty({
    type: 'object',
    objectType: SensorRecord.name,
    isRequired: false
  })
  minHumidity?: SensorRecord | null;

  @DocProperty({
    type: 'object',
    objectType: SensorRecord.name,
    isRequired: false
  })
  maxHumidity?: SensorRecord | null;

  @DocProperty({
    type: 'string',
    isRequired: false
  })
  lastUpdate?: string | null;

}
