import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { ErrorChecker } from "../../../../stores/helpers/ErrorChecker";

import type { ThunkConfig } from "../../../../stores/types";
import { UpdateDeviceRequestBody } from "@fly-workspace/lib-api-interface";

export type UpdateDeviceRequest = {
  device: UpdateDeviceRequestBody;
  deviceId: string;
}

export const updateDeviceAsync = createAsyncThunk<
  any,
  UpdateDeviceRequest,
  ThunkConfig
>('device/updateDevice', async (request, { rejectWithValue }) => {
  try {
    const { data } = await axios.put<any>(
      `/private/organizations/${request.device.organizationId}/devices/${request.deviceId}`,
      request.device,
    );

    return data.data;
  } catch (err) {
    return rejectWithValue(ErrorChecker(err));
  }
});
