import { DocProperty } from 'typescript-openapi-router';

export class InsectRecipientFillRateModel {
  @DocProperty({
    type: 'number',
    isRequired: true,
  })
  fillRate: number;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  lastCleanedAt: string;
}
