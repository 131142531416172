import React, { FC, useState } from 'react';
import moment from 'moment';
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';

import { useTranslation } from 'react-i18next';
import { Card } from '../../../../components/widgets/Card';
import { useAppDispatch, useAppSelector } from '../../../../stores/hooks';
import { addPheromoneLogAsync } from '../../store/actions/addPheromoneLogAsync';
import { pheromoneRefillTranslatePaths } from '../../translations';
import { CustomDatePicker } from '../../../../components/CustomDatePicker';

import styles from './styles.module.scss';

const pheromones = [
  {
    id: 'helicoverpaarmigera',
    value: 'CSALOMON: Helicoverpa Armighere',
  },
  {
    id: 'diabroticavirgifera',
    value: 'CSALOMON: Diabrotica Virgifera',
  },
  {
    id: 'ostrinianubilalis',
    value: 'CSALOMON: Ostrinia Nubilalis',
  },
  {
    id: 'tanymecusdilaticollis',
    value: 'CSALOMON: Tanymecus Dilaticolis',
  },
];

type TProps = {
  mainStyles: { readonly [key: string]: string };
  currentDevice: string;
};

export const PheromoneRefill: FC<TProps> = ({ mainStyles, currentDevice }: TProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { myDeviceList } = useAppSelector((state) => state.myDeviceList);
  const { isLoading } = useAppSelector((state) => state.pheromones);

  const [refillPheromoneSelect, setRefillPheromoneSelect] = useState('helicoverpaarmigera');
  const [lifespan, setLifespan] = useState(0);
  const [pheromoneRefillDate, setPheromoneRefillDate] = useState<Date | null>(new Date());

  const clearPheromoneRefill = () => {
    setLifespan(0);
    setPheromoneRefillDate(new Date());
    setRefillPheromoneSelect('helicoverpaarmigera');
  };

  const handleChangePheromone = (event: SelectChangeEvent<string>) => {
    setRefillPheromoneSelect(event.target.value);
  };

  const updateRefillHandler = async () => {
    const currentPheromone = pheromones.find((el) => el.id === refillPheromoneSelect);

    await dispatch(
      addPheromoneLogAsync({
        deviceId: currentDevice,
        body: {
          name: currentPheromone ? currentPheromone.value : '',
          insectId: refillPheromoneSelect,
          refilledAt: moment(pheromoneRefillDate).toISOString(),
          provider: '',
          lifespan: `${moment.duration(lifespan, 'd').asMilliseconds()}`,
          cropId: myDeviceList.find((el) => el.id === currentDevice)?.crop.id || '',
        },
      }),
    );

    clearPheromoneRefill();
  };

  const renderDateInput = (params: TextFieldProps) => (
    <TextField className={mainStyles.date_picker_input} {...params} />
  );

  return (
    <Card className={mainStyles.card_wrapper__gap} title={t(pheromoneRefillTranslatePaths.title)}>
      <div className={styles.refill__wrapper}>
        {isLoading ? (
          <CircularProgress size={40} className={mainStyles.loading} />
        ) : (
          <>
            <FormControl className={styles.refill__select_wrapper}>
              <InputLabel id="refill_pheromones_label">
                {t(pheromoneRefillTranslatePaths.select_label)}
              </InputLabel>
              <Select
                labelId="refill_pheromones_label"
                id="refill_pheromones"
                value={refillPheromoneSelect}
                label={t(pheromoneRefillTranslatePaths.select_label)}
                onChange={handleChangePheromone}
              >
                {pheromones.map((pheromone, index) => (
                  <MenuItem key={`pheromones_${index + pheromone.id}`} value={pheromone.id}>
                    <span>{pheromone.value}</span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className={styles.refill__item}>
              <span className={styles.refill__label}>
                {t(pheromoneRefillTranslatePaths.lifespan)}
              </span>
              <TextField
                className={styles.refill__lifespan}
                id="lifespan"
                value={lifespan}
                onChange={(event) => setLifespan(Number(event.target.value))}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
              />
            </div>

            <div className={styles.refill__item}>
              <span className={styles.refill__label}>
                {t(pheromoneRefillTranslatePaths.refilled_date)}
              </span>
              <CustomDatePicker
                value={pheromoneRefillDate}
                onChange={(newValue) => {
                  setPheromoneRefillDate(newValue as Date);
                }}
                renderInput={renderDateInput}
              />
            </div>
          </>
        )}
      </div>
      <div className={mainStyles.button__update}>
        <Button
          onClick={updateRefillHandler}
          disabled={isLoading}
          variant="contained"
          endIcon={<SaveIcon />}
        >
          {isLoading ? (
            <CircularProgress size={24} className={mainStyles.button__loading} />
          ) : (
            <>{t(pheromoneRefillTranslatePaths.button)}</>
          )}
        </Button>
      </div>
    </Card>
  );
};
