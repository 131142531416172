import React, { FC, useMemo } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { ChartManager } from "../../helpers";
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from "recharts";
import { Chart, InterestZone, SensorSource, UtilValue } from "@fly-workspace/lib-api-interface";
import { ChartColor, getBarChartColor } from "../chart-color";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";
import ReactDOMServer from "react-dom/server";

interface ILineChartProps {
  chart: Chart;
  ident: string;
}

export const LineChart: FC<ILineChartProps> = (props: ILineChartProps) => {
  const { chart, ident } = props;
  const { t } = useTranslation();

  const { minValue, maxValue } = getChartBoundaries(chart);

  const interval = ChartManager.getSizeBasedInterval(maxValue);
  const ticks = ChartManager.getSizeBasedTicks(interval, minValue, maxValue);

  // console.log(`Computing gradient for ${chart.name} with interest zones ${chart.interestZones}`);
  const gradient = useMemo(() => {
    return getChartGradient(chart, ident, maxValue);
  }, [chart.name, maxValue]);
  // if (gradient) {
  //   const htmlString = ReactDOMServer.renderToString(gradient);
  //   console.log(`Gradient for ${chart.name} is ${htmlString}`);
  // }
  const CustomLegend = useMemo(() => {
    return getCustomLegend(chart);
  }, [chart.name]);

  const CustomTooltip = useMemo(() => {
    return getCustomTooltip(chart, t);
  }, [chart.name]);

  const tooltip = gradient ? (
    <Tooltip content={CustomTooltip} />
  ) : (
    <Tooltip />
  );

  const legend = gradient ? (
    <Legend content={CustomLegend} />
  ) : (
    <Legend />
  );

  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart
        height={300}
        data={chart.data}
        stackOffset="sign"
        margin={{
          top: 5,
          right: 5,
          left: 0,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="5 5" />
        <XAxis dataKey={chart.xKey} />
        <YAxis interval={"preserveStart"} ticks={ticks} />
        {tooltip}
        {legend}
        <ReferenceLine y={0} stroke="#000" />
        {chart.displayPreference === "bar" && (
          <Bar
            barSize={30}
            name={t(`device_data.charts.${ident}`)}
            dataKey={getChartDataKey(chart)}
            fill={ChartColor.BASE_COLOR}
          />
        )}
        {chart.displayPreference === "area" && (
          <>
            {gradient}
            <Area
              strokeWidth="3"
              type="monotone"
              stroke={ChartColor.BASE_COLOR}
              fill={chart.interestZones? `url(#${ident}Gradient)` : ChartColor.BASE_COLOR}
              name={t(`device_data.charts.${ident}`)}
              dataKey={getChartDataKey(chart)}
            />
          </>
        )}
        {chart.displayPreference === "line" && (
          <>
            {gradient}
            <Line
              strokeWidth="2"
              type="monotone"
              stroke={chart.interestZones? `url(#${ident}Gradient)` : ChartColor.BASE_COLOR}
              name={t(`device_data.charts.${ident}`)}
              dataKey={getChartDataKey(chart)}
            />
          </>
        )}

      </ComposedChart>
    </ResponsiveContainer>
  );
};

const getChartDataKey = (chart: Chart) => {
  if (chart.utilValue === UtilValue.sumup || chart.utilValue === UtilValue.single)
    return "value";
  return "maxValue"; 
}

const getChartBoundaries = (chart: Chart) => {
  let min = 0;
  let max = 0;

  for (let i = 0; i < chart.data.length; i++) {
    const item = chart.data[i];

    if (item.minValue !== undefined) {
      min = Math.min(min, item.minValue);
    }

    if (item.value !== undefined) {
      min = Math.min(min, item.value);
      max = Math.max(max, item.value);
    }

    if (item.maxValue !== undefined) {
      max = Math.max(max, item.maxValue);
    }
  }
  return { minValue: min, maxValue: max };
};

const createStopElement = (offset, color, key, name) => (
  <stop
    offset={offset}
    stopColor={color}
    stopOpacity={1}
    key={key}
    name={name}
  />
);

const asPercentage = (value: number, maxValue: number): string => {
  const percentage = Math.min(100, (100 - (value * 100 / maxValue))).toFixed(2);
  return `${percentage}%`;
};

const getZoneStops = (interestZones: InterestZone[], maxValue: number) => {
  const stopsForZones = interestZones.map((zone, index) => {
    const zoneStops = [
      createStopElement(
        asPercentage(zone.start, maxValue),
        zone.color,
        zone.name + "End",
        zone.name + "End"
      ),
      createStopElement(
        asPercentage(zone.end, maxValue),
        zone.color,
        zone.name + "Start",
        zone.name + "Start"
      )
    ];

    // Add neutral stops between interest zones
    if (
      interestZones[index + 1] !== undefined &&
      interestZones[index + 1].end !== zone.start
    ) {
      const neutralStops = [
        createStopElement(
          asPercentage(zone.end, maxValue),
          ChartColor.BASE_COLOR,
          "Neutral" + index + "End",
          "Neutral" + index + "End"
        ),
        createStopElement(
          asPercentage(interestZones[index + 1].start, maxValue),
          ChartColor.BASE_COLOR,
          "Neutral" + index + "Start",
          "Neutral" + index + "Start"
        )
      ];

      zoneStops.push(...neutralStops);
    }

    return zoneStops;
  });

  return stopsForZones.flat();
};

const createEndBoundaryStops = (zone: InterestZone, maxValue: number): JSX.Element[] => {
  return [
    createStopElement(asPercentage(zone.end, maxValue), ChartColor.BASE_COLOR, "NeutralStart", "NeutralStart"),
    createStopElement(
      asPercentage(maxValue, maxValue),
      ChartColor.BASE_COLOR,
      "NeutralEnd",
      "NeutralEnd"
    )
  ];
};

const createStartBoundaryStops = (zone: InterestZone, maxValue: number): JSX.Element[] => {
  return [
    createStopElement(
      asPercentage(0, maxValue),
      ChartColor.BASE_COLOR,
      "NeutralFinishEnd",
      "NeutralFinishEnd"
    ),
    createStopElement(asPercentage(zone.start, maxValue), ChartColor.BASE_COLOR, "NeutralFinishStart", "NeutralFinishStart")
  ];
};

const getChartGradient = (chart: Chart, ident: string, maxValue: number) => {
  if (!chart.interestZones || chart.interestZones.length === 0) {
    return null;
  }

  const lastZone = chart.interestZones[chart.interestZones.length - 1];
  const firstZone = chart.interestZones[0];

  const endBoundaryStops = firstZone.start === 0 ? [] : createEndBoundaryStops(lastZone, maxValue);
  const startBoundaryStops = lastZone.end === maxValue ? [] : createStartBoundaryStops(firstZone, maxValue);
  const interestZoneStops: JSX.Element[] = getZoneStops(chart.interestZones, maxValue);
  const allStops = [...startBoundaryStops, ...interestZoneStops, ...endBoundaryStops].reverse();

  return (
    <defs>
      <linearGradient id={`${ident}Gradient`} x1="0" y1="0" x2="0" y2="1">
        {allStops}
      </linearGradient>
    </defs>
  );
};

const getCustomLegend = (chart: Chart) => (props) => {
  const { payload } = props;
  const { t } = useTranslation();
  return <Box display={"flex"} flexDirection={"column"} flexWrap={"wrap"} justifyContent={"center"} gap={"1rem"}>
    {payload.map((entry, index) => (
      <Typography align={"center"} key={`item-${index}`}
                  style={{ color: ChartColor.BASE_COLOR }}>{entry.value}</Typography>
    ))}
    {chart.interestZones?.map((zone, index) => (
      <Typography align={"center"} key={`item-${index}`}
                  style={{ color: zone.color }}>{t(`device_data.charts.interest_zones.${zone.name}`)}</Typography>
    ))}
  </Box>;
};

const getCustomTooltip = (chart: Chart, t: TFunction<"translation", undefined>) => (props: TooltipProps<any, any>) => {
  const { active, payload, label } = props;
  if (active && payload) {
    return <Paper>
      <Box padding={"0.5rem"}>
        <Typography>{label}</Typography>
        {payload.map((entry: any) => {
          const { date, value } = entry.payload;
          const interestZone = chart.interestZones?.find((zone) => {
            return zone.start <= value && zone.end >= value;
          });
          return <Box key={date}>
            <Typography style={{ color: ChartColor.BASE_COLOR }}>{value}</Typography>
            {interestZone && <Typography
              style={{ color: interestZone.color }}>{t(`device_data.charts.interest_zones.${interestZone.name}`)}</Typography>}
          </Box>;
        })}
      </Box>
    </Paper>;
  }

  return null;
};
