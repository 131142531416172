import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../../stores/types';
import type { deleteDeviceRequestDTO } from '../../dto/deleteDevice.dto';

export const deleteDeviceAsync = createAsyncThunk<null, deleteDeviceRequestDTO, ThunkConfig>(
  'device/deleteDevice',
  async ({ deviceId }, { rejectWithValue }) => {
    try {
      await axios.delete(`/private/device/${deviceId}`);

      return null;
    } catch (err) {
      return rejectWithValue(ErrorChecker(err));
    }
  },
);
