import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { getLanguageLocalStorage } from '../../../../helpers/localStorage';
import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../../stores/types';
import type { getCropDTO } from '../../dto/getCrops.dto';

type GetCropListRequestParams = { sort?: string; sortDirection?: string; search?: string };

export const getCropListAsync = createAsyncThunk<getCropDTO, GetCropListRequestParams | void, ThunkConfig>(
  'crop/getCrops',

  async (params: GetCropListRequestParams | void, { rejectWithValue }) => {
    try {
      const language = getLanguageLocalStorage();

      const { data } = await axios.get<{ data: getCropDTO }>(`/private/crops/list-of-crop`, {
        headers: {
          'Accept-Language': language,
        },
        params,
      });

      return data.data;
    } catch (err) {
      return rejectWithValue(ErrorChecker(err));
    }
  },
);
