import { MapOptions, Maps } from 'google-map-react';

export const getMapOptions = (maps: Maps): MapOptions => ({
  zoomControlOptions: {
    position: maps.ControlPosition.RIGHT_BOTTOM,
  },
  mapTypeControlOptions: {
    position: maps.ControlPosition.TOP_RIGHT,
  },
  mapTypeControl: true,
  mapTypeId: 'hybrid',
});
