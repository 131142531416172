import { useEffect, useState } from 'react';
import { IInsect } from 'src/app/interfaces/insect';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSeparateInsectPerPage = (data: any) => {
  const [insectForAllPages, setInsectForAllPages] = useState<IInsect[][]>([]);

  useEffect(() => {
    const chunksArray: IInsect[][] = [];
    for (let i = 0; i < data.insectList?.length; i += data.rowsPerPage) {
      const chunk = data.insectList.slice(i, i + data.rowsPerPage);

      chunksArray.push(chunk);
    }

    setInsectForAllPages(chunksArray);
  }, [data.rowsPerPage, data.insectList]);

  return {
    insectForAllPages,
  };
};
