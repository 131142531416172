import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../../stores/types';
import type { deleteCropRequestDTO } from '../../dto/deleteCrops.dto';

export const deleteCropsAsync = createAsyncThunk<null, deleteCropRequestDTO, ThunkConfig>(
  'crop/deleteCrop',
  async ({ cropId }, { rejectWithValue }) => {
    try {
      await axios.delete(`/private/crop/${cropId}`);

      return null;
    } catch (err) {
      return rejectWithValue(ErrorChecker(err));
    }
  },
);
