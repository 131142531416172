import { configureStore } from '@reduxjs/toolkit';
import { userSlice } from '../pages/Login/store';
import {
  deviceListSlice,
  myDevicesListSlice,
  deviceListUpdateSlice,
} from '../pages/ManageDevices/store';
import { mainSlice } from '../main/store';
import { alertSlice } from '../pages/Alerts/store';
import { deviceInfoSlice } from '../pages/DeviceData/store';
import { insectListSlice } from '../pages/DeviceData/store/insect-slice';
import { deviceSetAlertsSlice } from '../pages/SetUpAlerts/store';
import { deviceApiSlice } from './api/device-info-api-slice';
import { navbarSlice } from '../pages/Navbar/store';
import { pheromonesSlice } from '../pages/Pheromones/store';
import { usersSlice } from '../pages/Users/store';
import { deviceSlice } from '../pages/Device/store';
import { cropSlice } from '../pages/Crops/store';
import { insectSlice } from '../pages/Insect/store';
import { organizationSlice } from '../pages/organization/store';

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    deviceList: deviceListSlice.reducer,
    myDeviceList: myDevicesListSlice.reducer,
    main: mainSlice.reducer,
    updateListDevices: deviceListUpdateSlice.reducer,
    alertList: alertSlice.reducer,
    deviceInfo: deviceInfoSlice.reducer,
    insectsList: insectListSlice.reducer,
    setAlertsDevices: deviceSetAlertsSlice.reducer,
    navbar: navbarSlice.reducer,
    pheromones: pheromonesSlice.reducer,
    userList: usersSlice.reducer,
    listOfDevice: deviceSlice.reducer,
    listOfCrops: cropSlice.reducer,
    listOfInsect: insectSlice.reducer,
    listOfOrganization: organizationSlice.reducer,
    [deviceApiSlice.reducerPath]: deviceApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(deviceApiSlice.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
