import { DocProperty } from 'typescript-openapi-router';

export class ApiErrorModel {
  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  code?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  message?: string;
}
