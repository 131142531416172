import { DocProperty } from 'typescript-openapi-router';
import { ActiveAlertModel } from '../model';

export class AddAlertsRequestBody {

  @DocProperty({
    type: 'array',
    objectType: ActiveAlertModel.name,
    isRequired: true,
    minSize: 1
  })
  alerts: ActiveAlertModel[]
}
