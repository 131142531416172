import { TooltipProps } from "recharts";
import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { GDDChartColor } from "./chart-colors";

export const CustomTooltip = (props: TooltipProps<any, any>) => {
  const { active, payload, label } = props;
  if (active && payload) {
    return <Paper>
      <Box padding={"0.5rem"}>
        <Typography>{label}</Typography>
        {payload.map((entry: any) => {
          const { gdd, info, sentAlerts } = entry.payload;
          return <Box key={gdd}>
            <Typography style={{color:GDDChartColor.gddValueColor}}>GDD: {gdd}</Typography>
            {info?.map((value: any, index: number) => {
              return <Typography key={index} style={{color:GDDChartColor.alertPredictionColor}}>Prediction: {value}</Typography>;
            })}
            {sentAlerts?.map((content: any, index: number) => {
              return <Typography key={index} style={{color:GDDChartColor.sentAlertColor}}>Generated: {content}</Typography>;
            })}
          </Box>;
        })}
      </Box>
    </Paper>;
  }

  return null;
};
