import { DocProperty, InheritedDoc } from 'typescript-openapi-router';
import { PheromoneModel } from '../model/pheromone-model';
import { ResponseEnvelope } from './response-envelope';

@InheritedDoc()
export class PheromoneListResponseBody extends ResponseEnvelope {
  @DocProperty({
    type: 'array',
    objectType: PheromoneModel.name,
    isRequired: true,
  })
  data: PheromoneModel[];
}
