import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';
import { getOrganizationIdLocalStorage } from '../../../../helpers/localStorage';

import type { ThunkConfig } from '../../../../stores/types';
import {
  getInsectRecipientFillRateDTO,
  getInsectRecipientFillRateRequestDTO,
} from '../../dto/getInsectRecipientFillRate.dto';

export const getInsectRecipientFillRateAsync = createAsyncThunk<
  getInsectRecipientFillRateDTO,
  getInsectRecipientFillRateRequestDTO,
  ThunkConfig
>('pheromones/insect-fill-rate', async ({ deviceId }, { rejectWithValue }) => {
  const organizationId = getOrganizationIdLocalStorage();

  try {
    const { data } = await axios.get<{
      data: getInsectRecipientFillRateDTO;
    }>(`/private/organizations/${organizationId}/devices/${deviceId}/insect-recipient-fill-rate`);

    return data.data;
  } catch (err) {
    return rejectWithValue(ErrorChecker(err));
  }
});
