
export const deviceStatusTranslatePaths = {
  title_zero: 'device_data.device_status.title_zero',
  title: 'device_data.device_status.title',
  location: 'device_data.device_status.location',
  buttons: {
    open_in_maps: 'device_data.device_status.buttons.open_in_maps',
    copy_coordinates: 'device_data.device_status.buttons.copy_coordinates',
  },
  cards: {
    battery: 'device_data.device_status.cards.battery',
    temperature: 'device_data.device_status.cards.temperature',
    humidity: 'device_data.device_status.cards.humidity',
    last_update: 'device_data.device_status.cards.last_update',
    last_known_value: 'device_data.device_status.cards.last_known_value',
    minimum: 'device_data.device_status.cards.minimum',
    maximum: 'device_data.device_status.cards.maximum',
  }
};
