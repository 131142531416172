import { DocProperty } from 'typescript-openapi-router';

export class EditUserOrganization {
  @DocProperty({
    type: 'number',
    isRequired: true,
  })
  newOrganizationId: number;

  @DocProperty({
    type: 'number',
    isRequired: true,
  })
  oldOrganizationId: number;
}
