
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  getLanguageLocalStorage,
  getOrganizationIdLocalStorage,
} from '../../../../helpers/localStorage';
import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../../stores/types';

export type UpdateInsectNameRequest = {
  insectId: string;
  recordedAt: string;
  deviceId: string;
}

export const updateInsectNameAsync = createAsyncThunk<
  any,
  UpdateInsectNameRequest,
  ThunkConfig
  >('/data/insect', async ({ insectId, recordedAt, deviceId }, { rejectWithValue }) => {
  try {
    const organizationId = getOrganizationIdLocalStorage();
    const language = getLanguageLocalStorage();

    const { data } = await axios.put<{ data: UpdateInsectNameRequest }>(
      ///private/data/{deviceId}/insect
      `/private/data/${organizationId}/devices/${deviceId}/insect`,
      {
        insectId,
        recordedAt
      },
      {
        headers: {
          'Accept-Language': language,
        },
      },
    );

    return data.data;
  } catch (err) {
    return rejectWithValue(ErrorChecker(err));
  }
});
