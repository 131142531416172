import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { ErrorChecker } from "../../../../stores/helpers/ErrorChecker";

import type { ThunkConfig } from "../../../../stores/types";
import type { updateUserOrganizationRequest } from "../../dto/updateUser.dto";
import { UserModel } from "@fly-workspace/lib-api-interface";

export const updateUserOrganizationAsync = createAsyncThunk<
  UserModel,
  updateUserOrganizationRequest,
  ThunkConfig
>("users/updateUser", async (request, { rejectWithValue }) => {
  try {
    const { data } = await axios.put<{ data: UserModel }>(`/private/user/${request.userId}`, {
      ...request
    });

    return data.data;
  } catch (err) {
    return rejectWithValue(ErrorChecker(err));
  }
});
