import { createSlice } from '@reduxjs/toolkit';

import { getAllDevicesAsync } from './actions';

import type { IDeviceData } from '../interfaces/deviceData';
import type { TRejectValue } from '../../../stores/types';

interface IInitialState {
  data: IDeviceData[];
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage?: TRejectValue;
}

const initialState: IInitialState = {
  data: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: undefined,
};

export const deviceListSlice = createSlice({
  name: 'deviceList',
  initialState,
  reducers: {
    clearAllDevicesState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDevicesAsync.fulfilled, (state, { payload }) => {
      state.data = payload.map((el) => ({
        ...el,
        cropName: el.crop?.name,
        cropId: el.crop?.id,
      }));
      state.isFetching = false;
      state.isSuccess = true;
    });
    builder.addCase(getAllDevicesAsync.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    });
    builder.addCase(getAllDevicesAsync.pending, (state) => {
      state.isFetching = true;
    });
  },
});

export const { clearAllDevicesState } = deviceListSlice.actions;
