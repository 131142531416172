import { DocProperty, InheritedDoc } from 'typescript-openapi-router';
import { RolesListModel } from "../model";
import { ResponseEnvelope } from './response-envelope';

@InheritedDoc()
export class RolesListResponse extends ResponseEnvelope {

  @DocProperty({
    type: 'object',
    objectType: RolesListModel.name,
    isRequired: false
  })
  data?: RolesListModel;
}
