import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { getOrganizationIdLocalStorage } from '../../../../helpers/localStorage';
import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../../stores/types';
import type { TCropNameUnion } from '../../../../interfaces/crop';

type updateDeviceListRequestDTO = {
  body: {
    items: {
      id: string;
      alias: string;
      cropId: string | null;
      cropName: string | null;
      isManagedByMe: boolean;
    }[];
  };
};

export const updateDeviceListAsync = createAsyncThunk<
  null,
  updateDeviceListRequestDTO,
  ThunkConfig
>('devices/organization/update', async ({ body }, { rejectWithValue }) => {
  const organizationId = getOrganizationIdLocalStorage();
  try {
    await axios.put(`/private/organizations/${organizationId}/devices`, body);

    return null;
  } catch (err) {
    return rejectWithValue(ErrorChecker(err));
  }
});
