import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import { ELanguages } from "../constants";
import {
  getDeviceIdLocalStorage,
  getLanguageLocalStorage,
  getOrganizationIdLocalStorage,
  removeDeviceIdLocalStorage,
  setDeviceIdLocalStorage,
  setOrganizationIdLocalStorage
} from "../../../helpers/localStorage";
import { getDeviceInfoAsync } from "./actions/getDeviceInfoAsync";

import type { IDates } from "../../DeviceData/interfaces";
import type { TRejectValue } from "../../../stores/types";
import type { IMyDeviceData } from "../../ManageDevices/interfaces/myDeviceData";
import type { IOrganization } from "../../../interfaces/organization";
import { DeviceInfoModel } from "@fly-workspace/lib-api-interface";

interface IInitialState {
  language: ELanguages;
  deviceId?: string;
  organizationId: number;
  organization?: IOrganization;
  deviceInfo?: DeviceInfoModel;
  currentDevice?: IMyDeviceData;
  dates: IDates;
  error?: TRejectValue;
  isLoading: boolean;
}

const initialState: IInitialState = {
  language: getLanguageLocalStorage(),
  deviceId: getDeviceIdLocalStorage(),
  organizationId: getOrganizationIdLocalStorage(),
  organization: undefined,
  deviceInfo: undefined,
  currentDevice: undefined,
  dates: {
    startDate: moment().startOf("day").subtract(1, "month").utc(true).toISOString(),
    endDate: moment().endOf("day").utc(true).toISOString()
  },
  isLoading: false,
  error: undefined
};

export const navbarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    clearNavbarState: (state) => {
      state.language = ELanguages.english;
      state.deviceInfo = undefined;
      state.deviceId = "";
      state.isLoading = false;
      state.error = undefined;
      removeDeviceIdLocalStorage();
    },
    setLanguage: (state, { payload }: PayloadAction<ELanguages>) => {
      state.language = payload;
    },
    setDeviceId: (state, { payload }: PayloadAction<string>) => {
      setDeviceIdLocalStorage(payload);
      state.deviceId = payload;
    },
    setOrganizationId: (state, { payload }: PayloadAction<string>) => {
      state.organizationId = Number(payload);
      setOrganizationIdLocalStorage(payload);
    },
    setOrganization: (state, { payload, type }: PayloadAction<IOrganization | null>) => {
      if (payload) {
        navbarSlice.caseReducers.setOrganizationId(state, { payload: String(payload.id), type });
        state.organization = payload;
      }
    },
    setDates: (state, { payload }: PayloadAction<IDates>) => {
      state.dates = {
        ...state.dates,
        ...payload
      };
    },
    setCurrentDevice: (state, { payload }: PayloadAction<IMyDeviceData | undefined>) => {
      state.currentDevice = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDeviceInfoAsync.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getDeviceInfoAsync.fulfilled, (state, { payload }) => {
      state.deviceInfo = payload;
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(getDeviceInfoAsync.rejected, (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
      state.deviceInfo = undefined;
    });
  }
});

export const {
  clearNavbarState,
  setLanguage,
  setDeviceId,
  setDates,
  setCurrentDevice,
  setOrganization,
  setOrganizationId
} = navbarSlice.actions;
