import { createSlice, isPending, isAsyncThunkAction, isRejectedWithValue } from '@reduxjs/toolkit';

import { IOrganization } from 'src/app/interfaces/organization';
import { getOrganizationListAsync } from './actions/getOrganizationListAsync';
import type { TRejectValue } from '../../../stores/types';

interface IInitialState {
  organizationList: IOrganization[];
  error?: TRejectValue;
  isLoading: boolean;
}

const initialState: IInitialState = {
  organizationList: [],
  error: undefined,
  isLoading: false,
};

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    clearOrganizationState: (state) => {
      state.organizationList = [];
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrganizationListAsync.fulfilled, (state, { payload }) => {

      state.organizationList = payload;
      state.isLoading = false;
      state.error = null;
    });

    builder.addMatcher(isRejectedWithValue(getOrganizationListAsync), (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    });

    builder.addMatcher(isPending(getOrganizationListAsync), (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addMatcher(isAsyncThunkAction(), (state) => {
      state.isLoading = false;
      state.error = null;
    });
  },
});

export const { clearOrganizationState } = organizationSlice.actions;
