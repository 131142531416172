import { enumToArray } from '@xtool/enum-to-array';
import { DocProperty } from 'typescript-openapi-router';
import { CropModel, OrganizationModel, OrganizationRole, Role, UserModel } from '.';
class DeviceModelWithoutField {
  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  id: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  alias: string;

  @DocProperty({
    type: 'object',
    objectType: CropModel.name,
    isRequired: false,
  })
  crop?: CropModel;

  @DocProperty({
    type: 'number',
    isRequired: false,
  })
  alertCount?: number;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  lastLocationRecorded?: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  serialNumber: string;
}

export class DeviceCropChangeLogModel {
  @DocProperty({
    type: 'number',
    isRequired: false,
  })
  id?: number;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  createdAt?: string;

  @DocProperty({
    type: 'number',
    isRequired: false,
  })
  userId?: number;

  @DocProperty({
    type: 'string',
    isRequired: false,
    enum: enumToArray(Role),
  })
  userPlatformRole?: Role;

  @DocProperty({
    type: 'number',
    isRequired: false,
  })
  organizationId?: number;

  @DocProperty({
    type: 'string',
    isRequired: false,
    enum: enumToArray(OrganizationRole),
  })
  userOrganizationRole?: OrganizationRole;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  deviceId?: string;

  @DocProperty({
    type: 'object',
    objectType: UserModel.name,
    isRequired: false,
  })
  user?: UserModel;

  @DocProperty({
    type: 'object',
    objectType: OrganizationModel.name,
    isRequired: false,
  })
  organization?: OrganizationModel;

  @DocProperty({
    type: 'object',
    objectType: DeviceModelWithoutField.name,
    isRequired: false,
  })
  device?: DeviceModelWithoutField;
}

