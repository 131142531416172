export const alertsTranslatePaths = {
  device_period: {
    title: 'alerts.device_period.title',
    select_device_label: 'alerts.device_period.select_device_label',
    choose_period_label: 'alerts.device_period.choose_period_label',
  },
  alerts_received: {
    title: 'alerts.alerts_received.title',
    button: 'alerts.alerts_received.button',
    empty_text: {
      first_sentence: 'alerts.alerts_received.empty_text.first_sentence',
      second_sentence: 'alerts.alerts_received.empty_text.second_sentence',
    },
    modal: {
      title: 'alerts.alerts_received.modal.title',
      clear_text: 'alerts.alerts_received.modal.clear_text',
      delete_text: 'alerts.alerts_received.modal.delete_text',
      buttons: {
        ok: 'alerts.alerts_received.modal.buttons.ok',
        cancel: 'alerts.alerts_received.modal.buttons.cancel',
      },
    },
  },
};
