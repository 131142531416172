import { DocProperty } from 'typescript-openapi-router';
import { Identity } from './identity';

export class S3Bucket {
  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  name: string;

  @DocProperty({
    type: 'object',
    objectType: Identity.name,
    isRequired: true,
  })
  ownerIdentity: Identity;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  arn: string;
}
