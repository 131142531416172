import MButton from '@mui/material/Button';
import { ButtonProps as MButtonProps } from '@mui/material/Button';
import { Box } from '@mui/material';

type ButtonProps = MButtonProps & {
  maxWidth?: string;
};

export const Button = (props: ButtonProps) => {
  const {children, maxWidth, ...rest} = props;
  return <Box maxWidth={maxWidth}>
    <MButton variant={'contained'} {...rest}>
      <span style={{ color: 'white' }}>{children}</span>
    </MButton>
  </Box>;
}
