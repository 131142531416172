import { DocProperty } from 'typescript-openapi-router';
import { enumToArray } from '@xtool/enum-to-array';
import { AlertType } from './alert-type';

export class ActiveAlertSentModel {
  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  deviceId?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  deviceAlias?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  createdAt?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  content?: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
    enum: enumToArray(AlertType),
  })
  type: AlertType;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  id: string;
}
