import { Chart } from './chart';
import { DocProperty, InheritedDoc } from 'typescript-openapi-router';
import { InsectDetectionModel } from './insect-detection-model';

@InheritedDoc()
export class InsectChart extends Chart {

  @DocProperty({
    type: 'array',
    objectType: InsectDetectionModel.name,
    isRequired: true
  })
  allDetections: InsectDetectionModel[];

  @DocProperty({
    type: 'array',
    objectType: InsectDetectionModel.name,
    isRequired: false
  })
  firstOccurrences?: InsectDetectionModel[];
}
