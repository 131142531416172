import { formatToDate } from "../../../../../../../libs/common-utils/src";
import { Chart, DeviceInfoModel, InsectChart, UtilValue } from "@fly-workspace/lib-api-interface";

const getBasicDownloadData = (chartName: string, deviceInfo: DeviceInfoModel): string => {
  const chart: Chart = deviceInfo.charts?.find(chart => chart.name === chartName) as Chart;
  return getBasicDownloadChartData(chart);
}

const getBasicDownloadChartData = (chart: Chart): string => {
  if (chart.utilValue === UtilValue.sumup || chart.utilValue === UtilValue.single) {
    let data = "date,val,source\r\n";
    for (const item of chart.data) {
      data += `${item.date},${item.value},${item.source}\r\n`;
    }
    return data;
  }
  let data = "date,min,max,avg,source\r\n";
  for (const item of chart.data) {
    data += `${item.date},${item.minValue},${item.maxValue},${item.avgValue},${item.source}\r\n`;
  }
  return data;
}

const getInsectDownloadData = (deviceInfo: DeviceInfoModel): string => {
  const insectChart: InsectChart | undefined = deviceInfo.charts?.find(chart => chart.name === 'insect') as InsectChart;
  return getInsectDownloadChartData(insectChart);
};

const getInsectDownloadChartData = (chart: InsectChart | undefined): string => {
  let data = "date,insect name, picture\r\n";
  const detections = chart?.allDetections ?? [];
  for (const item of detections) {
    data += `${item.photoDisplayRecordedAt},${item.name},https://cdn.fly.teraseya.com/${item.photo}\r\n`;
  }
  return data;
};

const getDownloadData = (chartName: string, deviceInfo: DeviceInfoModel): string => {
  switch (chartName) {
    case "insect":
      return getInsectDownloadData(deviceInfo);
    default:
      return getBasicDownloadData(chartName, deviceInfo);
  }
};

const getDownloadChartData = (chart: Chart): string => {
  switch (chart.name) {
    case "insect":
      return getInsectDownloadChartData(chart as InsectChart);
    default:
      return getBasicDownloadChartData(chart);
  }
};

export const downloadCSV = (chartName: string, deviceInfo: DeviceInfoModel, deviceId: string, ident?: string) => {
  const data = getDownloadData(chartName, deviceInfo);
  createDownloadElement(data, deviceId, ident ? ident : chartName);
};

export const downloadChartCSV = (chart: Chart, deviceId: string, ident?: string) => {
  const data = getDownloadChartData(chart);
  createDownloadElement(data, deviceId, ident ? ident : chart.name);
};

const createDownloadElement = (data: string, deviceId: string, ident: string) => {
  const element = document.createElement("a");
  const file = new Blob([data], {
    type: "text/csv;charset=utf-8"
  });
  element.href = URL.createObjectURL(file);
  element.download = `${deviceId}_${formatToDate(new Date())}_${ident}.csv`;
  document.body.appendChild(element);
  element.click();
};
