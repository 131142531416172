import React from "react";
import { Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Card } from "../../../../components/widgets/Card";
import { dashboardTranslatePaths } from "../../translations/dashboard";
import { Legend } from "../../constants";

import MarkerGood from "../../../../../assets/marker-good.svg";
import MarkerBad from "../../../../../assets/marker-bad.svg";
import MarkerNoConnection from "../../../../../assets/marker-no-connection.svg";
import MarkerVirtual from "../../../../../assets/marker-virtual.svg";
import styles from "./styles.module.scss";

type TLegendPinsUnion = keyof typeof dashboardTranslatePaths.legend.pins;

export const Legendary = () => {
  const { t } = useTranslation();

  const checkSrc = (value: string) => {
    switch (value) {
      case Legend.success:
        return MarkerGood;
      case Legend.error:
        return MarkerBad;
      case Legend.noConnection:
        return MarkerNoConnection;
      case Legend.virtual:
        return MarkerVirtual;
      default:
        return '';
    }
  };

  return (
    <Card className={styles.legend}>
      <div className={styles.legend__header}>
        <Typography align="left" variant="body1">
          {t(dashboardTranslatePaths.legend.title)}
        </Typography>
      </div>
      <Divider />
      {Object.values(Legend).map((value) => (
        <div key={value} className={styles.legend__item}>
          <img width={40} src={checkSrc(value)} alt={value} />
          <p className={styles.legend__item_text}>
            {t(dashboardTranslatePaths.legend.pins[value as TLegendPinsUnion])}
          </p>
        </div>
      ))}
    </Card>
  );
};
