import { DocProperty } from "typescript-openapi-router";
import { ChartData } from "./chart-data";
import { InterestZone } from "./interest-zone";

export class Chart {

  @DocProperty({
    type: "string",
    isRequired: true
  })
  name: string;

  @DocProperty({
    type: "string",
    isRequired: false
  })
  ident?: string;

  @DocProperty({
    type: "string",
    isRequired: false
  })
  utilValue?: string;

  @DocProperty({
    type: 'array',
    objectType: ChartData.name,
    isRequired: true
  })
  data: ChartData[];

  @DocProperty({
    type: 'array',
    objectType: "string",
    isRequired: true
  })
  dataKeys: string[];

  @DocProperty({
    type: "string",
    isRequired: true
  })
  xKey: string;

  @DocProperty({
    type: "string",
    isRequired: true,
    enum: ["line", "bar", "area"]
  })
  displayPreference: "line" | "bar" | "area";

  @DocProperty({
    type: "object",
    objectType: InterestZone.name,
    isRequired: false,
  })
  interestZones?: InterestZone[];
}
