export const usersTranslatePaths = {
  title: 'users.title',
  header: {
    search: 'users.header.search',
    sort_by: 'users.header.sort_by',
    direction: 'users.header.direction',
    create_user: 'users.header.create_user',
  },
  sort: {
    created_at: 'users.sort.created_at',
    updated_at: 'users.sort.updated_at',
    app_role: 'users.sort.app_role',
    organization_role: 'users.sort.organization_role',
    lastName: 'users.sort.lastName',
    firstName: 'users.sort.firstName',
  },
  users_list: {
    created: 'users.users_list.created',
    updated: 'users.users_list.updated',
    app_role: 'users.users_list.app_role',
    org_role: 'users.users_list.org_role',
    email: 'users.users_list.email',
    comment: 'users.users_list.comment',
  },
  keys: {
    ADMIN: 'users.keys.ADMIN',
    USER: 'users.keys.USER',
    VIEWER: 'users.keys.VIEWER',
    EDITOR: 'users.keys.EDITOR',
  },
  add_user: {
    title: 'users.add_user.title',
    first_name: 'users.add_user.first_name',
    last_name: 'users.add_user.last_name',
    email: 'users.add_user.email',
    phone_number: 'users.add_user.phone_number',
    initial_password: 'users.add_user.initial_password',
    app_role: 'users.add_user.app_role',
    organization: 'users.add_user.organization',
    org_role: 'users.add_user.org_role',
    comment: 'users.add_user.comment',
    ok: 'users.add_user.ok',
    cancel: 'users.add_user.cancel',
    admin_password: 'users.add_user.admin_password',
  },
  edit_user: {
    title: 'users.edit_user.title',
    first_name: 'users.edit_user.first_name',
    last_name: 'users.edit_user.last_name',
    email: 'users.edit_user.email',
    phone_number: 'users.edit_user.phone_number',
    initial_password: 'users.edit_user.initial_password',
    app_role: 'users.edit_user.app_role',
    organization: 'users.edit_user.organization',
    org_role: 'users.edit_user.org_role',
    comment: 'users.edit_user.comment',
    ok: 'users.edit_user.ok',
    cancel: 'users.edit_user.cancel',
    admin_password: 'users.edit_user.admin_password',
  },
};
