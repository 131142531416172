import { DocProperty } from 'typescript-openapi-router';

export class SensorRecord {

  @DocProperty({
    type: 'string',
    isRequired: true
  })
  date: string;

  @DocProperty({
    type: 'number',
    isRequired: false
  })
  value?: number;
}
