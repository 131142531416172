import { BaseCronRequest } from "./base-cron-request";
import { DocProperty, InheritedDoc } from "typescript-openapi-router";

@InheritedDoc()
export class IdentifyInsectsRequest extends BaseCronRequest {

  @DocProperty({
    type: "string",
    isRequired: false
  })
  startDate?: string;
}
