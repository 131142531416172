import { useEffect, useState } from 'react';
import { IOrganization } from 'src/app/interfaces/organization';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSeparateOrganizationPerPage = (data: any) => {
  const [organizationForAllPages, setOrganizationForAllPages] = useState<IOrganization[][]>([]);

  useEffect(() => {
    const chunksArray: IOrganization[][] = [];
    for (let i = 0; i < data.organizationList?.length; i += data.rowsPerPage) {
      const chunk = data.organizationList.slice(i, i + data.rowsPerPage);

      chunksArray.push(chunk);
    }

    setOrganizationForAllPages(chunksArray);
  }, [data.rowsPerPage, data.organizationList]);

  return {
    organizationForAllPages,
  };
};
