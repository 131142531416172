import { DocProperty } from "typescript-openapi-router";

export class InterestZone {

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  name: string;

  @DocProperty({
    type: 'number',
    isRequired: true,
  })
  start: number;

  @DocProperty({
    type: 'number',
    isRequired: true,
  })
  end: number;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  color: string;

}
