import { WeatherSensor } from './weather-sensor';
import { DocProperty } from 'typescript-openapi-router';
import { enumToArray } from '@xtool/enum-to-array';

export class WeatherAlertModel {

  @DocProperty({
    type: 'string',
    isRequired: true,
    enum: enumToArray(WeatherSensor)
  })
  sensor: WeatherSensor;

  @DocProperty({
    type: 'number',
    isRequired: false
  })
  min?: number;

  @DocProperty({
    type: 'number',
    isRequired: false
  })
  max?: number;
}
