import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Button, DialogActions, DialogContent, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

import { IOrganization } from "src/app/interfaces/organization";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../components/Modal";
import { useAppDispatch } from "../../../../stores/hooks";
import { initialFields } from "../../constants/OrganizationModal";
import { useSetTouched } from "../../hooks/useSetTouched";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { organizationTranslatePaths } from "../../translations";
import { createOrganizationAsync } from "../../store/actions/createOrganizationListAsync";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from "./styles.module.scss";
import { ICreateOrganizationRequestDTO } from "../../dto/createOrganization.dto";

type TProps = {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
  currentOrganization?: IOrganization | null;
  isReadonly?: boolean;
};

export const OrganizationModal: FC<TProps> = ({
  isOpen,
  onClose,
  onAccept,
  currentOrganization,
  isReadonly,
}: TProps) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const dispatch = useAppDispatch();

  const [fields, setFields] = useState(initialFields);
  const { isTouched } = useSetTouched({ fields });

  useEffect(() => {
    if (currentOrganization) {
      setFields((prevState) => ({
        ...prevState,
        alias: currentOrganization?.name ?? '',
      }));
    }
  }, [currentOrganization]);

  const updateFieldsHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: string,
  ) => {
    setFields((prevState) => ({
      ...prevState,
      [field]: event.target.value,
    }));
  };

  const submit = async () => {
    const orgData = {
      ...fields,
      registrationNumber: Number(fields.registrationNumber),
      contractPerson: {
        firstName: fields.contractFirstName,

        lastName: fields.contractLastName,

        email: fields.contractEmail,

        phoneNumber: fields.contractPhoneNumber,
      },
    };
    await dispatch(createOrganizationAsync(orgData as ICreateOrganizationRequestDTO));
    onAccept();
  };

  return (
    <BootstrapDialog
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      aria-labelledby="create_organization_title"
      open={isOpen}
      fullScreen={width <= 768}
    >
      <BootstrapDialogTitle id="create_organization_title" onClose={onClose}>
        {t(organizationTranslatePaths.add_organizations.title)}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <div className={styles.organization_modal__field_container}>
          <TextField
            key="name"
            id="name"
            placeholder={t(organizationTranslatePaths.add_organizations.name)}
            required
            value={fields.name}
            onChange={(event) => updateFieldsHandler(event, 'name')}
            fullWidth
            disabled={isReadonly}
          />
          <div className={styles.organization_modal__select_label}>
            {t(organizationTranslatePaths.add_organizations.address)}
          </div>
          <TextField
            key="streetAddress"
            id="street_address"
            placeholder={t(organizationTranslatePaths.add_organizations.street_address)}
            required
            value={fields.streetAddress}
            onChange={(event) => updateFieldsHandler(event, 'streetAddress')}
            fullWidth
            disabled={isReadonly}
          />
          <TextField
            key="country"
            id="country"
            placeholder={t(organizationTranslatePaths.add_organizations.country)}
            required
            value={fields.country}
            onChange={(event) => updateFieldsHandler(event, 'country')}
            fullWidth
            disabled={isReadonly}
          />
          <TextField
            key="city"
            id="city"
            placeholder={t(organizationTranslatePaths.add_organizations.city)}
            required
            value={fields.city}
            onChange={(event) => updateFieldsHandler(event, 'city')}
            fullWidth
            disabled={isReadonly}
          />
          <TextField
            key="zip"
            id="zip"
            placeholder={t(organizationTranslatePaths.add_organizations.zip)}
            required
            value={fields.zip}
            onChange={(event) => updateFieldsHandler(event, 'zip')}
            fullWidth
            disabled={isReadonly}
          />
          <div className={styles.organization_modal__select_label}>
            {t(organizationTranslatePaths.add_organizations.legal_details)}
          </div>
          <div className={styles.organization_modal__fields_container}>
            <TextField
              key="vat"
              id="vat"
              placeholder={t(organizationTranslatePaths.add_organizations.vat)}
              required
              value={fields.vat}
              onChange={(event) => updateFieldsHandler(event, 'vat')}
              disabled={isReadonly}
              className={styles.organization_modal__field_options}
            />
            <TextField
              key="registrationNumber"
              id="registration_number"
              placeholder={t(organizationTranslatePaths.add_organizations.registration_number)}
              required
              value={fields.registrationNumber}
              onChange={(event) => updateFieldsHandler(event, 'registrationNumber')}
              disabled={isReadonly}
              className={styles.organization_modal__field_options}
            />
          </div>
          <TextField
            key="iban"
            id="iban"
            placeholder={t(organizationTranslatePaths.add_organizations.iban)}
            required
            value={fields.iban}
            onChange={(event) => updateFieldsHandler(event, 'iban')}
            fullWidth
            disabled={isReadonly}
          />
          <TextField
            key="bankName"
            id="bank_name"
            placeholder={t(organizationTranslatePaths.add_organizations.bank_name)}
            required
            value={fields.bankName}
            onChange={(event) => updateFieldsHandler(event, 'bankName')}
            fullWidth
            disabled={isReadonly}
          />
          <div className={styles.organization_modal__select_label}>
            {t(organizationTranslatePaths.add_organizations.contact_details)}
          </div>
          <div className={styles.organization_modal__fields_container}>
            <TextField
              key="contractFirstName"
              id="contract_first_name"
              placeholder={t(organizationTranslatePaths.add_organizations.contract_first_name)}
              required
              value={fields.contractFirstName}
              onChange={(event) => updateFieldsHandler(event, 'contractFirstName')}
              fullWidth
              disabled={isReadonly}
              className={styles.organization_modal__field_options}
            />
            <TextField
              key="contractLastName"
              id="contract_last_name"
              placeholder={t(organizationTranslatePaths.add_organizations.contract_last_name)}
              required
              value={fields.contractLastName}
              onChange={(event) => updateFieldsHandler(event, 'contractLastName')}
              fullWidth
              disabled={isReadonly}
              className={styles.organization_modal__field_options}
            />
            <TextField
              key="contractEmail"
              id="contract_email"
              placeholder={t(organizationTranslatePaths.add_organizations.contract_email)}
              required
              value={fields.contractEmail}
              onChange={(event) => updateFieldsHandler(event, 'contractEmail')}
              fullWidth
              disabled={isReadonly}
              className={styles.organization_modal__field_options}
            />
            <TextField
              key="contractPhoneNumber"
              id="contract_phone_number"
              placeholder={t(organizationTranslatePaths.add_organizations.contract_phone_number)}
              required
              value={fields.contractPhoneNumber}
              onChange={(event) => updateFieldsHandler(event, 'contractPhoneNumber')}
              fullWidth
              disabled={isReadonly}
              className={styles.organization_modal__field_options}
            />
          </div>
          <TextField
            key="comment"
            id="comment"
            placeholder={t(organizationTranslatePaths.add_organizations.comment)}
            required
            value={fields.comment}
            onChange={(event) => updateFieldsHandler(event, 'comment')}
            fullWidth
            disabled={isReadonly}
          />
        </div>
      </DialogContent>
      <DialogActions>
        {isTouched && (
          <>
            <Button onClick={submit}>{t(organizationTranslatePaths.add_organizations.ok)}</Button>
            <Button color="warning" onClick={onClose}>
              {t(organizationTranslatePaths.add_organizations.cancel)}
            </Button>
          </>
        )}
      </DialogActions>
    </BootstrapDialog>
  );
};
