import { DocProperty } from "typescript-openapi-router";

export class DiseaseModel {
  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  id: string;
  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  name: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  createdAt: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  updatedAt?: string;

  @DocProperty({
    type: 'array',
    objectType: 'string',
    isRequired: true,
  })
  availableAlerts: string[];
}
