
import { DocProperty, InheritedDoc } from 'typescript-openapi-router';
import { OrganizationModel } from '../model';
import { ResponseEnvelope } from './response-envelope';

@InheritedDoc()
export class OrganizationListResponseBody extends ResponseEnvelope {

  @DocProperty({
    type: 'array',
    objectType: OrganizationModel.name,
    isRequired: false
  })
  data?: OrganizationModel[];
}
