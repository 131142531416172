import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Button, DialogActions, DialogContent, MenuItem, Select, TextField } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { useTranslation } from "react-i18next";

import { BootstrapDialog, BootstrapDialogTitle } from "../../../../components/Modal";
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks";
import { mainSelector } from "../../../../main/store/selectors";
import { capitalizeStr } from "../../../../helpers";
import type { IInitialSelects } from "../../constants/userModal";
import { initialFields, initialSelects, userFields } from "../../constants/userModal";
import { useSetTouched } from "../../hooks/useSetTouched";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { usersTranslatePaths } from "../../translations";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from "./styles.module.scss";
import { updateUserAsync } from "../../store/actions/updateUserAsync";
import { updateUserOrganizationAsync } from "../../store/actions/updateUserOrganizationAsync";
import { OrganizationRole, Role, UserModel } from "@fly-workspace/lib-api-interface";

type TProps = {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
  currentUser?: UserModel | null;
  isReadonly?: boolean;
};

export const EditUserModal: FC<TProps> = ({
  isOpen,
  onClose,
  onAccept,
  currentUser,
  isReadonly,
}: TProps) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const dispatch = useAppDispatch();
  const { organizations } = useAppSelector(mainSelector);

  const [fields, setFields] = useState(initialFields);
  const [selects, setSelects] = useState<IInitialSelects>(initialSelects);
  const [currentOrgId, setCurrentOrgId] = useState<number>();
  const [currentAppRole, setCurrentAppRole] = useState(initialSelects.roleSelect[0]);
  const [currentOrgRole, setCurrentOrgRole] = useState(initialSelects.organizationRole[0]);

  const { isTouched } = useSetTouched({ fields });

  useEffect(() => {
    if (currentUser) {
      setCurrentAppRole(currentUser?.role);
      setCurrentOrgRole(currentUser?.organizationRole);
      setCurrentOrgId(currentUser?.organizationId);
      setFields((prevState) => ({
        ...prevState,
        ...currentUser,
        firstName: currentUser?.firstName ?? '',
        lastName: currentUser?.lastName ?? '',
      }));
    }
  }, [currentUser]);

  useEffect(() => {
    setSelects((prev) => ({
      ...prev,
      organizations,
    }));
    if (organizations) setCurrentOrgId(organizations[0]?.id);
  }, [organizations]);

  const updateFieldsHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: string,
  ) => {
    setFields((prevState) => ({
      ...prevState,
      [field]: event.target.value,
    }));
  };

  const updateCurrentSelectHandler = <T,>(
    event: SelectChangeEvent<T>,
    setSelect: (value: T) => void,
  ) => {
    setSelect(event.target.value as T);
  };

  const submit = async () => {
    const userData: Partial<UserModel> = {};
    if (fields.firstName !== currentUser?.firstName) userData.firstName = fields.firstName;
    if (fields.lastName !== currentUser?.lastName) userData.lastName = fields.lastName;
    if (fields.comment !== currentUser?.comment) userData.comment = fields.comment;
    if (fields.phoneNumber !== currentUser?.phoneNumber) userData.phoneNumber = fields.phoneNumber;
    if (fields.role !== currentUser?.role)
      userData.role = fields.role as Role;
    if (fields.initialPassword !== '') userData.password = fields.initialPassword;
    if (fields.organizationRole !== currentUser?.organizationRole)
      userData.organizationRole = fields.organizationRole as OrganizationRole;

    if (currentUser) {
      await dispatch(
        updateUserAsync({
          userData,
          userId: currentUser.id!,
          organizationId: currentOrgId,
        }),
      );
      if (selects.organizations)
        if (selects.organizations[0].id !== organizations[0]?.id)
          await dispatch(
            updateUserOrganizationAsync({
              userId: currentUser.id!,
              org: {
                newOrganizationId: selects.organizations[0].id,
                oldOrganizationId: organizations[0]?.id,
              },
            }),
          );
    }

    onAccept();
  };

  return (
    <BootstrapDialog
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      aria-labelledby="edit_user_title"
      open={isOpen}
      fullScreen={width <= 768}
    >
      <BootstrapDialogTitle id="edit_user_title" onClose={onClose}>
        {t(usersTranslatePaths.edit_user.title)}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <div className={styles.user_modal__field_container}>
          {userFields.map((field) => (
            <TextField
              key={field.id}
              className={
                field.key === 'initialPassword' || field.key === 'adminPassword'
                  ? styles.user_modal__last_field
                  : undefined
              }
              id={field.id}
              placeholder={t(usersTranslatePaths.edit_user[field.id])}
              required={field.key !== 'initialPassword'}
              value={fields[field.key]}
              onChange={(event) => updateFieldsHandler(event, field.key)}
              fullWidth
              disabled={isReadonly}
            />
          ))}
        </div>
        <div className={styles.user_modal__select_wrapper}>
          <div className={styles.user_modal__select_label}>
            {t(usersTranslatePaths.edit_user.app_role)}
          </div>
          <Select
            fullWidth
            labelId="app-role-label"
            id="app-role-select"
            value={currentAppRole}
            disabled={isReadonly || !isTouched}
            onChange={(event: SelectChangeEvent<Role>) => {
              updateCurrentSelectHandler(event, setCurrentAppRole);
            }}
          >
            {selects.roleSelect.map((role) => (
              <MenuItem key={role} value={role}>
                {capitalizeStr(t(usersTranslatePaths.keys[role]))}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={styles.user_modal__select_wrapper}>
          <div className={styles.user_modal__select_label}>
            {t(usersTranslatePaths.edit_user.organization)}
          </div>
          <Select
            fullWidth
            labelId="organization-label"
            id="organization-select"
            value={currentOrgId}
            disabled={isReadonly || !isTouched}
            onChange={(event: SelectChangeEvent<number>) => {
              updateCurrentSelectHandler(event, setCurrentOrgId);
            }}
          >
            {selects?.organizations?.map((org) => (
              <MenuItem key={org?.id} value={org?.id}>
                {org?.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={styles.user_modal__select_wrapper}>
          <div className={styles.user_modal__select_label}>
            {t(usersTranslatePaths.edit_user.org_role)}
          </div>
          <Select
            fullWidth
            labelId="org-role-label"
            id="org-role-select"
            value={currentOrgRole}
            disabled={isReadonly || !isTouched}
            onChange={(event: SelectChangeEvent<OrganizationRole>) => {
              updateCurrentSelectHandler(event, setCurrentOrgRole);
            }}
          >
            {selects.organizationRole.map((role) => (
              <MenuItem key={role} value={role}>
                {capitalizeStr(t(usersTranslatePaths.keys[role]))}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div>
          <TextField
            id="comment"
            placeholder={t(usersTranslatePaths.edit_user.comment)}
            value={fields.comment}
            onChange={(event) => updateFieldsHandler(event, 'comment')}
            fullWidth
            multiline
            disabled={isReadonly}
            rows={4}
          />
        </div>
      </DialogContent>
      <DialogActions>
        {isTouched && (
          <>
            <Button onClick={submit}>{t(usersTranslatePaths.edit_user.ok)}</Button>
            <Button color="warning" onClick={onClose}>
              {t(usersTranslatePaths.edit_user.cancel)}
            </Button>
          </>
        )}
      </DialogActions>
    </BootstrapDialog>
  );
};
