export class ChartManager {
  public static getWeatherTicks = (
    type: "temperature" | "humidity",
    minTemp: number,
    maxTemp: number,
    minHum: number,
    maxHum: number
  ): number[] => {
    const ticks: number[] = [];
    const interval = ChartManager.getWeatherInterval(type);
    let min = minTemp === Infinity ? 0 : minTemp;
    let max = maxTemp === -Infinity ? 0 : maxTemp;
    if (type === "humidity") {
      min = minHum === Infinity ? 0 : minHum;
      max = maxHum === -Infinity ? 0 : maxHum;
    }

    min = ChartManager.makeDivisibleBy(min, interval + 1, "down");
    max = ChartManager.makeDivisibleBy(max, interval + 1, "up");
    for (let i = min; i <= max; i += 1) {
      ticks.push(i);
    }
    return ticks;
  };

  public static getInsectTicks = (max: number): number[] => {
    const ticks: number[] = [];
    const interval = ChartManager.getInsectInterval();
    const newMax = ChartManager.makeDivisibleBy(max, interval + 1, "up");
    for (let i = 0; i <= newMax; i += 1) {
      ticks.push(i);
    }
    return ticks;
  };

  public static getWeatherInterval = (type: "temperature" | "humidity") =>
    type === "temperature" ? 4 : 9;

  public static getInsectInterval = () => 4;

  public static getSizeBasedInterval = (maxValue: number) => {
    if (maxValue > 50) {
      return Math.pow(10, Math.floor(Math.log10(Math.abs(maxValue))));
    }
    if (maxValue > 10) {
      return 5;
    }
    if (maxValue <= 1) {
      return 0.1;
    }
    return 1;
  };

  public static getSizeBasedTicks = (
    interval: number,
    minValue: number,
    maxValue: number
  ): number[] => {
    const ticks: number[] = [];
    let min = minValue === Infinity ? 0 : minValue;
    let max = maxValue === -Infinity ? 0 : maxValue;

    min = ChartManager.makeDivisibleBy(min, interval, "down");
    max = ChartManager.makeDivisibleBy(max, interval, "up");
    for (let i = min; i <= max; i += interval) {
      if (interval < 1)
        ticks.push(parseFloat(i.toFixed(1)));
      else
        ticks.push(i);
    }
    return ticks;
  };

  public static getDomain = (type: "temperature" | "humidity") => {
    if (type === "humidity") {
      return [0, 100];
    }
    return ["dataMin", "dataMax"];
  };

  private static makeDivisibleBy = (value: number, by: number, direction: "up" | "down") => {
    if (direction === "up") {
      return Math.ceil(value / by) * by;
    }
    return Math.floor(value / by) * by;
  };
}
