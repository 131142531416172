import MuiAppBar from '@mui/material/AppBar';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar/AppBar';
import { styled } from '@mui/material/styles';

import { drawerWidth } from '../constants';

type AppBarProps = {
  isOpen?: boolean;
} & MuiAppBarProps;

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<AppBarProps>(({ theme, isOpen }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  padding: '10px 0 15px',
  backgroundColor: '#fff',
  ...(isOpen && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));
