import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';
import { getInsectRecipientFillRateAsync } from './getInsectRecipientFillRateAsync';
import { getOrganizationIdLocalStorage } from '../../../../helpers/localStorage';

import type { ThunkConfig } from '../../../../stores/types';
import type { updateLastCleanDateRequestDTO } from '../../dto/updateLastCleanDate.dto';

export const updateLastCleanDateAsync = createAsyncThunk<
  null,
  updateLastCleanDateRequestDTO,
  ThunkConfig
>(
  'pheromones/update-last-clean-date',
  async ({ deviceId, body }, { rejectWithValue, dispatch }) => {
    const organizationId = getOrganizationIdLocalStorage();

    try {
      await axios.put(
        `/private/organizations/${organizationId}/devices/${deviceId}/last-clean-date`,
        body,
      );

      dispatch(getInsectRecipientFillRateAsync({ deviceId }));
      return null;
    } catch (err) {
      return rejectWithValue(ErrorChecker(err));
    }
  },
);
