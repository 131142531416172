import { Card } from "../../../../components/widgets/Card";
import { useTranslation } from "react-i18next";
import { DeviceInfoModel, DiseaseModel } from "@fly-workspace/lib-api-interface";
import { Box, Checkbox, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";

export type DiseaseAlertsProps = {
  deviceInfo: DeviceInfoModel,
  diseases: DiseaseModel[]
}

export const DiseaseAlerts = (props: DiseaseAlertsProps) => {
  const { deviceInfo, diseases } = props;
  const { t } = useTranslation();
  const title = t("set_up_alerts.disease_alerts.title");

  const isChecked = (type: string) => {
    const diseaseAlerts = deviceInfo.activeAlerts?.diseaseAlerts
    if (!diseaseAlerts || diseaseAlerts.length === 0) {
      return true
    }
    return diseaseAlerts.find(d => d[type] === true) !== undefined;
  }

  const renderAlerts = (disease: DiseaseModel) => {
    const availableAlerts = disease.availableAlerts;
    return <Box display={"flex"} width={"100%"}>
      {availableAlerts.map((alert, index) => {
        return <Box key={index} display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <Checkbox checked={isChecked(alert)}/>
          <Typography>{t(`set_up_alerts.disease_alerts.${alert}`)}</Typography>
        </Box>;
      })}
    </Box>;
  };
  return <Card title={title}>
    {diseases.map((disease, index) => {
      return <Box display={'flex'} flexDirection={"column"}>
        <Box key={disease.id} mt={"10px"} pb={"10px"}>
          {disease.name}
          {renderAlerts(disease)}
        </Box>
        {index !== diseases.length - 1 && <Divider style={{
          width: "100%",
          height: "10px",
          display: "block"
        }} />}
      </Box>;
    })}
  </Card>;
};
