import React, { FC, PropsWithChildren, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Divider, Typography } from "@mui/material";

import { Card } from "../../../../components/widgets/Card";
import { useOutsideListener } from "../../../../hooks/useOutsideListener";
import { useAppDispatch } from "../../../../stores/hooks";
import { markerTranslatePaths } from "../../translations/dashboard";
import { TransparentButton } from "../../../../components/TransparentButton";
import { useMemoizedMarkerValues } from "../../hooks/useMemoizedMarkerValues";
import { setDeviceId } from "../../../Navbar/store";
import { BottomIndent } from "../../constants";

import type { IMyDeviceData } from "../../../ManageDevices/interfaces/myDeviceData";
import type { TCropIdUnion } from "../../../../interfaces/crop";

import MarkerGood from "../../../../../assets/marker-good.svg";
import MarkerBad from "../../../../../assets/marker-bad.svg";
import MarkerNoConnection from "../../../../../assets/marker-no-connection.svg";
import MarkerVirtual from "../../../../../assets/marker-virtual.svg";
import Eye from "../../../../../assets/eye.svg";

import styles from "./styles.module.scss";

type TInfoWindowProps = {
  title: string;
  alertCount?: number;
  batteryLevel?: string;
  lastLocationRecorded?: string;
  id?: string;
  crop?: string;
  serialNumber?: string;
};

type TKeysBottomIndent = keyof typeof BottomIndent;

export const InfoWindow: FC<TInfoWindowProps> = ({
                                                   id,
                                                   serialNumber,
                                                   title,
                                                   crop,
                                                   alertCount,
                                                   batteryLevel,
                                                   lastLocationRecorded
                                                 }: PropsWithChildren<TInfoWindowProps>) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [itemsCount, setItemsCount] = useState(0);

  useEffect(() => {
    const items = {
      id,
      serialNumber,
      crop,
      alertCount,
      batteryLevel
    };

    setItemsCount(Object.values(items).filter((item) => !!item).length);
  }, [id, serialNumber, crop, alertCount, batteryLevel]);

  const onClickHandler = () => {
    if (id) dispatch(setDeviceId(id));
    if (alertCount) {
      history.push("/alerts");
    } else {
      history.push("/device-data");
    }
  };

  return (
    <Card
      onClick={onClickHandler}
      className={styles.marker_info__card}
      style={{
        bottom: BottomIndent[itemsCount as TKeysBottomIndent]
      }}
    >
      <div className={styles.marker_info__wrapper}>
        <div className={styles.marker_info__header_wrapper}>
          <Typography align="left" variant="body1">
            {title}
          </Typography>
          <div>
            <img src={Eye} alt="eye" />
          </div>
        </div>
        {serialNumber && (
          <Typography align="left" variant="body1">
            {t(markerTranslatePaths.marker.serial_number, { serialNumber })}
          </Typography>
        )}
        {crop && (
          <Typography align="left" variant="body1">
            {t(markerTranslatePaths.marker.crop, {
              crop: t(markerTranslatePaths.crop_variants[crop as TCropIdUnion])
            })}
          </Typography>
        )}
        {Number(alertCount) !== 0 && <Typography align="left" variant="body1">
          {t(markerTranslatePaths.marker.alert_count, { alertCount })}
        </Typography>
        }
        {batteryLevel && (
          <Typography align="left" variant="body1">
            {t(markerTranslatePaths.marker.buttery_level, { batteryLevel })}
          </Typography>
        )}
        {lastLocationRecorded !== undefined && (
          <Typography align="left" variant="body1">
            {t(markerTranslatePaths.marker.last_location, { lastLocationRecorded })}
          </Typography>
        )}
      </div>
    </Card>
  );
};

type TMarkerIcon = {
  src: string;
};

const MarkerIcon: FC<TMarkerIcon> = ({ src }: TMarkerIcon) => (
  <img
    src={src}
    alt="marker"
    width="40px"
    height="40px"
    style={{
      transform: `translate(-50%, -100%)`
    }}
  />
);

type TMarkerLabel = {
  alertCount: number;
};

const MarkerLabel: FC<TMarkerLabel> = ({ alertCount }: TMarkerLabel) => (
  <div style={{
      transform: `translate(-50%, -175%)`,
      position: "absolute",
      fontSize: "16px",
      zIndex: 10,
      color: "#ffffff"
    }}
  >
    {alertCount}
  </div>
);

type TTeraseyaMarkerProps = {
  lat?: number;
  lng?: number;
  batteryLevel?: string;
  item: IMyDeviceData;
};

export const TeraseyaMarker: FC<TTeraseyaMarkerProps> = ({
                                                           batteryLevel,
                                                           item,
                                                           lat,
                                                           lng
                                                         }: TTeraseyaMarkerProps) => {
  const wrapper = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const { hasBlueIcon, hasGreyIcon, hasGreenIcon, hasRedIcon, alertCount, text, lastLocationRecorded } =
    useMemoizedMarkerValues(item);

  const toggleOpen = () => setIsOpen((prev) => !prev);

  useOutsideListener(wrapper, toggleOpen);

  return (
    <div style={{ position: "relative" }}>
      <TransparentButton onClick={toggleOpen}>
        {hasRedIcon && (
          <>
            {Number(alertCount) !== 0 && <MarkerLabel alertCount={alertCount} />}
            <MarkerIcon src={MarkerBad} />
          </>
        )}
        {hasGreenIcon && <MarkerIcon src={MarkerGood} />}
        {hasGreyIcon && <MarkerIcon src={MarkerNoConnection} />}
        {hasBlueIcon && <>
          {Number(alertCount) !== 0 && <MarkerLabel alertCount={alertCount} />}
          <MarkerIcon src={MarkerVirtual} />
        </>}
      </TransparentButton>
      {isOpen && text && (
        <div ref={wrapper}>
          <InfoWindow
            id={item?.id}
            serialNumber={item?.serialNumber}
            crop={item?.crop?.id}
            title={text}
            alertCount={alertCount}
            batteryLevel={batteryLevel}
            lastLocationRecorded={lastLocationRecorded}
          />
        </div>
      )}
    </div>
  );
};
