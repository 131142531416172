import { DocProperty } from 'typescript-openapi-router';

export class WeatherDetection {
  @DocProperty({
    type: 'array',
    objectType: 'number',
    isRequired: true
  })
  values: number[];

  @DocProperty({
    type: 'array',
    objectType: 'string',
    isRequired: true
  })
  recordedAt: string[];
}
