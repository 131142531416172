import { createSlice } from '@reduxjs/toolkit';

import { updateDeviceListAsync } from './actions';

import type { TRejectValue } from '../../../stores/types';

interface IInitialState {
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage?: TRejectValue;
}

const initialState: IInitialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: null,
};

export const deviceListUpdateSlice = createSlice({
  name: 'updateListDevices',
  initialState,
  reducers: {
    clearUpdateDevicesState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateDeviceListAsync.fulfilled, (state) => {
      state.isFetching = false;
      state.isSuccess = true;
    });
    builder.addCase(updateDeviceListAsync.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    });
    builder.addCase(updateDeviceListAsync.pending, (state) => {
      state.isFetching = true;
    });
  },
});

export const { clearUpdateDevicesState } = deviceListUpdateSlice.actions;
