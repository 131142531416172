import React, { FC, SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, FormControl, FormHelperText, Popper, Stack, TextField, TextFieldProps } from "@mui/material";
import moment from "moment";
import classNames from "classnames";

import { useAppDispatch, useAppSelector } from "../../../../stores/hooks";
import { myDeviseListSelector } from "../../../ManageDevices/store/selectors";
import { getDeviceInfoAsync } from "../../store/actions/getDeviceInfoAsync";
import { useDebounce } from "../../../../hooks/useDebounce";
import { clearNavbarState, setCurrentDevice, setDates, setDeviceId, setOrganization } from "../../store";
import { navbarTranslatePaths } from "../../translations/navbar";
import { CustomDatePicker } from "../../../../components/CustomDatePicker";

import type { TTimeStamp } from "../../../DeviceData/interfaces";

import styles from "./styles.module.scss";
import { useQuery } from "../../../../hooks/useQuery";
import { shiftDateByTZ } from "../../../../../../../../libs/common-utils/src";
import Box from "@mui/material/Box";
import { IOrganization } from "../../../../interfaces/organization";
import { navbarSelector } from "../../store/selectors";
import { mainSelector } from "../../../../main/store/selectors";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { getOrganizationIdLocalStorage } from "../../../../helpers/localStorage";
import { clearMyDevicesState } from "../../../ManageDevices/store/myDevicesListSlice";
import { IMyDeviceData } from "../../../ManageDevices/interfaces/myDeviceData";
import { selectDeviceInsect } from "../../../DeviceData/store";
import { sensorSourceSelector } from "../../../DeviceData/store/selectors";

type TDispatchGetDeviceInfo = {
  newDeviceId: string;
  periodValue?: TTimeStamp;
  startValue?: string | null;
  endValue?: string | null;
};

type TProps = {
  isDateRangeSelectorOn?: boolean;
  isDeviceDeviceSelectorOn?: boolean;
};

const getSelectDeviceLabel = (option: IMyDeviceData) => {
  return `${option.alias}/${option.serialNumber}${option.cropName ? ` (${option.cropName})` : ""}`;
};

export const SelectDevice: FC<TProps> = ({ isDateRangeSelectorOn, isDeviceDeviceSelectorOn }: TProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { organization } = useAppSelector(navbarSelector);
  const { organizations } = useAppSelector(mainSelector);
  const { width } = useWindowDimensions();

  const { myDeviceList } = useAppSelector(myDeviseListSelector);
  const sensorSource = useAppSelector(sensorSourceSelector);
  const [selectedDevice, setSelectedDevice] = useState<IMyDeviceData | null>(null);
  const query = useQuery();
  const {
    deviceId,
    dates: { startDate, endDate },
    language,
    organizationId
  } = useAppSelector((state) => state.navbar);

  const [endError, setEndError] = useState(false);

  const cn = {
    date_picker: (error?: boolean) =>
      classNames(styles.date_picker_input, { [styles.error]: error })
  };

  const dispatchGetDeviceInfo = ({
                                   newDeviceId,
                                   periodValue,
                                   startValue,
                                   endValue
                                 }: TDispatchGetDeviceInfo) => {
    dispatch(
      getDeviceInfoAsync({
        pathParams: {
          deviceId: newDeviceId
        },
        params: {
          relativeDate: periodValue,
          startDate: startValue,
          endDate: endValue,
          source: sensorSource
        }
      })
    );
  };

  const startDateDebounced = useDebounce(startDate, 500);
  const endDateDebounced = useDebounce(endDate, 500);

  useEffect(() => {
    if (getOrganizationIdLocalStorage()) {
      const currentOrganization = organizations.find((org) => `${org?.id}` === `${organizationId}`);
      if (currentOrganization) dispatch(setOrganization(currentOrganization));
    } else if (organizations[0]) dispatch(setOrganization(organizations[0]));
    setSelectedDevice(null);
  }, [dispatch, organizationId, organizations]);

  useEffect(() => {
    if (deviceId && isDateRangeSelectorOn) {
      dispatchGetDeviceInfo({
        newDeviceId: deviceId,
        startValue: startDateDebounced,
        endValue: endDateDebounced
      });
    }
  }, [deviceId, startDateDebounced, endDateDebounced, language, organizationId, sensorSource]);

  useEffect(() => {
    const device = myDeviceList.find((el) => el.id === deviceId);
    if (device) {
      setSelectedDevice(device);
    }
    dispatch(setCurrentDevice(device));
  }, [deviceId, myDeviceList]);

  useEffect(() => {
    const queryDeviceId = query.get("deviceId");
    if (queryDeviceId) {
      dispatch(setDeviceId(queryDeviceId));
      dispatch(selectDeviceInsect("all"));
    }
    const queryStartDate = query.get("startDate");
    const queryEndDate = query.get("endDate");
    if (queryStartDate && queryEndDate) {
      const endDate = moment(queryEndDate).utc().toISOString();
      changeStartDateHandler(queryStartDate);
      changeEndDateHandler(endDate, queryStartDate);
    }
  }, []);

  const changeSelectDeviceHandler = (event: SyntheticEvent, newValue: IMyDeviceData | null) => {
    if (newValue) {
      setSelectedDevice(newValue);
      dispatch(setDeviceId(newValue.id));
      dispatch(selectDeviceInsect("all"));
    }
  };

  const changeStartDateHandler = (startDate: string | null) => {
    const newStartDate = moment(startDate).utc(true).startOf("day");
    if (newStartDate.isValid())
      dispatch(
        setDates({
          startDate: newStartDate.toISOString()
        })
      );
  };

  const changeEndDateHandler = (endDate: string | null, startDate: string | null) => {
    const newEndDate = moment.utc(endDate).endOf("day");
    const oldStartDate = moment.utc(startDate).startOf("day");
    if (newEndDate.isValid()) {
      if (newEndDate.toDate() >= oldStartDate.toDate()) {
        dispatch(
          setDates({
            endDate: newEndDate.toISOString()
          })
        );
        setEndError(false);
      } else {
        setEndError(true);
      }
    }
  };

  const getInputSize = () => width < 900 ? "small" : "medium";

  const renderStartDateInput = (params: TextFieldProps) => (
    <TextField className={cn.date_picker(params.error)}
               {...params}
               size={getInputSize()} />
  );

  const renderEndDateInput = (params: TextFieldProps, error: boolean) => (
    <>
      <TextField
        className={cn.date_picker(error || params.error)}
        aria-describedby="end_date_helper_text"
        {...params}
        error={error || params.error}
        size={getInputSize()}
      />
      {error && (
        <FormHelperText className={styles.date_picker_input__helper_text} id="end_date_helper_text">
          {t(navbarTranslatePaths.device_select.helper_text)}
        </FormHelperText>
      )}
    </>
  );

  const onChangeAutocompleteHandler = async (event: SyntheticEvent, newValue: IOrganization | null) => {
    await dispatch(clearNavbarState());
    await dispatch(clearMyDevicesState());
    dispatch(setOrganization(newValue));
  };


  // need to shift the dates by timezone so that the picker will correctly disoplay the dates;
  const shiftedStartDate = shiftDateByTZ(moment(startDate).toDate()).toString();
  const shiftedEndDate = shiftDateByTZ(moment(endDate).toDate()).toString();
  return (
    <Box className={styles.navbar_actions} flexDirection={width > 900 ? "row" : "column"}>
      <FormControl className={styles.select_device__org_wrapper}>
        {organization && <Autocomplete
          id="organizations"
          value={organization}
          fullWidth={true}
          size={getInputSize()}
          onChange={onChangeAutocompleteHandler}
          options={organizations}
          PopperComponent={(props) => (
            <Popper {...props} style={{ width: width < 900 ? width : 400 }} placement={"auto-start"} />
          )}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          getOptionLabel={(option: IOrganization) => option?.name ?? ""}
          renderInput={(params) => <TextField {...params} label={t(navbarTranslatePaths.device_select.organization)} />}
        />}
        {!organization && <Autocomplete
          id="organizations"
          size={getInputSize()}
          options={[]}
          renderInput={(params) => <TextField {...params} label={t(navbarTranslatePaths.device_select.organization)} />}
        />}
      </FormControl>
      <Box className={styles.select_device__device_and_date}>
        {isDeviceDeviceSelectorOn && <FormControl className={styles.select_device__wrapper}>
          <Autocomplete
            id="select_device_label"
            value={selectedDevice}
            fullWidth={true}
            size={getInputSize()}
            onChange={changeSelectDeviceHandler}
            options={myDeviceList}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option: IMyDeviceData) => getSelectDeviceLabel(option)}
            PopperComponent={(props) => (
              <Popper {...props} style={{ width: width < 900 ? width : 400 }} placement={"auto-start"} />
            )}
            renderOption={(props, option: IMyDeviceData) => (
              <li {...props} style={{ color: option.alertCount > 0 ? "red" : "black" }}>
                {getSelectDeviceLabel(option)}
              </li>
            )}
            renderInput={(params) => <TextField {...params}
                                                label={t(navbarTranslatePaths.device_select.select)}
                                                size={getInputSize()} />}
          />
        </FormControl>}
        {isDateRangeSelectorOn && (
          <Stack direction={"row"} spacing={1} width={"20rem"}>
            <CustomDatePicker
              label={t(navbarTranslatePaths.device_select.from)}
              value={shiftedStartDate}
              onChange={(date) => date && changeStartDateHandler((date as Date).toISOString())}
              renderInput={renderStartDateInput}
            />
            <CustomDatePicker
              label={t(navbarTranslatePaths.device_select.to)}
              value={shiftedEndDate}
              onChange={(date) => (date && startDate) && changeEndDateHandler((date as Date).toISOString(), startDate)}
              renderInput={(params: TextFieldProps) => renderEndDateInput(params, endError)}
            />
          </Stack>
        )}
      </Box>
    </Box>
  )
    ;
};
