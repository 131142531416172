import React, { FC, useEffect } from 'react';

import { Map } from './components/Map';
import { myDeviseListSelector } from '../ManageDevices/store/selectors';
import { getMyDevicesAsync } from '../ManageDevices/store/actions';
import { useAppDispatch, useAppSelector } from '../../stores/hooks';
import { Legendary } from './components/Legend';

import { CustomCircularProgress } from '../../components/CustomCircularProgress';

export const Dashboard: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { isFetching } = useAppSelector(myDeviseListSelector);
  const { language, organizationId } = useAppSelector((state) => state.navbar);

  useEffect(() => {
    dispatch(getMyDevicesAsync());
  }, [language, organizationId]);

  return (
    <div>
      {isFetching && <CustomCircularProgress />}

      <Map />
      <Legendary />
    </div>
  );
};
