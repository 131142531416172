import React, { FC, useState } from 'react';
import { DataGrid, GridRowModel } from '@mui/x-data-grid';
import { isEqual } from 'lodash';

import { AddButtons } from '../../../../components/AddButtons';
import { useAppSelector } from '../../../../stores/hooks';
import { useColumns } from '../../hooks/useColumns';
import { mainSelector } from '../../../../main/store/selectors';
import { ChangeCropModal } from '../ChangeCropModal';

import type { IDeviceData } from '../../interfaces/deviceData';

type TRowArguments = {
  resolve: (value: IDeviceData) => void;
  newRow: IDeviceData;
  oldRow: IDeviceData;
} | null;

type TProps = {
  handleClear: () => void;
  handleCreate: () => void;
  rows: IDeviceData[];
  updateRows: (value: IDeviceData[]) => void;
  className?: string;
  isMyDevices?: boolean;
};

export const CustomDataGrid: FC<TProps> = ({
  handleClear,
  handleCreate,
  rows,
  updateRows,
  className
}: TProps) => {
  const { crops } = useAppSelector(mainSelector);

  const [isUpdated, setIsUpdated] = useState(false);

  const columns = useColumns(crops);

  const [rowArguments, setRowArguments] = useState<TRowArguments>(null);

  const cellEditHandler = (el: IDeviceData) => {
    const mapDevices = (device: IDeviceData): IDeviceData => {
      if (device.id === el.id) {
        return {
          ...el,
          dirty: true
        };
      }
      return device;
    };

    const newData = rows.map(mapDevices);
    updateRows(newData);
  };

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) =>
    new Promise<GridRowModel>((resolve: (value: IDeviceData) => void) => {
      const newTypeRow = newRow as IDeviceData;
      const oldTypeRow = oldRow as IDeviceData;

      const isEqualObjects = isEqual(newRow, oldRow);
      if (!isEqualObjects) {
        if (newTypeRow.cropName !== oldTypeRow.cropName) {
          // TODO: this fix is stupid, but it works for now, need to fix it
          newTypeRow.cropId = newTypeRow.cropName;
          newTypeRow.cropName = crops.find(el => el.id === newTypeRow.cropId)?.name ?? null;
          // Save the arguments to resolve or reject the promise later
          setRowArguments({ resolve, newRow: newTypeRow, oldRow: oldTypeRow });
        } else {
          setIsUpdated(true);
          cellEditHandler(newTypeRow);
          resolve(newTypeRow);
        }
      } else {
        resolve(oldTypeRow); // Nothing was changed
      }
    });

  const handleNo = () => {
    if (rowArguments) {
      const { oldRow, resolve } = rowArguments;
      resolve(oldRow);
      setRowArguments(null);
    }
  };

  const handleYes = () => {
    if (rowArguments) {
      const { newRow, resolve } = rowArguments;

      cellEditHandler(newRow);
      setRowArguments(null);
      setIsUpdated(true);
      resolve(newRow);
    }
  };

  const onClickAddButtonsHandler = (func: () => void) => {
    func();
    setIsUpdated(false);
  };

  return (
    <>
      <DataGrid
        rows={rows}
        className={className}
        columns={columns}
        hideFooterSelectedRowCount
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
      />
      <AddButtons
        toSave={isUpdated}
        clear={() => {
          onClickAddButtonsHandler(handleClear);
        }}
        handleCreate={() => {
          onClickAddButtonsHandler(handleCreate);
        }}
      />

      <ChangeCropModal isOpen={!!rowArguments} onClose={handleNo} onAccept={handleYes} />
    </>
  );
};
