import React, { FC, useEffect, useState } from "react";

import { Card } from "../../../../components/widgets/Card";
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks";
import { getAllDevicesAsync, updateDeviceListAsync } from "../../store/actions";
import { deviseListSelector, updateDeviseListSelector } from "../../store/selectors";
import { clearAllDevicesState } from "../../store/deviceListSlice";
import { clearUpdateDevicesState } from "../../store/updateDeviceListSlice";
import { navbarSelector } from "../../../Navbar/store/selectors";
import { CustomCircularProgress } from "../../../../components/CustomCircularProgress";
import { CustomDataGrid } from "../CustomDataGrid";

import type { IDeviceData } from "../../interfaces/deviceData";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

export const AllDevices: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { isFetching, isSuccess, isError, data } = useAppSelector(deviseListSelector);
  const { language, organizationId } = useAppSelector(navbarSelector);
  const updateDevices = useAppSelector(updateDeviseListSelector);
  const { t } = useTranslation();

  const [rows, setRows] = useState<IDeviceData[]>([]);

  useEffect(() => {
    setRows(data.map((item) => ({
        ...item,
        cropName: item.cropId ? t(`crops.${item.cropId}`) : ""
      }))
    );
  }, [data]);

  useEffect(() => {
    dispatch(getAllDevicesAsync());
  }, [language, organizationId]);

  useEffect(
    () => () => {
      dispatch(clearAllDevicesState());
    },
    []
  );

  useEffect(() => {
    if (isError) {
      dispatch(clearAllDevicesState());
      dispatch(clearUpdateDevicesState());
    }

    if (isSuccess) {
      dispatch(clearAllDevicesState());
      dispatch(clearUpdateDevicesState());
    }
  }, [isError, isSuccess, updateDevices.isError, updateDevices.isSuccess]);

  const handleClear = () => {
    dispatch(getAllDevicesAsync());
  };

  const handleCreate = async () => {
    const items = rows.filter(el => el.dirty).map((el) => ({
      id: el.id,
      alias: el.alias,
      cropId: el.cropId,
      cropName: el.cropName,
      isManagedByMe: el.isManagedByMe
    }));
    await dispatch(
      updateDeviceListAsync({
        body: {
          items: items
        }
      })
    );

    await dispatch(getAllDevicesAsync());
  };

  return (
    <>
      {(isFetching || updateDevices.isFetching) && <CustomCircularProgress />}

      <Card className={styles.all_devices__wrapper}>
        <CustomDataGrid
          rows={rows}
          updateRows={setRows}
          handleClear={handleClear}
          handleCreate={handleCreate}
          className={styles.all_devices__table}
        />
      </Card>
    </>
  );
};
