import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { ButtonProps } from '@mui/material';

import styles from './styles.module.scss';

type TProps = {
  onClick: () => void;
  className?: string;
} & ButtonProps;

export const TransparentButton: FC<PropsWithChildren<TProps>> = ({
  children,
  onClick,
  className,
  ...props
}: PropsWithChildren<TProps>) => (
  <button
    className={classNames(styles.transparent_btn, className)}
    type="button"
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
);
