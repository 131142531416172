import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Button, DialogActions, DialogContent, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

import { IInsect } from "src/app/interfaces/insect";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../components/Modal";
import { useAppDispatch } from "../../../../stores/hooks";
import { initialFields, insectFields } from "../../constants/insectModal";
import { useSetTouched } from "../../hooks/useSetTouched";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { insectTranslatePaths } from "../../translations";
import { createInsectAsync } from "../../store/actions/createInsectAsync";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from "./styles.module.scss";
import { ICreateInsectpRequestDTO } from "../../dto/createInsectRequest.dto";

type TProps = {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
  currentinsect?: IInsect | null;
  isReadonly?: boolean;
};

export const InsectModal: FC<TProps> = ({
  isOpen,
  onClose,
  onAccept,
  currentinsect: currentInsect,
  isReadonly,
}: TProps) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const dispatch = useAppDispatch();

  const [fields, setFields] = useState(initialFields);

  const { isTouched } = useSetTouched({ fields });

  useEffect(() => {
    if (currentInsect) {
      setFields((prevState) => ({
        ...prevState,
        alias: currentInsect?.name ?? '',
      }));
    }
  }, [currentInsect]);

  const updateFieldsHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: string,
  ) => {
    setFields((prevState) => ({
      ...prevState,
      [field]: event.target.value,
    }));
  };

  const submit = async () => {
    await dispatch(createInsectAsync(fields as ICreateInsectpRequestDTO));
    onAccept();
  };

  return (
    <BootstrapDialog
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      aria-labelledby="create_insect_title"
      open={isOpen}
      fullScreen={width <= 768}
    >
      <BootstrapDialogTitle id="create_insect_title" onClose={onClose}>
        {t(insectTranslatePaths.add_insect.title)}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <div className={styles.insect_modal__field_container}>
          {insectFields.map((field) => (
            <TextField
              key={field.id}
              id={field.id}
              placeholder={t(insectTranslatePaths.add_insect[field.id])}
              required
              value={fields[field.key]}
              onChange={(event) => updateFieldsHandler(event, field.key)}
              fullWidth
              disabled={isReadonly}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        {isTouched && (
          <>
            <Button onClick={submit}>{t(insectTranslatePaths.add_insect.ok)}</Button>
            <Button color="warning" onClick={onClose}>
              {t(insectTranslatePaths.add_insect.cancel)}
            </Button>
          </>
        )}
      </DialogActions>
    </BootstrapDialog>
  );
};
