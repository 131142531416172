import { DocProperty } from "typescript-openapi-router";

export class BaseCronRequest {
  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  superAdminPassword?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  deviceId?: string;

  @DocProperty({
    type: 'number',
    isRequired: false,
  })
  executionYear?: number;

}
