import React, { FC } from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { ChartManager } from "../../helpers";
import { InsectChart as InsectChartModel } from "@fly-workspace/lib-api-interface";

const colors = ["#0088FE", "#00C49F", "#FF0000", "#8c00ff", "#009700", "#ff6abf"];

export type TProps = {
  insectChart: InsectChartModel;
};

export const InsectChart: FC<TProps> = ({ insectChart }: TProps) => {
  let maxInsectCount = 0;

  for (const data of insectChart.data) {
    if (data.maxValue && data.maxValue > maxInsectCount) {
      maxInsectCount = data.maxValue;
    }
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        height={300}
        data={insectChart.data}
        margin={{
          top: 5,
          right: 5,
          left: -25,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={insectChart.xKey} />
        <YAxis
          interval={ChartManager.getInsectInterval()}
          ticks={ChartManager.getInsectTicks(maxInsectCount)}
        />
        <Tooltip />
        <Legend />
        {insectChart.dataKeys.map((el, index) => (
          <Line type="monotone" key={`chart_data_keys_${el}`} stroke={colors[index]} dataKey={el} />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};
