export const deviceAlertsTranslatePaths = {
  title: 'device_data.alerts.title',
  subtitle: 'device_data.alerts.subtitle',
  alertAtFirstDetection: 'device_data.alerts.alert_detection',
  alertAtThreshold: 'device_data.alerts.alert_at_threshold',
  activityAlert: 'device_data.alerts.alert_activity_conditions',
  laidEggsAlert: 'device_data.alerts.alert_egg_laying',
  sendSprayAlerts: 'device_data.alerts.alert_spray',
  pheromoneAlert: 'device_data.alerts.alert_pheromones',
  cards: {
    temperature: 'device_data.alerts.cards.temperature',
    humidity: 'device_data.alerts.cards.humidity',
    below: 'device_data.alerts.cards.below',
    above: 'device_data.alerts.cards.above',
    alert_text: 'device_data.alerts.cards.alert_text',
  },
  buttons: {
    see_alerts: 'device_data.alerts.buttons.see_alerts',
    change_setup: 'device_data.alerts.buttons.change_setup',
  },
};
