import React, { useState, MouseEvent, ChangeEvent, useEffect } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import {
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TablePagination,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

import { capitalizeStr } from 'src/app/helpers';
import { IInsect } from 'src/app/interfaces/insect';
import { Card } from '../../components/widgets/Card';
import { TransparentButton } from '../../components/TransparentButton';
import { Button } from '../../components/widgets/Button';
import { InsectModal } from './components/InsectModal/index';
import { EditInsectModal } from './components/EditInsectModal/index';
import { useAppDispatch, useAppSelector } from '../../stores/hooks';
import { insectListSelector } from './store/selectors';
import { getInsectListAsync } from './store/actions/getInsectListAsync';
import { useSeparateInsectPerPage } from './hooks/useSeparateInsectPerPage';
import { CustomCircularProgress } from '../../components/CustomCircularProgress';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Eye from '../../../assets/eye.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Notepad from '../../../assets/notepad.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from './styles.module.scss';

import { insectTranslatePaths } from './translations';

import { getAllInsectsAsync } from '../DeviceData/store/actions/getAllInsectsAsync';
import { SortDirection } from '../Crops/constants/sortDirection';

export const InsectsPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { insectList, isLoading } = useAppSelector(insectListSelector);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpenInsectModal, setIsOpenInsectModal] = useState(false);
  const [isOpenEditInsectModal, setIsOpenEditInsectModal] = useState(false);
  const [currentInsect, setCurrentInsect] = useState<IInsect | null>(null);
  const [isReadonly, setIsReadonly] = useState(false);
  const [sortBy, setSortBy] = useState('sort_by');
  const [direction, setDirection] = useState('direction');
  const [searchQuery, setSearchQuery] = useState('');
  const { insectForAllPages } = useSeparateInsectPerPage({
    insectList,
    rowsPerPage,
  });

  const handleSelectedOption = (event: SelectChangeEvent) => {
    setSortBy(event.target.value as string);

    dispatch(
      getInsectListAsync({
        sort: event.target.value as string,
        sortDirection: direction !== 'direction' ? direction : SortDirection.asc,
      }),
    );
  };

  const handleDirectionOption = (event: SelectChangeEvent) => {
    setDirection(event.target.value as string);

    dispatch(
      getInsectListAsync({
        sort: sortBy !== 'sort_by' ? sortBy : 'name',
        sortDirection: event.target.value,
      }),
    );
  };

  const handleSearchOption = (event: string) => {
    dispatch(
      getInsectListAsync({
        sort: sortBy !== 'sort_by' ? sortBy : 'name',
        sortDirection: direction !== 'direction' ? direction : SortDirection.asc,
        search: event,
      }),
    );
  };

  useEffect(() => {
    dispatch(getInsectListAsync());
  }, [dispatch]);

  const changePageHandler = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const changeRowsPerPageHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const editInsectHandler = (insect: IInsect) => {
    setCurrentInsect(insect);
    setIsOpenEditInsectModal(true);
  };

  const watchInsectHandler = (insect: IInsect) => {
    editInsectHandler(insect);
    setIsReadonly(true);
  };

  const onCloseInsectModalHandler = () => {
    setIsOpenInsectModal(false);
    setCurrentInsect(null);
    setIsReadonly(false);
  };

  const onCloseEditInsectModalHandler = () => {
    setIsOpenEditInsectModal(false);
    setCurrentInsect(null);
    setIsReadonly(false);
  };

  const onAcceptInsectModalHandler = () => {
    onCloseInsectModalHandler();
  };

  const onAcceptEditInsectModalHandler = () => {
    onCloseEditInsectModalHandler();
  };

  return (
    <div className={styles.insect}>
      {isLoading && <CustomCircularProgress />}

      <div className={styles.insect__header}>
        <div className={styles.insect__search_container}>
          <Paper component="form" className={styles.insect__search}>
            <IconButton aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  handleSearchOption(event?.target?.value);
                }
              }}
              className={styles.insect__search_input}
              placeholder={t(insectTranslatePaths.header.search)}
            />
          </Paper>

          <div className={styles.insect__select_container}>
            <Select
              className={styles.insect__select}
              labelId="sort-label"
              id="sort-select"
              label="sort_by"
              value={sortBy}
              onChange={handleSelectedOption}
            >
              <MenuItem value="createdAt">{t(insectTranslatePaths.sort.created_at)}</MenuItem>
              <MenuItem value="updatedAt">{t(insectTranslatePaths.sort.updated_at)}</MenuItem>
              <MenuItem value="name">{t(insectTranslatePaths.sort.name)}</MenuItem>
              <MenuItem className={styles.insect__select_label} value="sort_by">
                {t(insectTranslatePaths.header.sort_by)}
              </MenuItem>
            </Select>

            <Select
              className={styles.insect__select}
              labelId="direction-label"
              id="direction-select"
              value={direction}
              onChange={handleDirectionOption}
            >
              <MenuItem value={SortDirection.asc}>{capitalizeStr(SortDirection.asc)}</MenuItem>
              <MenuItem value={SortDirection.desc}>{capitalizeStr(SortDirection.desc)}</MenuItem>
              <MenuItem className={styles.insect__select_label} value="direction">
                {t(insectTranslatePaths.header.direction)}
              </MenuItem>
            </Select>
          </div>
        </div>

        <div className={styles.insect__create_user_wrapper}>
          <Button className={styles.insect__create_user} onClick={() => setIsOpenInsectModal(true)}>
            {t(insectTranslatePaths.header.create_insect)}
          </Button>
        </div>
      </div>

      <Card className="insects__wrapper" title={t(insectTranslatePaths.title)}>
        <div className={styles.insect__container}>
          <div className={styles.insect__card_list}>
            {!!insectForAllPages.length &&
              insectForAllPages[page].map((insect, index) => (
                <Card
                  className={classNames(styles.insect__card, 'insects__card')}
                  key={`${insect.createdAt}_${index}`}
                >
                  <div className={styles.insect__full_name}>
                    <div>{`${insect?.name}`}</div>
                    <div className={styles.insect__actions}>
                      <TransparentButton onClick={() => watchInsectHandler(insect)}>
                        <img src={Eye} alt="eye" />
                      </TransparentButton>
                      <TransparentButton onClick={() => editInsectHandler(insect)}>
                        <img src={Notepad} alt="notepad" />
                      </TransparentButton>
                    </div>
                  </div>
                  {insect?.createdAt && (
                    <div>
                      {t(insectTranslatePaths.insects_list.created, {
                        value: moment(insect.createdAt).format('DD.MM.YYYY - HH:mm:SS'),
                      })}
                    </div>
                  )}
                  {insect?.updatedAt && (
                    <div>
                      {t(insectTranslatePaths.insects_list.updated, {
                        value: moment(insect.updatedAt).format('DD.MM.YYYY - HH:mm:SS'),
                      })}
                    </div>
                  )}
                </Card>
              ))}
          </div>

          <div>
            <TablePagination
              component="div"
              count={insectList.length}
              page={page}
              onPageChange={changePageHandler}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={changeRowsPerPageHandler}
            />
          </div>
        </div>
      </Card>

      <InsectModal
        isOpen={isOpenInsectModal}
        onAccept={onAcceptInsectModalHandler}
        onClose={onCloseInsectModalHandler}
        currentinsect={currentInsect}
      />
      <EditInsectModal
        isOpen={isOpenEditInsectModal}
        onAccept={onAcceptEditInsectModalHandler}
        onClose={onCloseEditInsectModalHandler}
        currentInsect={currentInsect}
      />
    </div>
  );
};
