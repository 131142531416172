import { DocProperty, InheritedDoc } from 'typescript-openapi-router';
import { InsectModel } from './insect-model';
import { InsectIdSource } from "./insect-id-source";
import { enumToArray } from "@xtool/enum-to-array";

@InheritedDoc()
export class InsectDetectionModel extends InsectModel {

  @DocProperty({
    type: 'string',
    isRequired: true
  })
  displayRecordedAt: string;

  @DocProperty({
    type: 'string',
    isRequired: true
  })
  recordedAt: string;

  @DocProperty({
    type: 'string',
    isRequired: true
  })
  photoDisplayRecordedAt: string;

  @DocProperty({
    type: 'string',
    isRequired: false
  })
  photo?: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
    enum: enumToArray(InsectIdSource)
  })
  idSource: InsectIdSource;
}
