import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, TextField } from '@mui/material';
import classNames from 'classnames';

import { Card } from '../../../../components/widgets/Card';
import { setUpAlertsWeatherAlertsTranslatePaths } from '../../translations';

import type { IWeatherAlerts } from '../../interfaces';

import styles from './styles.module.scss';
import './styles.overload.scss';

type TProps = {
  weatherAlerts: IWeatherAlerts;
  setWeatherAlerts: Dispatch<SetStateAction<IWeatherAlerts>>;
};

export const WeatherAlerts: FC<TProps> = ({ weatherAlerts, setWeatherAlerts }: TProps) => {
  const { t } = useTranslation();

  const cn = {
    temperature: classNames('temperature-over', styles.temperature),
    add_item: classNames('add-item', 'underline', styles.add_item),
  };

  const textFieldChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    key: string,
  ) => {
    const value = Number(event.target.value);
    if (!isNaN(value) || event.target.value === '-') {
      setWeatherAlerts((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key as keyof IWeatherAlerts],
          value: event.target.value,
        },
      }));
    }
  };

  const onClickCheckboxHandler = (key: string) => {
    setWeatherAlerts((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key as keyof IWeatherAlerts],
        isEnable: !prevState[key as keyof IWeatherAlerts].isEnable,
        value: '',
      },
    }));
  };

  return (
    <Card title={t(setUpAlertsWeatherAlertsTranslatePaths.title)}>
      <span className="explain">{t(setUpAlertsWeatherAlertsTranslatePaths.text)}</span>

      <div className={cn.temperature}>
        {Object.entries(weatherAlerts).map(([key, value]) => (
          <div key={`weather_alerts_${key}`} className={cn.add_item}>
            <Checkbox checked={value.isEnable} onClick={() => onClickCheckboxHandler(key)} />
            <span className="header-text">
              {t(setUpAlertsWeatherAlertsTranslatePaths[key as keyof IWeatherAlerts])}
            </span>
            <TextField
              id={key}
              disabled={!value.isEnable}
              className="numbers"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
              value={value.value}
              onChange={(event) => textFieldChangeHandler(event, key)}
            />
            <span className={styles.temperature__ending}>
              {key.split(/(?=[A-Z])/)[1] === 'Temperature' ? '°C' : '%'}
            </span>
          </div>
        ))}
      </div>
    </Card>
  );
};
