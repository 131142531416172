import { ResponseEnvelope } from ".";
import { DiseaseModel } from "../model";
import { DocProperty, InheritedDoc } from "typescript-openapi-router";

@InheritedDoc()
export class GetDiseaseListResponse extends ResponseEnvelope {

  @DocProperty({
    type: "array",
    objectType: DiseaseModel.name,
    isRequired: false
  })
  data?: DiseaseModel[];
}
