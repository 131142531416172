import { DocProperty } from 'typescript-openapi-router';
import { OrganizationRequestModel } from '../model';

export class UpdateOrganizationRequestBody {
  @DocProperty({
    type: 'object',
    objectType: OrganizationRequestModel.name,
    isRequired: true,
  })
  data: OrganizationRequestModel;
}
