import React, { useMemo } from "react";
import { Box, ListItem, Paper, Typography } from "@mui/material";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import EdgesensorHighIcon from "@mui/icons-material/EdgesensorHigh";
import BugReportIcon from "@mui/icons-material/BugReport";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import { Card } from "../../../../components/widgets/Card";
import { TextLabel } from "../../../../components/widgets/TextLabel";
import { StyledInfoCard } from "../../../../components/widgets/StyledInfoCard";
import { Button } from "../../../../components/widgets/Button";
import { useAppSelector } from "../../../../stores/hooks";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { deviceAlertsTranslatePaths } from "../../translations";
import { navbarSelector } from "../../../Navbar/store/selectors";

import styles from "./styles.module.scss";
import { InsectAlertModel, WeatherAlertModel } from "@fly-workspace/lib-api-interface";

type TDeviceTranslatePaths = keyof Omit<typeof deviceAlertsTranslatePaths, "buttons" | "cards">;
type TDeviceTranslatePathsCount = keyof Pick<InsectAlertModel, "alertAtThreshold">;

export const DeviceAlerts = () => {
  const history = useHistory();
  const { width } = useWindowDimensions();

  const { t } = useTranslation();
  const { deviceInfo } = useAppSelector(navbarSelector);

  const cn = {
    alerts_info_wrapper: classNames("divider", styles.alerts_info__wrapper)
  };

  const alertMinWidth = useMemo(() => (width < 600 ? "100%" : "35rem"), [width]);

  const navigate = () => {
    history.push("/set-up-alerts");
  };

  const navigateAlert = () => {
    history.push("/alerts");
  };

  const renderStyledCard = (weatherAlert: WeatherAlertModel, index: number) => {
    const { sensor, min, max } = weatherAlert;
    const newSensor =
      sensor === "temperature"
        ? t(deviceAlertsTranslatePaths.cards.temperature)
        : t(deviceAlertsTranslatePaths.cards.humidity);

    const getSensorAlertMessage = (sensorValue: string, param: number, isMin: boolean) =>
      t(deviceAlertsTranslatePaths.cards.alert_text, {
        text: isMin
          ? t(deviceAlertsTranslatePaths.cards.below)
          : t(deviceAlertsTranslatePaths.cards.above),
        param,
        unit: sensorValue === "temperature" ? "°C" : "%"
      });

    if (max || min) {
      return (
        <StyledInfoCard
          className={styles.weather_info_card}
          Icon={EdgesensorHighIcon}
          title={newSensor}
          key={`${sensor}-${index}`}
        >
          <Box>
            {min !== undefined && <TextLabel>{getSensorAlertMessage(sensor, min, true)}</TextLabel>}
            {max !== undefined && <TextLabel>{getSensorAlertMessage(sensor, max, false)}</TextLabel>}
          </Box>
        </StyledInfoCard>
      );
    }

    return null;
  };

  const activeAlertCount = useMemo(
    () => deviceInfo?.sentAlerts?.filter((el) => el.status === "active").length,
    [deviceInfo]
  );

  const latestAlerts = useMemo(() => deviceInfo?.sentAlerts?.slice(0, 4), [deviceInfo]);

  if (deviceInfo) {
    const { activeAlerts } = deviceInfo;

    return (
      <Card title={t(deviceAlertsTranslatePaths.title)}>
        <div className={cn.alerts_info_wrapper}>
          <Typography
            className={styles.alerts_info__subtitle}
            component="span"
            variant="body2"
            color="text.primary"
          >
            <FmdBadIcon />
            {t(deviceAlertsTranslatePaths.subtitle, { count: activeAlertCount })}
          </Typography>
          <Box className={styles.alerts_info__alert}>
            {latestAlerts?.map((el, index) => {
              const rows = el.content.split("\n");
              return (
                <Box
                  key={`latest_alerts_${el.content + el.id + index}`}
                  className={styles.alerts_info__alert_box}
                  minWidth={alertMinWidth}
                >
                  <Paper
                    style={{
                      backgroundColor: el.status === "active" ? "#ffdebe" : "#f5fcf3"
                    }}
                  >
                    <ListItem alignItems="flex-start">
                      <Box paddingRight="3rem">
                        {rows.map((row, rowIndex) => (
                          <Box key={`alert_device_${row + rowIndex}`}>
                            <Typography component="span" variant="body2" color="text.primary">
                              {row}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </ListItem>
                  </Paper>
                </Box>
              );
            })}
          </Box>

          {!!activeAlertCount && activeAlertCount > 0 && (
            <Box marginBottom="0.5rem">
              <Button onClick={() => navigateAlert()} maxWidth="10rem">
                {t(deviceAlertsTranslatePaths.buttons.see_alerts)}
              </Button>
            </Box>
          )}
          <Box className={styles.insect_alerts}>
            {activeAlerts &&
              activeAlerts.insectAlerts?.map((insectAlert: InsectAlertModel, index) => (
                  <StyledInfoCard
                    key={`${insectAlert.insectId + index}`}
                    Icon={BugReportIcon}
                    title={insectAlert.insectId}
                    className={styles.insect_alerts__card}
                  >
                    <Box>
                      {Object.keys(insectAlert).filter((key) => insectAlert[key as TDeviceTranslatePathsCount]).map((key, ind) => {
                          return <TextLabel key={`alerts_${key + ind}`}>
                            {t(deviceAlertsTranslatePaths[key as TDeviceTranslatePaths], {
                              count: insectAlert[key as TDeviceTranslatePathsCount]
                            })}
                          </TextLabel>;
                        }
                      )}
                    </Box>
                  </StyledInfoCard>
                ))}
            {activeAlerts && activeAlerts.weatherAlerts?.map(renderStyledCard)}
          </Box>
          <Box marginBottom="0.5rem" marginTop="0.5rem">
            <Button onClick={() => navigate()} maxWidth="10rem">
              {t(deviceAlertsTranslatePaths.buttons.change_setup)}
            </Button>
          </Box>
        </div>
      </Card>
    );
  }

  return <></>;
};
