import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { getOrganizationIdLocalStorage } from '../../../../helpers/localStorage';
import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { changeAlertRequest } from '../../dto/changeAlert.request.dto';
import type { ThunkConfig } from '../../../../stores/types';

export const deleteAlertAsync = createAsyncThunk<null, changeAlertRequest, ThunkConfig>(
  'alert/list/delete',
  async ({ body }, { rejectWithValue }) => {
    try {
      const organizationId = getOrganizationIdLocalStorage();

      await axios.post(
        `/private/organizations/${organizationId}/devices/notifications/delete`,
        body,
      );

      return null;
    } catch (err) {
      return rejectWithValue(ErrorChecker(err));
    }
  },
);
