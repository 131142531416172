import { DocProperty } from 'typescript-openapi-router';

export class AddInsectRequestBody {
  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  name: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  image?: string;
}
