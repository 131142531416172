import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../../stores/types';
import { ICreateInsectpRequestDTO } from '../../dto/createInsectRequest.dto';

export const createInsectAsync = createAsyncThunk<null, ICreateInsectpRequestDTO, ThunkConfig>(
  'insect/createInsect',
  async (insectData, { rejectWithValue }) => {
    try {
      await axios.post(`/private/insects`, {
        ...insectData,
      });

      return null;
    } catch (err) {
      toast.error(`An insect named ${insectData.name} already exists`);
      return rejectWithValue(ErrorChecker(err));
    }
  },
);
