import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Button, DialogActions, DialogContent, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IInsect } from 'src/app/interfaces/insect';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../../components/Modal';
import { useAppDispatch } from '../../../../stores/hooks';
import { initialFields, initialSelects, insectFields } from '../../constants/insectModal';
import { useSetTouched } from '../../hooks/useSetTouched';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { insectTranslatePaths } from '../../translations';
import type { IInitialSelects } from '../../constants/insectModal';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from './styles.module.scss';
import { editInsectAsync } from '../../store/actions/editInsectAsync';

type TProps = {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
  currentInsect: IInsect | null;
  isReadonly?: boolean;
};

export const EditInsectModal: FC<TProps> = ({
  isOpen,
  onClose,
  onAccept,
  currentInsect,
  isReadonly,
}: TProps) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const dispatch = useAppDispatch();

  const [fields, setFields] = useState<IInsect>(initialFields);
  0;
  const [selects, setSelects] = useState<IInitialSelects>(initialSelects);

  const { isTouched } = useSetTouched({ fields });

  useEffect(() => {
    if (currentInsect) {
      setFields(currentInsect);
    }
  }, [currentInsect]);

  useEffect(() => {
    setSelects((prev) => ({
      ...prev,
    }));
  }, []);

  const updateFieldsHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: string,
  ) => {
    setFields((prevState) => ({
      ...prevState,
      [field]: event.target.value,
    }));
  };

  const submit = async () => {
    await dispatch(editInsectAsync(fields));
    onAccept();
  };

  return (
    <BootstrapDialog
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      aria-labelledby="create_insect_title"
      open={isOpen}
      fullScreen={width <= 768}
    >
      <BootstrapDialogTitle id="create_insect_title" onClose={onClose}>
        {t(insectTranslatePaths.edit_insect.title)}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <div className={styles.insect_modal__field_container}>
          {insectFields.map((field) => (
            <TextField
              key={field.id}
              id={field.id}
              placeholder={t(insectTranslatePaths.edit_insect[field.id])}
              required
              value={fields[field.key] ?? ''}
              onChange={(event) => updateFieldsHandler(event, field.key)}
              fullWidth
              disabled={isReadonly}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        {isTouched && (
          <>
            <Button onClick={submit}>{t(insectTranslatePaths.edit_insect.ok)}</Button>
            <Button color="warning" onClick={onClose}>
              {t(insectTranslatePaths.edit_insect.cancel)}
            </Button>
          </>
        )}
      </DialogActions>
    </BootstrapDialog>
  );
};
