import { IOrganization } from 'src/app/interfaces/organization';
import { organizationTranslatePaths } from '../translations';

export const initialFields: IOrganization = {
  name: '',
  bankName: '',
  city: '',
  comment: '',
  contractEmail: '',
  contractFirstName: '',
  contractLastName: '',
  contractPhoneNumber: '',
  country: '',
  iban: '',
  registrationNumber: undefined,
  streetAddress: '',
  vat: '',
  zip: '',
};

export interface IField {
  id: keyof typeof organizationTranslatePaths.add_organizations;
  key: keyof typeof initialFields;
}

export const organizationFields: IField[] = [
  {
    id: 'name',
    key: 'name',
  },
  {
    id: 'bank_name',
    key: 'bankName',
  },
  {
    id: 'city',
    key: 'city',
  },
  {
    id: 'comment',
    key: 'comment',
  },
  {
    id: 'contract_email',
    key: 'contractEmail',
  },
  {
    id: 'contract_first_name',
    key: 'contractFirstName',
  },
  {
    id: 'contract_last_name',
    key: 'contractLastName',
  },
  {
    id: 'contract_phone_number',
    key: 'contractPhoneNumber',
  },
  {
    id: 'country',
    key: 'country',
  },
  {
    id: 'iban',
    key: 'iban',
  },
  {
    id: 'registration_number',
    key: 'registrationNumber',
  },
  {
    id: 'street_address',
    key: 'streetAddress',
  },
  {
    id: 'vat',
    key: 'vat',
  },
  {
    id: 'zip',
    key: 'zip',
  },
];

export interface IInitialSelects {
  organizationList: Array<IOrganization>;
}

export const initialSelects: IInitialSelects = {
  organizationList: [],
};
