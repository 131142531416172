export const deviceTranslatePaths = {
  title: 'devices.title',
  header: {
    search: 'devices.header.search',
    sort_by: 'devices.header.sort_by',
    direction: 'devices.header.direction',
    create_device: 'devices.header.create_device',
  },
  sort: {
    created_at: 'devices.sort.created_at',
    updated_at: 'devices.sort.updated_at',
    alias: 'devices.sort.alias',
    cropId: 'devices.sort.crop_id',
    serialNumber: 'devices.sort.serial_number',
    comment: 'devices.sort.comment',
  },
  devices_list: {
    created: 'devices.device_list.created_at',
    updated: 'devices.device_list.updated_at',
    alias: 'devices.device_list.alias',
    cropId: 'devices.device_list.crop_id',
    serialNumber: 'devices.device_list.serial_number',
    comment: 'devices.device_list.comment',
  },
  keys: {
    ADMIN: 'devices.keys.ADMIN',
    USER: 'devices.keys.USER',
    VIEWER: 'devices.keys.VIEWER',
    EDITOR: 'devices.keys.EDITOR',
  },
  add_device: {
    title: 'devices.add_device.title',
    first_name: 'devices.add_device.first_name',
    last_name: 'devices.add_device.last_name',
    email: 'devices.add_device.email',
    phone_number: 'devices.add_device.phone_number',
    initial_password: 'devices.add_device.initial_password',
    app_role: 'devices.add_device.app_role',
    organization: 'devices.add_device.organization',
    org_role: 'devices.add_device.org_role',
    comment: 'devices.add_device.comment',
    ok: 'devices.add_device.ok',
    cancel: 'devices.add_device.cancel',
    serial_number: 'devices.add_device.serial_number',
    name: 'devices.add_device.name',
    crop: 'devices.add_device.crop',
    alias: 'devices.add_device.name',
    initial_location: 'devices.add_device.initial_location',
  },
  edit_device: {
    title: 'devices.edit_device.title',
    first_name: 'devices.edit_device.first_name',
    last_name: 'devices.edit_device.last_name',
    email: 'devices.edit_device.email',
    phone_number: 'devices.edit_device.phone_number',
    initial_password: 'devices.edit_device.initial_password',
    app_role: 'devices.edit_device.app_role',
    organization: 'devices.edit_device.organization',
    org_role: 'devices.edit_device.org_role',
    comment: 'devices.edit_device.comment',
    ok: 'devices.edit_device.ok',
    cancel: 'devices.edit_device.cancel',
    serial_number: 'devices.edit_device.serial_number',
    name: 'devices.edit_device.name',
    crop: 'devices.edit_device.crop',
    alias: 'devices.edit_device.name',
    initial_location: 'devices.edit_device.initial_location',
  },
};
