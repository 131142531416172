import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../../stores/types';
import { UserModel } from "@fly-workspace/lib-api-interface";

export const createUserAsync = createAsyncThunk<
  UserModel,
  UserModel,
  ThunkConfig
>('users/createUser', async (request, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<{ data: UserModel }>(`/private/auth/register`, {
      ...request,
    });

    return data.data;
  } catch (err) {
    return rejectWithValue(ErrorChecker(err));
  }
});
