import React, { FC } from 'react';
import { Box, Button, CircularProgress, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from 'react-i18next';

import { buttonsTranslatePaths } from '../../../translations/paths';

import styles from './styles.module.scss';

type TProps = {
  toSave: boolean;
  clear: () => void;
  handleCreate: () => void;
  pending?: boolean;
};

export const AddButtons: FC<TProps> = ({ toSave, pending, clear, handleCreate }: TProps) => {
  const { t } = useTranslation();

  return (
    <Box marginTop="1rem" marginBottom="1rem">
      {toSave && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={4}
          className={styles.add_buttons}
        >
          {!pending && (
            <Button variant="outlined" onClick={() => clear()} startIcon={<DeleteIcon />}>
              {t(buttonsTranslatePaths.delete)}
            </Button>
          )}
          <Button
            onClick={() => handleCreate()}
            disabled={pending}
            variant="contained"
            endIcon={<SaveIcon />}
          >
            {pending ? (
              <CircularProgress
                size={24}
                sx={{
                  color: 'white',
                  position: 'relative',
                }}
              />
            ) : (
              <>{t(buttonsTranslatePaths.save)}</>
            )}
          </Button>
        </Stack>
      )}
    </Box>
  );
};
