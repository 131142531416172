import type { ICrop } from 'src/app/interfaces/crop';
import type { IOrganization } from '../../../interfaces/organization';
import { deviceTranslatePaths } from '../translations';

export const initialFields = {
  serialNumber: '',
  comment: '',
  alias: '',
  initialLocation: ''
};

export interface IField {
  id: keyof typeof deviceTranslatePaths.edit_device;
  key: keyof typeof initialFields;
  isRequired: boolean;
}

export const deviceFields: IField[] = [
  {
    id: 'serial_number',
    key: 'serialNumber',
    isRequired: true
  },
  {
    id: 'comment',
    key: 'comment',
    isRequired: true
  },
  {
    id: 'alias',
    key: 'alias',
    isRequired: true
  },
  {
    id: 'initial_location',
    key: 'initialLocation',
    isRequired: false
  }
];

export interface IInitialSelects {
  organizations?: IOrganization[];
  crops?: ICrop[];
}

export const initialSelects: IInitialSelects = {
  organizations: [],
  crops: [],
};
