import { useEffect, useState } from 'react';
import { UserModel } from "@fly-workspace/lib-api-interface";

type TProps = {
  usersList: UserModel[];
  rowsPerPage: number;
};

export const useSeparateUsersPerPage = ({ usersList, rowsPerPage }: TProps) => {
  const [usersForAllPages, setUsersForAllPages] = useState<UserModel[][]>([]);

  useEffect(() => {
    const chunksArray: UserModel[][] = [];
    for (let i = 0; i < usersList.length; i += rowsPerPage) {
      const chunk = usersList.slice(i, i + rowsPerPage);
      chunksArray.push(chunk);
    }
    setUsersForAllPages(chunksArray);
  }, [rowsPerPage, usersList]);

  return {
    usersForAllPages,
  };
};
