import { cropTranslatePaths } from '../translations';
import { IInsect } from "../../../interfaces/insect";

export const initialFields = {
  name: '',
  currentCrop: {
    createdAt: '',
    id: '',
    insects: [],
    name: '',
    updatedAt: null,
  },
};

export interface IField {
  id: keyof typeof cropTranslatePaths.edit_crop;
  key: keyof typeof initialFields;
}

export const cropFields: IField[] = [
  {
    id: 'name',
    key: 'name',
  },
];

export interface IInitialSelects {
  insectsList: Array<IInsect>;
  insect: Array<IInsect>;
}

export const initialSelects: IInitialSelects = {
  insectsList: [],
  insect: [],
};
