export const insectTranslatePaths = {
  title: 'insects.title',
  header: {
    search: 'insects.header.search',
    sort_by: 'insects.header.sort_by',
    direction: 'insects.header.direction',
    create_insect: 'insects.header.create_insect',
  },
  sort: {
    created_at: 'insects.sort.created_at',
    updated_at: 'insects.sort.updated_at',
    name: 'insects.sort.name',
  },
  insects_list: {
    created: 'insects.insects_list.created_at',
    updated: 'insects.insects_list.updated_at',
    name: 'insects.insects_list.name',
    size: 'insects.insects_list.size',
    pest_insects: 'insects.insects_list.pest_insects',
  },
  keys: {
    ADMIN: 'insects.keys.ADMIN',
    USER: 'insects.keys.USER',
    VIEWER: 'insects.keys.VIEWER',
    EDITOR: 'insects.keys.EDITOR',
  },
  add_insect: {
    title: 'insects.add_insect.title',
    ok: 'insects.add_insect.ok',
    cancel: 'insects.add_insect.cancel',
    name: 'insects.add_insect.name',
    image: 'insects.add_insect.image',
  },
  edit_insect: {
    title: 'insects.edit_insects.title',
    ok: 'insects.edit_insects.ok',
    cancel: 'insects.edit_insects.cancel',
    name: 'insects.edit_insects.name',
    image: 'insects.edit_insects.image',
  },
};
