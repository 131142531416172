import { DocProperty } from 'typescript-openapi-router';
import { Role } from './role';
import { enumToArray } from '@xtool/enum-to-array';

export class AuthenticationModel {
  @DocProperty({
    type: 'number',
    isRequired: true
  })
  userId: number;

  @DocProperty({
    type: 'string',
    isRequired: true
  })
  authToken: string;

  @DocProperty({
    type: 'string',
    isRequired: true
  })
  authTokenType: string;

  @DocProperty({
    type: 'string',
    isRequired: true
  })
  refreshToken: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
    enum: enumToArray(Role)
  })
  role: Role;

  @DocProperty({
    type: 'number',
    isRequired: true
  })
  organizationId: number;

}
