import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../../stores/types';
import { ICreateOrganizationRequestDTO } from '../../dto/createOrganization.dto';

export const createOrganizationAsync = createAsyncThunk<
  null,
  ICreateOrganizationRequestDTO,
  ThunkConfig
>('organization/createOrganization', async (organizationData, { rejectWithValue }) => {
  try {
    await axios.post(`/private/organizations`, {
      ...organizationData,
    });

    return null;
  } catch (err) {
    return rejectWithValue(ErrorChecker(err));
  }
});
