import { createTheme } from '@mui/material/styles';

export const theme = createTheme(
  {
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: '#92d050',
        light: '#f0ffe1',
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#99ccff',
      },
    },
  },
);
