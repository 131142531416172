export const organizationTranslatePaths = {
  title: 'organization.title',
  header: {
    search: 'organizations.header.search',
    sort_by: 'organizations.header.sort_by',
    direction: 'organizations.header.direction',
    create_organizations: 'organizations.header.create_organizations',
  },
  sort: {
    created_at: 'organizations.sort.created_at',
    updated_at: 'organizations.sort.updated_at',
    name: 'organizations.sort.name',
    contact_name: 'organizations.sort.contact_name',
    phone: 'organizations.sort.phone',
    email: 'organizations.sort.email',
  },
  organizations_list: {
    created: 'organizations.organizations_list.created_at',
    updated: 'organizations.organizations_list.updated_at',
    contact_name: 'organizations.organizations_list.contact_name',
    phone: 'organizations.organizations_list.phone',
    email: 'organizations.organizations_list.email',
  },
  keys: {
    ADMIN: 'organizations.keys.ADMIN',
    USER: 'organizations.keys.USER',
    VIEWER: 'organizations.keys.VIEWER',
    EDITOR: 'organizations.keys.EDITOR',
  },
  add_organizations: {
    title: 'organizations.add_organizations.title',
    ok: 'organizations.add_organizations.ok',
    cancel: 'organizations.add_organizations.cancel',
    name: 'organizations.add_organizations.name',
    bank_name: 'organizations.add_organizations.bank_name',
    city: 'organizations.add_organizations.city',
    comment: 'organizations.add_organizations.comment',
    contract_email: 'organizations.add_organizations.contract_email',
    contract_first_name: 'organizations.add_organizations.contract_first_name',
    contract_last_name: 'organizations.add_organizations.contract_last_name',
    contract_phone_number: 'organizations.add_organizations.contract_phone_number',
    country: 'organizations.add_organizations.country',
    iban: 'organizations.add_organizations.iban',
    registration_number: 'organizations.add_organizations.registration_number',
    street_address: 'organizations.add_organizations.street_address',
    vat: 'organizations.add_organizations.vat',
    zip: 'organizations.add_organizations.zip',
    address: 'organizations.add_organizations.address',
    legal_details: 'organizations.add_organizations.legal_details',
    contact_details: 'organizations.add_organizations.contact_details',
  },
  edit_organizations: {
    title: 'organizations.edit_organizations.title',
    ok: 'organizations.edit_organizations.ok',
    cancel: 'organizations.edit_organizations.cancel',
    name: 'organizations.edit_organizations.name',
    bank_name: 'organizations.edit_organizations.bank_name',
    city: 'organizations.edit_organizations.city',
    comment: 'organizations.edit_organizations.comment',
    contract_email: 'organizations.edit_organizations.contract_email',
    contract_first_name: 'organizations.edit_organizations.contract_first_name',
    contract_last_name: 'organizations.edit_organizations.contract_last_name',
    contract_phone_number: 'organizations.edit_organizations.contract_phone_number',
    country: 'organizations.edit_organizations.country',
    iban: 'organizations.edit_organizations.iban',
    registration_number: 'organizations.edit_organizations.registration_number',
    street_address: 'organizations.edit_organizations.street_address',
    vat: 'organizations.edit_organizations.vat',
    zip: 'organizations.edit_organizations.zip',
    address: 'organizations.edit_organizations.address',
    legal_details: 'organizations.edit_organizations.legal_details',
    contact_details: 'organizations.edit_organizations.contact_details',
  },
};
