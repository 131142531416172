import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../../stores/types';
import { IEditInsectpRequestDTO } from '../../dto/editInsectRequest.dto';

export const editInsectAsync = createAsyncThunk<null, IEditInsectpRequestDTO, ThunkConfig>(
  'insect/editInsect',
  async ({ id, name, image }, { rejectWithValue }) => {
    try {
      await axios.put(`/private/insects/${id}`, {
        name,
        image,
      });

      return null;
    } catch (err) {
      return rejectWithValue(ErrorChecker(err));
    }
  },
);
