import { DocProperty } from 'typescript-openapi-router';

export class TestDataImportRequestBody {

  @DocProperty({
    type: 'string',
    isRequired: true
  })
  deviceId: string;

  @DocProperty({
    type: 'array',
    objectType: 'string',
    isRequired: false
  })
  files?: string[];
}
