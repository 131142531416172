import { createSlice } from '@reduxjs/toolkit';
import { setAlertsAsync } from './actions/setAlertsAsync';
import { TRejectValue } from '../../../stores/types';

interface IInitialState {
  isFetching: boolean;
  errorMessage?: TRejectValue;
}

const initialState: IInitialState = {
  isFetching: false,
  errorMessage: null,
};

export const deviceSetAlertsSlice = createSlice({
  name: 'setAlertsDevices',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isFetching = false;
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setAlertsAsync.fulfilled, (state) => {
      state.isFetching = false;
      state.errorMessage = null;
    });
    builder.addCase(setAlertsAsync.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.errorMessage = payload;
    });
    builder.addCase(setAlertsAsync.pending, (state) => {
      state.isFetching = true;
      state.errorMessage = null;
    });
  },
});

export const { clearState } = deviceSetAlertsSlice.actions;
