import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { environment } from '../../environments/environment';
import { getLanguageLocalStorage, getTokenLocalStorage } from './localStorage';

const onRequest = (request: AxiosRequestConfig) => {
  const token = getTokenLocalStorage();

  if (request.headers) {
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    request.headers['x-api-Key'] = environment.apiKey;
  }

  return request;
};

const onResponse = (response: AxiosResponse) => {
  if (response.status === 401) {
    getTokenLocalStorage();
    window.location.reload();
  }
  return response;
};

axios.defaults.baseURL = environment.apiUrl;
axios.defaults.headers.common['Accept-Language'] = getLanguageLocalStorage();

axios.interceptors.request.use(onRequest);
axios.interceptors.response.use(onResponse);
