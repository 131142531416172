import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../../stores/types';
import type { updateCropDTO, updateCropRequestDTO } from '../../dto/updateCrops.dto';

export const updateCropAsync = createAsyncThunk<updateCropDTO, updateCropRequestDTO, ThunkConfig>(
  'crop/updateCrop',
  async ({ cropData, cropId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<{ data: updateCropDTO }>(`/private/crops/${cropId}`, {
        ...cropData,
      });

      return data.data;
    } catch (err) {
      return rejectWithValue(ErrorChecker(err));
    }
  },
);
