import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import SvgIcon from "@mui/material/SvgIcon/SvgIcon";

import { deviceStatusTranslatePaths } from "../translations";
import { DeviceStatusModel } from "@fly-workspace/lib-api-interface";

export type TDeviceStatusCards = keyof typeof deviceStatusTranslatePaths.cards;

export type TCardValue = {
  translate_path: TDeviceStatusCards;
  value: keyof Omit<DeviceStatusModel, 'altitude' | 'latitude' | 'longitude' | 'lastUpdate'>;
  date: keyof Omit<DeviceStatusModel, 'altitude' | 'latitude' | 'longitude'>;
};

export type TInfoCards = {
  icon: typeof SvgIcon;
  card_fields: TCardValue[];
  title: TDeviceStatusCards;
  separator: 'V' | '°C' | '%';
};

export const infoCards: TInfoCards[] = [
  {
    icon: BatteryChargingFullIcon,
    title: 'battery',
    separator: 'V',
    card_fields: [
      {
        translate_path: 'last_known_value',
        value: 'currentBatteryLevel',
        date: 'lastUpdate',
      },
      {
        translate_path: 'minimum',
        value: 'minBatteryLevel',
        date: 'minBatteryLevel',
      },
      {
        translate_path: 'maximum',
        value: 'maxBatteryLevel',
        date: 'maxBatteryLevel',
      },
    ],
  },
  {
    icon: DeviceThermostatIcon,
    title: 'temperature',
    separator: '°C',
    card_fields: [
      {
        translate_path: 'last_known_value',
        value: 'currentTemperature',
        date: 'lastUpdate',
      },
      {
        translate_path: 'minimum',
        value: 'minTemperature',
        date: 'minTemperature',
      },
      {
        translate_path: 'maximum',
        value: 'maxTemperature',
        date: 'maxTemperature',
      },
    ],
  },
  {
    icon: InvertColorsIcon,
    title: 'humidity',
    separator: '%',
    card_fields: [
      {
        translate_path: 'last_known_value',
        value: 'currentHumidity',
        date: 'lastUpdate',
      },
      {
        translate_path: 'minimum',
        value: 'minHumidity',
        date: 'minHumidity',
      },
      {
        translate_path: 'maximum',
        value: 'maxHumidity',
        date: 'maxHumidity',
      },
    ],
  },
];
