export const tableHeaderNamesTranslatePaths = {
  device_sn: 'manage_devices.table.header.device_sn',
  device_name: 'manage_devices.table.header.device_name',
  crop: 'manage_devices.table.header.crop',
  managed: 'manage_devices.table.header.managed',
};

export const changeCropModalPaths = {
  title: 'manage_devices.table.change_crop_alert.title',
  texts: {
    text1: 'manage_devices.table.change_crop_alert.texts.text1',
    text2: 'manage_devices.table.change_crop_alert.texts.text2',
    text3: 'manage_devices.table.change_crop_alert.texts.text3',
    text4: 'manage_devices.table.change_crop_alert.texts.text4',
  },
  buttons: {
    cancel: 'manage_devices.table.change_crop_alert.buttons.cancel',
    agree: 'manage_devices.table.change_crop_alert.buttons.agree',
  },
};
