import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import { initialFields } from '../constants/userModal';

type TProps = {
  fields: typeof initialFields;
};

export const useSetTouched = ({ fields }: TProps) => {
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    if (!isEqual(initialFields, fields)) {
      setIsTouched(true);
    } else {
      setIsTouched(false);
    }
  }, [fields]);

  return {
    isTouched,
  };
};
