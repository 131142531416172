import { DocProperty } from 'typescript-openapi-router';
import { CropModel } from './crop-model';

export class DeviceModel {

  @DocProperty({
    type: 'string',
    isRequired: true
  })
  id: string;

  @DocProperty({
    type: 'string',
    isRequired: true
  })
  createdAt: string;

  @DocProperty({
    type: 'string',
    isRequired: false
  })
  updatedAt?: string;

  @DocProperty({
    type: 'string',
    isRequired: true
  })
  alias: string;

  @DocProperty({
    type: 'number',
    isRequired: true
  })
  organizationId: number;

  @DocProperty({
    type: 'object',
    objectType: CropModel.name,
    isRequired: false
  })
  crop?: CropModel;

  @DocProperty({
    type: 'boolean',
    isRequired: true
  })
  isManagedByMe: boolean;

  @DocProperty({
    type: 'number',
    isRequired: false
  })
  alertCount?: number;

  @DocProperty({
    type: 'string',
    isRequired: false
  })
  lastLocationRecorded?: string;

  @DocProperty({
    type: 'string',
    isRequired: true
  })
  serialNumber: string;

  @DocProperty({
    type: 'string',
    isRequired: false
  })
  comment?: string;

}
