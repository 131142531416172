import { OrganizationRole } from './organization-role';
import { DocProperty, InheritedDoc } from 'typescript-openapi-router';
import { Role } from './role';


export class ApplicationRoles {
  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  admin: Role.admin;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  user: Role.user;
}


export class OrganizationRoles {
  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  admin: OrganizationRole.admin;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  editor: OrganizationRole.editor;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  viewer: OrganizationRole.viewer;
}

export class RolesListModel {
  @DocProperty({
    type: 'object',
    isRequired: false,
    objectType: ApplicationRoles.name,
  })
  applicationRoles: ApplicationRoles;

  @DocProperty({
    type: 'object',
    isRequired: false,
    objectType: OrganizationRoles.name,
  })
  organizationRoles: OrganizationRoles;
}
