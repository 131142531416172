import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../../stores/types';
import { ICreateDeviceRequestDTO } from '../../dto/createDeviceRequest.dto';

export const createDeviceAsync = createAsyncThunk<null, ICreateDeviceRequestDTO, ThunkConfig>(
  'device/createDevice',
  async ({ deviceData, organizationId }, { rejectWithValue }) => {
    try {
      await axios.post(`/private/organizations/${organizationId}/devices`, {
        ...deviceData,
      });

      return null;
    } catch (err) {
      return rejectWithValue(ErrorChecker(err));
    }
  },
);
