import React, { FC, useMemo } from "react";
import { Box, Checkbox, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

import { EInsectsDataKeys } from "../../constants";
import { Card } from "../../../../components/widgets/Card";
import { setUpAlertsInsectAlertsTranslatePaths } from "../../translations";

import type { TCustomInsect } from "../../interfaces";

import styles from "./styles.module.scss";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { AvailableAlert, isAlertAvailable } from "../../helpers";

type TProps = {
  currentCropId?: string;
  insectData: TCustomInsect[];
  setInsectData: (data: TCustomInsect[]) => void;
};

export const SetUpInsectAlerts: FC<TProps> = ({
                                                currentCropId,
                                                insectData,
                                                setInsectData
                                              }: TProps) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const renderInsectAlertsCheckbox = (
    title: string,
    checked: boolean,
    data: TCustomInsect[],
    index: number,
    value: string
  ) => {
    const onCheckboxClick = () => {
      const newData = [...data];
      // @ts-ignore
      newData[index][value] = !newData[index][value as keyof TCustomInsect];
      setInsectData(Array.from(newData));
    };

    return (
      <div className={styles.insects__checkbox_wrapper}>
        <Checkbox checked={checked} onClick={onCheckboxClick} />
        <div className={styles.insects__checkbox_title}>{title}</div>
      </div>
    );
  };

  const insectAlertsTitle = useMemo(() => {
    let title = t(setUpAlertsInsectAlertsTranslatePaths.title_no_crop);
    if (currentCropId) {
      if (insectData.length > 1) {
        title = t(setUpAlertsInsectAlertsTranslatePaths.title_plural, {
          crop: t(`crops.${currentCropId}`),
          insect_alerts_number: insectData.length
        });
      } else {
        title = t(setUpAlertsInsectAlertsTranslatePaths.title, {
          crop: t(`crops.${currentCropId}`)
        });
      }
    }
    return title.toUpperCase();
  }, [currentCropId, t]);

  return (
    <Card title={insectAlertsTitle}>
      <span className="explain">{t(setUpAlertsInsectAlertsTranslatePaths.text)}</span>

      <div className={styles.insects}>
        {insectData.map((insect, index) => {
          const newInsectData = [...insectData];
          if (!insect.availableAlerts.length) {
            return;
          }

          return (
            <div key={`insect_data_${insect.name}`} className={styles.insects__item}>
              <div className={styles.insects__checkbox_title}>{insect.name}</div>
              <Box display={"flex"} flexDirection={width < 900 ? "column" : "row"} flexWrap={"wrap"}
                   className={styles.insects__item_wrapper}>
                {isAlertAvailable(insect.availableAlerts, AvailableAlert.PHEROMONES) && renderInsectAlertsCheckbox(
                  t(setUpAlertsInsectAlertsTranslatePaths.pheromones),
                  insect[EInsectsDataKeys.isPheromone],
                  insectData,
                  index,
                  EInsectsDataKeys.isPheromone
                )}
                {isAlertAvailable(insect.availableAlerts, AvailableAlert.ACTIVITY) && renderInsectAlertsCheckbox(
                  t(setUpAlertsInsectAlertsTranslatePaths.conditions),
                  insect[EInsectsDataKeys.isActivity],
                  insectData,
                  index,
                  EInsectsDataKeys.isActivity
                )}
                {isAlertAvailable(insect.availableAlerts, AvailableAlert.FIRST) && renderInsectAlertsCheckbox(
                  t(setUpAlertsInsectAlertsTranslatePaths.at_first_occurrence),
                  insect[EInsectsDataKeys.alertAtFirstDetection],
                  insectData,
                  index,
                  EInsectsDataKeys.alertAtFirstDetection
                )}
                {isAlertAvailable(insect.availableAlerts, AvailableAlert.EGGS) && renderInsectAlertsCheckbox(
                  t(setUpAlertsInsectAlertsTranslatePaths.egg_laying),
                  insect[EInsectsDataKeys.isEggLaying],
                  insectData,
                  index,
                  EInsectsDataKeys.isEggLaying
                )}
                {isAlertAvailable(insect.availableAlerts, AvailableAlert.SPRAY) && renderInsectAlertsCheckbox(
                  t(setUpAlertsInsectAlertsTranslatePaths.spray),
                  insect[EInsectsDataKeys.isSpray],
                  insectData,
                  index,
                  EInsectsDataKeys.isSpray
                )}
                {isAlertAvailable(insect.availableAlerts, AvailableAlert.THRESHOLD) &&
                  <div className={styles.insects__threshold}>
                    <Checkbox
                      checked={insect[EInsectsDataKeys.isThreshold]}
                      onClick={() => {
                        newInsectData[index].isThreshold = !newInsectData[index].isThreshold;
                        newInsectData[index].alertAtThreshold = 0;
                        setInsectData(Array.from(newInsectData));
                      }}
                    />
                    <div className={styles.insects__threshold_title}>
                      {t(setUpAlertsInsectAlertsTranslatePaths.threshold)}
                    </div>
                    <div className={styles.insects__threshold_input_wrapper}>
                      <TextField
                        className={styles.insects__threshold_input}
                        disabled={!insect.isThreshold}
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]" }}
                        value={insect.alertAtThreshold}
                        onChange={(event) => {
                          if (RegExp("[0-9]").test(event.target.value)) {
                            newInsectData[index].alertAtThreshold = +event.target.value;
                          } else {
                            newInsectData[index].alertAtThreshold = 0;
                          }
                          setInsectData(Array.from(newInsectData));
                        }}
                      />
                    </div>
                  </div>}
              </Box>
            </div>
          );
        })}
      </div>
    </Card>
  );
};
