import { createSlice, isPending, isAsyncThunkAction, isRejectedWithValue } from '@reduxjs/toolkit';

import { IInsect } from 'src/app/interfaces/insect';
import { getInsectListAsync } from './actions/getInsectListAsync';
import type { TRejectValue } from '../../../stores/types';
import { createInsectAsync } from './actions/createInsectAsync';
import { editInsectAsync } from './actions/editInsectAsync';

interface IInitialState {
  insectList: IInsect[];
  error?: TRejectValue;
  isLoading: boolean;
}

const initialState: IInitialState = {
  insectList: [],
  error: undefined,
  isLoading: false,
};

export const insectSlice = createSlice({
  name: 'insect',
  initialState,
  reducers: {
    clearInsectState: (state) => {
      state.insectList = [];
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInsectListAsync.fulfilled, (state, { payload }) => {
      state.insectList = payload;
      state.isLoading = false;
      state.error = null;
    });

    builder.addMatcher(
      isRejectedWithValue(getInsectListAsync, createInsectAsync, editInsectAsync),
      (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
      },
    );

    builder.addMatcher(
      isPending(getInsectListAsync, createInsectAsync, editInsectAsync),
      (state) => {
        state.isLoading = true;
        state.error = null;
      },
    );
    builder.addMatcher(isAsyncThunkAction(createInsectAsync, editInsectAsync), (state) => {
      state.isLoading = false;
      state.error = null;
    });
  },
});

export const { clearInsectState } = insectSlice.actions;
