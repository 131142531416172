import { DocProperty } from 'typescript-openapi-router';
import { InsectRecipientFillRateModel } from '../model/insect-recipient-fill-rate-model';
import { ResponseEnvelope } from './response-envelope';

export class InsectRecipientResponse extends ResponseEnvelope {
  @DocProperty({
    type: 'object',
    objectType: InsectRecipientFillRateModel.name,
    isRequired: false,
  })
  data?: InsectRecipientFillRateModel;
}
