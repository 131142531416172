import { DocProperty } from 'typescript-openapi-router';

export class InsectModel {

  @DocProperty({
    type: 'string',
    isRequired: true
  })
  id: string;

  @DocProperty({
    type: 'string',
    isRequired: true
  })
  name: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  createdAt?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  updatedAt?: string;

  @DocProperty({
    type: 'number',
    isRequired: false,
  })
  size?: number

  @DocProperty({
    type: 'array',
    objectType: 'string',
    isRequired: false,
  })
  availableAlerts?: string[];
}
