import { createSlice, isPending, isRejectedWithValue } from '@reduxjs/toolkit';

import { getPheromoneLogAsync } from './actions/getPheromoneLogAsync';
import { addPheromoneLogAsync } from './actions/addPheromoneLogAsync';
import { getInsectRecipientFillRateAsync } from './actions/getInsectRecipientFillRateAsync';
import { updateLastCleanDateAsync } from './actions/updateLastCleanDateAsync';

import type { IPheromoneLog } from '../interfaces';
import type { TRejectValue } from '../../../stores/types';

interface IInitialState {
  pheromonesList: IPheromoneLog[] | null;
  isLoading: boolean;
  errorMessage?: TRejectValue;
  insectRecipient?: {
    fillRate: number;
    lastCleanedAt?: string;
  };
}

const initialState: IInitialState = {
  pheromonesList: [],
  isLoading: false,
  errorMessage: null,
  insectRecipient: undefined,
};

export const pheromonesSlice = createSlice({
  name: 'pheromones',
  initialState,
  reducers: {
    clearPheromonesListState: (state) => {
      state.errorMessage = null;
      state.isLoading = false;
      state.pheromonesList = [];
      state.insectRecipient = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPheromoneLogAsync.fulfilled, (state, { payload }) => {
      state.pheromonesList = payload;
      state.isLoading = false;
    });

    builder.addCase(addPheromoneLogAsync.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getInsectRecipientFillRateAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.insectRecipient = payload;
    });

    builder.addCase(updateLastCleanDateAsync.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addMatcher(
      isPending(
        getPheromoneLogAsync,
        addPheromoneLogAsync,
        getInsectRecipientFillRateAsync,
        updateLastCleanDateAsync,
      ),
      (state) => {
        state.isLoading = true;
      },
    );

    builder.addMatcher(
      isRejectedWithValue(
        getPheromoneLogAsync,
        addPheromoneLogAsync,
        getInsectRecipientFillRateAsync,
        updateLastCleanDateAsync,
      ),
      (state, { payload }) => {
        state.isLoading = false;
        state.errorMessage = payload;
      },
    );
  },
});

export const { clearPheromonesListState } = pheromonesSlice.actions;
