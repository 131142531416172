import React, { CSSProperties, FC, PropsWithChildren } from 'react';
import SvgIcon from '@mui/material/SvgIcon/SvgIcon';
import classNames from 'classnames';

import { InfoCard } from '../InfoCard';

import styles from './styles.module.scss';

type TProps = {
  Icon: typeof SvgIcon;
  title: string;
  style?: CSSProperties;
  className?: string;
};

export const StyledInfoCard: FC<PropsWithChildren<TProps>> = ({
  Icon,
  title,
  children,
  style,
  className,
}: PropsWithChildren<TProps>) => {
  const minWidth = '20rem';
  const minHeight = '8rem';
  const padding = '1rem';

  return (
    <InfoCard
      Icon={Icon}
      title={title}
      minWidth={minWidth}
      minHeight={minHeight}
      paddingLeft={padding}
      paddingRight={padding}
      titleVariant="h6"
      className={classNames(styles.styled_info_card, className)}
      style={style}
    >
      {children}
    </InfoCard>
  );
};
