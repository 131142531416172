export enum ELanguages {
  english = 'en',
  russian = 'ru',
  romanian = 'ro',
  ukrainian = 'ua',
}

export const languages = {
  [ELanguages.english]: 'english',
  [ELanguages.russian]: 'russian',
  [ELanguages.romanian]: 'romain',
  [ELanguages.ukrainian]: 'ukrainian',
};
