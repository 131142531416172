import { DocProperty } from 'typescript-openapi-router';

export class EditCropRequestBody {
  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  id?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  name?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  createdAt?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  updatedAt?: string;

  @DocProperty({
    type: 'number',
    isRequired: false,
  })
  size?: number;
}
