import { Box, Typography } from "@mui/material";
import { GDDChartColor } from "./chart-colors";
import { useTranslation } from "react-i18next";

export const CustomLegend = (props) => {
  const { payload } = props;
  const { t } = useTranslation();

  return <Box display={"flex"} flexDirection={'row'} flexWrap={"wrap"} justifyContent={"center"} gap={'1rem'}>
    {payload.map((entry, index) => (
      <Typography key={`item-${index}`} style={{color:GDDChartColor.gddValueColor}}>{entry.value}</Typography>
    ))}
    <Typography key={`item-predicted-alert`} style={{color:GDDChartColor.alertPredictionColor}}>{t('device_data.charts.gdd_legend_predicted')}</Typography>
    <Typography key={`item-sent-alert`} style={{color:GDDChartColor.sentAlertColor}}>{t('device_data.charts.gdd_legend_generated')}</Typography>
  </Box>;
};
