import { DocProperty, Envelope } from 'typescript-openapi-router';
import { ApiErrorModel } from '../model';

export class ResponseEnvelope implements Envelope {
  @DocProperty({
    type: 'array',
    objectType: ApiErrorModel.name,
    isRequired: false,
  })
  errors?: ApiErrorModel[];
}
