import { DocProperty } from 'typescript-openapi-router';

export class editInsectRequestBody {
  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  id?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  name?: string;

  @DocProperty({
    type: 'number',
    isRequired: false,
  })
  size?: number;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  image?: string;
}
