import { ResponseEnvelope } from './response-envelope';
import { DocProperty, InheritedDoc } from 'typescript-openapi-router';
import { DeviceModel } from '../model';

@InheritedDoc()
export class ManagedDevicesResponseBody extends ResponseEnvelope {

  @DocProperty({
    type: 'array',
    isRequired: true,
    objectType: DeviceModel.name
  })
  data?: DeviceModel[];
}
