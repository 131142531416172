import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { getUserRoleLocalStorage } from '../../../helpers/localStorage';

export const ProtectedRoute = ({ path, component, ...rest }: RouteProps) => {
  const isAdmin = getUserRoleLocalStorage() === 'ADMIN';

  return isAdmin ? <Route path={path} component={component} {...rest} /> : <Redirect to="/" />;
};
