import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { getOrganizationIdLocalStorage } from '../../../../helpers/localStorage';
import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../../stores/types';
import type { getMyDevicesDTO } from '../../dto/getMyDevices.dto';
import { TCropIdUnion } from '../../../../interfaces/crop';

type getMyDevicesRequestDTO = {
  body: {
    serialNumber: string;
    alias: string;
    cropId?: TCropIdUnion;
  };
};

export const addMyDevicesAsync = createAsyncThunk<
  getMyDevicesDTO,
  getMyDevicesRequestDTO,
  ThunkConfig
>('devices/organization/my/add', async (request, { rejectWithValue }) => {
  const organizationId = getOrganizationIdLocalStorage();

  try {
    const { data } = await axios.post<{ data: getMyDevicesDTO }>(
      `/private/organizations/${organizationId}/devices`,
      request.body,
    );

    return data.data;
  } catch (err) {
    return rejectWithValue(ErrorChecker(err));
  }
});
