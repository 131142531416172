import { DocProperty } from 'typescript-openapi-router';
import { RequestParameters } from './request-parameters';
import { ResponseElements } from './response-elements';
import { Identity } from './identity';
import { S3 } from './s3';

export class S3EventRecord {
  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  eventVersion: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  eventSource: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  awsRegion: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  eventTime: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  eventName: string;

  @DocProperty({
    type: 'object',
    objectType: Identity.name,
    isRequired: true,
  })
  userIdentity: Identity;

  @DocProperty({
    type: 'object',
    objectType: RequestParameters.name,
    isRequired: true,
  })
  requestParameters: RequestParameters;

  @DocProperty({
    type: 'object',
    objectType: ResponseElements.name,
    isRequired: true,
  })
  responseElements: ResponseElements;

  @DocProperty({
    type: 'object',
    objectType: S3.name,
    isRequired: true,
  })
  s3: S3;
}
