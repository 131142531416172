import React, { FC, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../stores/hooks';
import { getPheromoneLogAsync } from './store/actions/getPheromoneLogAsync';
import { clearPheromonesListState } from './store';
import { getMyDevicesAsync } from '../ManageDevices/store/actions';
import { getInsectRecipientFillRateAsync } from './store/actions/getInsectRecipientFillRateAsync';
import { PheromoneStatus } from './components/PheromoneStatus';
import { PheromoneRefill } from './components/PheromoneRefill';
import { InsectRecipient } from './components/InsectRecipient';
import { navbarSelector } from '../Navbar/store/selectors';

import styles from './styles.module.scss';

export const Pheromones: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { deviceId, language } = useAppSelector(navbarSelector);

  useEffect(() => {
    dispatch(getMyDevicesAsync());

    return () => {
      dispatch(clearPheromonesListState());
    };
  }, []);

  useEffect(() => {
    if (deviceId) {
      dispatch(getPheromoneLogAsync({ deviceId }));
      dispatch(getInsectRecipientFillRateAsync({ deviceId }));
    }
  }, [deviceId, language]);

  return (
    <>
      <PheromoneStatus mainStyles={styles} />
      {deviceId && <PheromoneRefill mainStyles={styles} currentDevice={deviceId} />}
      {/* <InsectRecipient mainStyles={styles} currentDevice={deviceId} /> */}
    </>
  );
};
