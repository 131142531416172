import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorChecker } from '../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../stores/types';
import type { getAllCropsDTO } from '../../dto';

export const getAllCropsAsync = createAsyncThunk<getAllCropsDTO, void, ThunkConfig>(
  'main/crops_list',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<{ data: getAllCropsDTO }>(`/private/crops`);

      return data.data;
    } catch (err) {
      return rejectWithValue(ErrorChecker(err));
    }
  },
);
