import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, TextField } from '@mui/material';

import { Card } from '../../../../components/widgets/Card';
import { setUpAlertsAlertsTranslatePaths } from '../../translations';

type TProps = {
  setMobilePhoneRecipient: (value: string) => void;
  setEmailRecipients: (value: string) => void;
  setSendRate: (value: boolean) => void;
  mobilePhoneRecipient: string;
  emailRecipients: string;
  sendRate: boolean;
};

export const WillBeSentTo: FC<TProps> = ({
  setMobilePhoneRecipient,
  setEmailRecipients,
  setSendRate,
  mobilePhoneRecipient,
  emailRecipients,
  sendRate,
}: TProps) => {
  const { t } = useTranslation();

  const handleChangeMobilePhoneRecipient = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMobilePhoneRecipient(event.target.value);
  };

  const handleChangeEmailRecipients = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailRecipients(event.target.value);
  };

  const handleChangeRate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendRate(event.target.checked);
  };

  return (
    <Card title={t(setUpAlertsAlertsTranslatePaths.title)}>
      <div className="add-item">
        <span className="header-text">{t(setUpAlertsAlertsTranslatePaths.mobile_phone)}</span>
        <TextField
          id="outlined-name"
          placeholder={t(setUpAlertsAlertsTranslatePaths.mobile_phone_placeholder)}
          className="text"
          value={mobilePhoneRecipient}
          onChange={handleChangeMobilePhoneRecipient}
        />
      </div>

      <span className="explain">{t(setUpAlertsAlertsTranslatePaths.mobile_phone_text)}</span>

      <div className="add-item">
        <span className="header-text">{t(setUpAlertsAlertsTranslatePaths.email)}</span>
        <TextField
          id="outlined-name"
          placeholder={t(setUpAlertsAlertsTranslatePaths.email_placeholder)}
          className="text"
          value={emailRecipients}
          onChange={handleChangeEmailRecipients}
        />
      </div>

      <span className="explain">{t(setUpAlertsAlertsTranslatePaths.email_text)}</span>

      <div className="add-item">
        <span className="header-text alerts">{t(setUpAlertsAlertsTranslatePaths.alerts)}</span>
        <Checkbox checked={sendRate} onChange={handleChangeRate} />
      </div>

      <span className="explain">{t(setUpAlertsAlertsTranslatePaths.alerts_text)}</span>
    </Card>
  );
};
