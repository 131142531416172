import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../../stores/types';
import { IEditOrganizationRequestDTO } from '../../dto/editOrganization.dto';

export const editOrganizationAsync = createAsyncThunk<
  null,
  IEditOrganizationRequestDTO,
  ThunkConfig
>('organization/createOrganization', async (organizationData, { rejectWithValue }) => {
  try {
    await axios.put(`/private/organizations/${organizationData.id}/update`, {
      data: organizationData,
    });

    return null;
  } catch (err) {
    return rejectWithValue(ErrorChecker(err));
  }
});
