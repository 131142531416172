import { AlertStatus } from './alert-status';
import { DocProperty } from 'typescript-openapi-router';
import { enumToArray } from '@xtool/enum-to-array';
import { AlertType } from './alert-type';

export class SentAlertModel {

  @DocProperty({
    type: 'string',
    isRequired: true
  })
  id: string;

  @DocProperty({
    type: 'string',
    isRequired: true
  })
  recordedAt: string;

  @DocProperty({
    type: 'string',
    isRequired: true
  })
  content: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
    enum: enumToArray(AlertStatus)
  })
  status: AlertStatus;

  @DocProperty({
    type: 'string',
    isRequired: true,
    enum: enumToArray(AlertType),
  })
  type: AlertType;
}
