import { DocProperty } from 'typescript-openapi-router';
import { ContactPerson } from '../model/person-model';

export class AddOrganizationRequestBody {
  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  name: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  streetAddress: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  country: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  city: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  zip?: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  vat: string;

  @DocProperty({
    type: 'number',
    isRequired: true,
  })
  registrationNumber: number;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  iban?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  bankName?: string;

  @DocProperty({
    type: 'object',
    objectType: ContactPerson.name,
    isRequired: true,
  })
  contractPerson: ContactPerson;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  comment?: string;

  @DocProperty({
    type: 'number',
    isRequired: false,
  })
  organizationToUserId?: number;
}
