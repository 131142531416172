import React, { useMemo } from "react";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import { tableHeaderNamesTranslatePaths } from "../translations";
import { TransparentButton } from "../../../components/TransparentButton";
import { setDeviceId } from "../../Navbar/store";
import { useAppDispatch } from "../../../stores/hooks";
import { cutString } from "../../../helpers";

import type { ICrop } from "../../../interfaces/crop";

import Eye from "../../../../assets/eye.svg";
import styles from "./styles.module.scss";
import { ValueOptions } from "@mui/x-data-grid/models/colDef/gridColDef";

type ValueOptionsType = {
  value: any;
  label: string;
};

export const useColumns = (crops: ICrop[]): GridColumns => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const eyeClickHandler = (id?: string) => {
    if (id) dispatch(setDeviceId(id));
    history.push("/device-data");
  };

  const renderSerialNumberCell = (params: GridRenderCellParams) => (
    <div className={styles.serial_number}>
      <Tooltip title={params.value}>
        <span>{cutString(params.value, 20)}</span>
      </Tooltip>
      <TransparentButton onClick={() => eyeClickHandler(params.row?.id)}>
        <img width={22} src={Eye} alt="eye" />
      </TransparentButton>
    </div>
  );
  const cropValueOptions = useMemo<ValueOptions[]>(() => crops.map((crop) => {
    const value = crop.id;
    const label = t(`crops.${crop.id}`);
    return {
      value,
      label
    };
  }), [crops]);

  if (width >= 768) {
    return [
      {
        field: "serialNumber",
        headerName: t(tableHeaderNamesTranslatePaths.device_sn),
        width: 210,
        editable: false,
        renderCell: renderSerialNumberCell
      },
      {
        field: "alias",
        headerName: t(tableHeaderNamesTranslatePaths.device_name),
        type: "string",
        width: 180,
        editable: true
      },
      {
        field: "cropName",
        headerName: t(tableHeaderNamesTranslatePaths.crop),
        type: "singleSelect",
        width: 180,
        editable: true,
        valueOptions: cropValueOptions,
      },
      {
        field: "isManagedByMe",
        headerName: t(tableHeaderNamesTranslatePaths.managed),
        type: "boolean",
        width: 100,
        editable: true
      }
    ];
  }
  return [
    {
      field: "serialNumber",
      headerName: t(tableHeaderNamesTranslatePaths.device_sn),
      width: 145,
      editable: false,
      renderCell: renderSerialNumberCell
    },
    {
      field: "cropName",
      headerName: t(tableHeaderNamesTranslatePaths.crop),
      type: "singleSelect",
      width: 70,
      editable: true,
      valueOptions: cropValueOptions
    },
    {
      field: "isManagedByMe",
      headerName: t(tableHeaderNamesTranslatePaths.managed),
      type: "boolean",
      width: 90,
      editable: true
    }
  ];
};
