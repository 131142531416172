import React, { PropsWithChildren } from "react";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";

import { getTokenLocalStorage } from "../../../helpers/localStorage";


export const PrivateRoute = ({ children, ...rest }: PropsWithChildren<RouteProps>) => (
  <Route
    {...rest}
    // @ts-ignore
    render={(props: RouteComponentProps<any>) => {
      const token = getTokenLocalStorage();
      if (token) {
        return children;
      }
      return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
    }}
  />
);
