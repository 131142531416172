import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';
import { getPheromoneLogAsync } from './getPheromoneLogAsync';
import { getOrganizationIdLocalStorage } from '../../../../helpers/localStorage';

import type { ThunkConfig } from '../../../../stores/types';
import type { addPheromoneLogRequestDTO } from '../../dto/addPheromoneLog.dto';

export const addPheromoneLogAsync = createAsyncThunk<null, addPheromoneLogRequestDTO, ThunkConfig>(
  'pheromones/add-pheromone-log',
  async ({ deviceId, body }, { rejectWithValue, dispatch }) => {
    const organizationId = getOrganizationIdLocalStorage();

    try {
      await axios.post(
        `/private/organizations/${organizationId}/devices/${deviceId}/pheromone-log`,
        body,
      );

      dispatch(getPheromoneLogAsync({ deviceId }));
      return null;
    } catch (err) {
      return rejectWithValue(ErrorChecker(err));
    }
  },
);
