import { DocProperty } from 'typescript-openapi-router';

export class UpdateInsectRequestBody {

  @DocProperty({
    type: 'string',
    isRequired: true
  })
  name: string;
}
