import { createSlice } from '@reduxjs/toolkit';

import { loginUserAsync } from './actions/loginUserAsync';
import { getUserRoleLocalStorage, setUserRoleLocalStorage } from '../../../helpers/localStorage';

import type { TRejectValue } from '../../../stores/types';
import type { RootState } from '../../../stores/store';
import type { TUserRoleUnion } from '../dto/loginUser.dto';

interface IInitialState {
  role?: TUserRoleUnion;
  userId: number;
  isFetching: boolean;
  errorMessage?: TRejectValue;
}

const initialState: IInitialState = {
  role: getUserRoleLocalStorage() || undefined,
  userId: 0,
  isFetching: false,
  errorMessage: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUserAsync.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.errorMessage = null;
      if (payload) {
        setUserRoleLocalStorage(payload.role);
        state.role = payload.role;
        state.userId = payload.userId;
      }
    });
    builder.addCase(loginUserAsync.rejected, (state, { payload }) => {
      state.errorMessage = payload;
      state.isFetching = false;
    });
    builder.addCase(loginUserAsync.pending, (state) => {
      state.errorMessage = null;
      state.isFetching = true;
    });
  },
});

export const { clearState } = userSlice.actions;

export const userSelector = (state: RootState) => state.user;
