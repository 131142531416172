import React, { FC } from 'react';
import { CircularProgress, Tooltip } from '@mui/material';
import moment from 'moment';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { cutString } from '../../../../helpers';
import { Card } from '../../../../components/widgets/Card';
import { useAppSelector } from '../../../../stores/hooks';

import type { IPheromoneLog } from '../../interfaces';

import optionsIcon from '../../../../../assets/options.svg';
import styles from './styles.module.scss';
import { pheromoneStatusTranslatePaths } from '../../translations';

type TProps = {
  mainStyles: { readonly [key: string]: string };
};

export const PheromoneStatus: FC<TProps> = ({ mainStyles }: TProps) => {
  const { t } = useTranslation();

  const { pheromonesList, isLoading, errorMessage } = useAppSelector((state) => state.pheromones);

  const cn = {
    pheromone_status_card: (refilledAt: string) =>
      classNames(mainStyles.card, {
        [mainStyles.alert]: moment(refilledAt) <= moment(),
      }),
  };

  return (
    <Card className={mainStyles.card_wrapper__gap} title={t(pheromoneStatusTranslatePaths.title)}>
      <div className={styles.status__wrapper}>
        {isLoading ? (
          <CircularProgress size={40} className={mainStyles.loading} />
        ) : pheromonesList?.length ? (
          pheromonesList.map((pheromone: IPheromoneLog, index: number) => (
            <div
              key={`pheromones_${pheromone.insectId + index}`}
              className={cn.pheromone_status_card(pheromone.refilledAt)}
            >
              <div className={styles.status__name_wrapper}>
                <img src={optionsIcon} alt="img" />
                <Tooltip title={pheromone.name}>
                  <span className={styles.status__name}>{cutString(pheromone.name, 25)}</span>
                </Tooltip>
              </div>
              <div>{pheromone.provider}</div>
              <div>
                {t(pheromoneStatusTranslatePaths.lifespan, {
                  lifespan: moment.duration(pheromone.lifespan, 'milliseconds').asDays(),
                })}
              </div>
              <div>
                {t(pheromoneStatusTranslatePaths.refilled_at, {
                  date: moment(pheromone.refilledAt).format('DD.MM.YYYY'),
                })}
              </div>
              <div>
                {t(pheromoneStatusTranslatePaths.next_refilled, {
                  date: moment(pheromone.refilledAt).format('DD.MM.YYYY'),
                })}
              </div>
            </div>
          ))
        ) : (
          <div style={{ color: errorMessage?.message ? 'red' : '' }}>
            {errorMessage?.message ? errorMessage.message : t(pheromoneStatusTranslatePaths.error)}
          </div>
        )}
      </div>
    </Card>
  );
};
