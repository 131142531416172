import { enumToArray } from '@xtool/enum-to-array';
import { DocProperty } from 'typescript-openapi-router';
import { DeviceModel } from './device-model';
import { InsectModel } from './insect-model';
import { OrganizationModel } from './organization-model';
import { Supplier } from './pheromone-supplier';

export class PheromoneModel {
  @DocProperty({
    type: 'number',
    isRequired: false,
  })
  id?: number;

  @DocProperty({
    type: 'number',
    isRequired: false,
  })
  lifespan?: number;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  name?: string;

  @DocProperty({
    type: 'string',
    isRequired: false,
    enum: enumToArray(Supplier),
  })
  supplier?: Supplier;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  comment?: string;

  @DocProperty({
    type: 'number',
    isRequired: false,
  })
  organizationId?: number;

  @DocProperty({
    type: 'array',
    objectType: InsectModel.name,
    isRequired: false,
  })
  insectIds?: InsectModel[];

  @DocProperty({
    type: 'array',
    objectType: OrganizationModel.name,
    isRequired: false,
  })
  organization?: OrganizationModel[];

  @DocProperty({
    type: 'array',
    objectType: DeviceModel.name,
    isRequired: false,
  })
  deviceIds?: DeviceModel[];
}
