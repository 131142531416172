import { DocProperty } from 'typescript-openapi-router';

export class AddPheromoneLogRequestBody {
  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  insectId: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  cropId: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  name: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  provider: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  lifespan: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  refilledAt: Date; 
}
