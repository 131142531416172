import { DocProperty } from 'typescript-openapi-router';

// { date: "15.12.2021 - 05:00:00", ostrinia: 12, maleghensis: 20 },
export class InsectDetection {

  @DocProperty({
    type: 'array',
    objectType: 'string',
    isRequired: false
  })
  photo?: string;

  @DocProperty({
    type: 'array',
    objectType: 'string',
    isRequired: true
  })
  detectedAt: string;

  [key: string]: any;
}
