/* eslint-disable  @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { getOrganizationIdLocalStorage } from '../../../../helpers/localStorage';
import { getAllDevicesAsync, getMyDevicesAsync } from '../../../ManageDevices/store/actions';
import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { SetAlertsRequest } from '../../dto/setAlerts.request.dto';
import type { ThunkConfig } from '../../../../stores/types';

// TODO: DELETE ANY
export const setAlertsAsync = createAsyncThunk<any, SetAlertsRequest, ThunkConfig>(
  'devices/alerts/update',
  async ({ body }, { rejectWithValue, dispatch }) => {
    const organizationId = getOrganizationIdLocalStorage();

    try {
      await axios.post<{ data: undefined }>(
        `/private/organizations/${organizationId}/devices/alerts`,
        body,
      );

      dispatch(getAllDevicesAsync());
      dispatch(getMyDevicesAsync());

      return body;
    } catch (err) {
      return rejectWithValue(ErrorChecker(err));
    }
  },
);
