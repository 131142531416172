import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Dialog, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import ImageGallery from "react-image-gallery";

import { selectDeviceInsect } from "../../store";
import { Card } from "../../../../components/widgets/Card";
import { ImageManager } from "../../helpers";
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks";
import { deviceDataSelector, sensorSourceSelector } from "../../store/selectors";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { deviceInsectTranslatePaths } from "../../translations";
import { getDeviceInfoAsync } from "../../../Navbar/store/actions/getDeviceInfoAsync";
import { navbarSelector } from "../../../Navbar/store/selectors";

import "./styles.scss";
import { downloadCSV } from "../../helpers/download-manager";
import { InsectChart as InsectCharModel, InsectDetectionModel } from "@fly-workspace/lib-api-interface";
import { updateInsectNameAsync } from "../../store/actions/updateInsectNameAsync";
import { getUserRoleLocalStorage } from "../../../../helpers/localStorage";
import { InsectChart } from "./insect-chart";

const getNormalizedInsectName = (insectName: string) => {
  return insectName.toLowerCase().replaceAll(" ", "");
};

const getDisplayedInsectDetectionName = (updatedInsects: Map<number, string>, images: InsectDetectionModel[], imageGallery?: React.MutableRefObject<ImageGallery | null>, selectedImageIndex?: number) => {
  if (imageGallery && imageGallery.current) {
    if (updatedInsects.has(imageGallery.current.getCurrentIndex())) {
      return updatedInsects.get(imageGallery.current.getCurrentIndex());
    }
    let insectName = images[imageGallery.current.getCurrentIndex()].name;
    return getNormalizedInsectName(insectName);
  } else if (selectedImageIndex) {
    let insectName = images[selectedImageIndex].name;
    return getNormalizedInsectName(insectName);
  }
  return "unknown";
};

export const InsectChartCard: FC = () => {
  const isAdmin = getUserRoleLocalStorage() === "ADMIN";
  const dispatch = useAppDispatch();
  const { height } = useWindowDimensions();
  const { t } = useTranslation();

  const photoRef = useRef<HTMLDivElement | null>(null);

  const { images, insect } = useAppSelector(deviceDataSelector);
  const { deviceId, deviceInfo, dates } = useAppSelector(navbarSelector);
  const chartForInsects = deviceInfo?.charts?.find((chart) => chart.name === "insect");
  const insectChart: InsectCharModel | undefined = chartForInsects ? chartForInsects as InsectCharModel : undefined;
  const sensorSource = useAppSelector(sensorSourceSelector);

  const [selectedImageIndex, setSelectedImageIndex] = useState<number | undefined>();
  const [inGallerySelectedImage, setInGallerySelectedImage] = useState<number | undefined>();
  const [updatedInsects, setUpdatedInsects] = useState<Map<number, string>>(new Map());
  let imageGallery = useRef<ImageGallery>(null);

  useEffect(() => {
    if (photoRef?.current) photoRef.current.scrollLeft += photoRef.current?.scrollWidth;
  }, [images]);

  const detectedInsectList: Partial<InsectDetectionModel>[] = [
    {
      name: "All",
      id: "all"
    },
    {
      name: "Pest",
      id: "pest"
    }
  ];
  if (deviceInfo?.allInsectDetections) {
    detectedInsectList.push(...deviceInfo?.allInsectDetections);
  }

  const handleChange = (event: SelectChangeEvent<string>) => {
    dispatch(selectDeviceInsect(event.target.value));
    if (!deviceId) {
      return;
    }
    dispatch(
      getDeviceInfoAsync({
        pathParams: {
          deviceId
        },
        params: {
          insect: event.target.value,
          startDate: dates.startDate,
          endDate: dates.endDate,
          source: sensorSource
        }
      })
    );
  };

  const handleOpen = (index: number) => {
    setSelectedImageIndex(index);
  };

  const handleInsectNameConfirmation = (event: SelectChangeEvent<string>) => {

    if (imageGallery?.current && deviceId) {
      setUpdatedInsects((updatedInsects) => {
        if (imageGallery?.current) {
          updatedInsects.set(imageGallery.current.getCurrentIndex(), event.target.value);
        }
        return updatedInsects;
      });
      dispatch(updateInsectNameAsync({
        deviceId,
        insectId: event.target.value,
        recordedAt: images[imageGallery.current.getCurrentIndex()].recordedAt
      })).then(() => {
        imageGallery?.current?.slideToIndex(imageGallery.current.getCurrentIndex() - 1);
      });
    }
  };

  const photoGallery = useMemo(
    () =>
      images
        .map((image, index) => {
          const thumbHeight = height * 0.2;
          return (
            <button
              key={`images_${image.name + index}`}
              onClick={() => handleOpen(index)}
              className="item"
              type="button"
            >
              <img
                className="image"
                loading={"lazy"}
                src={ImageManager.getThumbnailUrl(image.photo ?? "placeholder", thumbHeight)}
                alt="insect"
              />
              <p>{image.name}</p>
              <div>
                {image.photoDisplayRecordedAt.split("-").map((time, ind) => (
                  <div key={`${time}_${ind}`}>{time}</div>
                ))}
              </div>
            </button>
          );
        }),
    [images, height]
  );

  const onDownloadData = () => {
    if (deviceInfo && deviceId) {
      downloadCSV("insect", deviceInfo, deviceId);
    }
  };

  return (
    <Card title={t(deviceInsectTranslatePaths.title)}>
      <Box display={"flex"} flexDirection={"column"} justifyItems={"start"}>
        <Button
          sx={{
            width: "9rem"
          }}
          onClick={onDownloadData}
          disabled={insectChart?.allDetections?.length === 0}
        >Download Data</Button>
        <FormControl className="divider" sx={{ m: 1, minWidth: 270, maxWidth: 270 }}>
          <InputLabel id="demo-simple-select-label">
            {t(deviceInsectTranslatePaths.select_insects)}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={insect}
            label={t(deviceInsectTranslatePaths.select_insects)}
            onChange={handleChange}
          >
            {detectedInsectList.map((insectItem) => (
              <MenuItem key={`insects_${insectItem.id}`} value={insectItem.id}>
                {insectItem.name}
              </MenuItem>
            ))
            }
          </Select>
        </FormControl>
      </Box>

      {insectChart && <InsectChart insectChart={insectChart} />}
      {insectChart?.firstOccurrences &&
        insectChart.firstOccurrences.map((insectItem, index) => (
          <Typography
            sx={{ display: "block" }}
            component="span"
            variant="body2"
            color="text.primary"
            align="center"
            key={`first_occurrence_${insectItem.name + index}`}
          >
            {t(deviceInsectTranslatePaths.detected_first_time, {
              name: insectItem.name,
              date: insectItem.displayRecordedAt
            })}
          </Typography>
        ))}

      <div ref={photoRef} className="wrap">
        {photoGallery}
      </div>

      <Dialog
        fullScreen
        open={selectedImageIndex !== undefined}
        onClose={() => setSelectedImageIndex(undefined)}
      >
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => setSelectedImageIndex(undefined)}
        >
          Exit
        </Button>
        {isAdmin && <Box position={"absolute"} mt={"3rem"} zIndex={9999}>
          <FormControl className="divider" sx={{ m: 1, minWidth: 270, maxWidth: 270 }}>
            <InputLabel id="demo-simple-select-label">
              {t(deviceInsectTranslatePaths.select_update_insect_name)}
            </InputLabel>
            <Select
              labelId="update-insect-name-select-label"
              id="update-insect-name-select"
              value={getDisplayedInsectDetectionName(updatedInsects, images, imageGallery, inGallerySelectedImage)}
              label={t(deviceInsectTranslatePaths.select_insects)}
              onChange={handleInsectNameConfirmation}
            >
              {deviceInfo?.allInsectDetections?.map((insectItem) => (
                <MenuItem key={`insects_${insectItem.id}`} value={insectItem.id}>
                  {insectItem.name}
                </MenuItem>
              ))
              }
            </Select>
          </FormControl>
        </Box>}
        <ImageGallery
          startIndex={selectedImageIndex}
          ref={imageGallery}
          lazyLoad={true}
          disableThumbnailScroll={false}
          infinite={false}
          disableSwipe={false}
          onSlide={(index) => {
            setInGallerySelectedImage(index);
          }}
          renderItem={(item) => {
            return <Box mt={"2rem"} mb={"3rem"}>
              <img
                height={400}
                className="image"
                loading={"lazy"}
                src={item.original}
                alt="insect"
              />
              <Box mt={"1rem"}>
                <Typography
                  sx={{ display: "block" }}
                  component="span"
                  variant="h4"
                  color="text.primary"
                  align="center"
                  key={`first_occurrence_${item.originalTitle}`}
                >
                  {item.originalTitle}
                </Typography>
                {isAdmin && <Box mr={"0.5rem"}>
                  {/*the insect id source is set to the originalClass field. this is hacky and a better way should be found*/}
                  <Typography>Id source: {item.originalClass}</Typography>
                </Box>}
                <Typography
                  sx={{ display: "block" }}
                  component="span"
                  variant="h5"
                  color="text.primary"
                  align="center"
                  key={`first_occurrence_${item.originalTitle}_description`}
                >
                  {item.description}
                </Typography>
              </Box>
            </Box>;
          }}
          items={images.map((img) => {
            const originalHeight = 300;
            return {
              original: ImageManager.getFullImageUrl(img.photo ?? "placeholder"),
              thumbnail: ImageManager.getThumbnailUrl(img.photo ?? "placeholder", 100, 100),
              originalTitle: img.name,
              // the insect id source is set to the originalClass field. this is hacky and a better way should be found
              originalClass: img.idSource,
              description: img.photoDisplayRecordedAt,
              thumbnailTitle: img.name,
              thumbnailHeight: 100,
              thumbnailWidth: 100,
              originalHeight
            };
          })}
        />
      </Dialog>
    </Card>
  );
};
