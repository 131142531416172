import React from 'react';
import { useHistory } from 'react-router-dom';

import { LoginComponent } from './components/LoginComponent';
import { clearState } from './store';
import { useAppDispatch } from '../../stores/hooks';
import { getTokenLocalStorage } from '../../helpers/localStorage';

export const LoginPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  if (getTokenLocalStorage()) {
    dispatch(clearState());
    history.push('/');
  }
  return <LoginComponent />;
};
