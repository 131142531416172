import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { getLanguageLocalStorage } from '../../../../helpers/localStorage';
import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../../stores/types';
import { getInsectDTO } from '../../dto/getInsects.dto';

type GetInsectListRequestParams = { sort?: string; sortDirection?: string; search?: string };

export const getInsectListAsync = createAsyncThunk<
  getInsectDTO,
  GetInsectListRequestParams | void,
  ThunkConfig
>(
  'crop/getCrops',

  async (params: GetInsectListRequestParams | void, { rejectWithValue }) => {
    try {
      const language = getLanguageLocalStorage();

      const { data } = await axios.get<{ data: getInsectDTO }>(`/private/insect/list-insect`, {
        headers: {
          'Accept-Language': language,
        },
        params,
      });

      return data.data;
    } catch (err) {
      return rejectWithValue(ErrorChecker(err));
    }
  },
);
