import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';
import { getOrganizationIdLocalStorage } from '../../../../helpers/localStorage';

import type { getPheromoneLogDTO, getPheromoneLogRequestDTO } from '../../dto/getPheromoneLog.dto';
import type { ThunkConfig } from '../../../../stores/types';

export const getPheromoneLogAsync = createAsyncThunk<
  getPheromoneLogDTO | null,
  getPheromoneLogRequestDTO,
  ThunkConfig
>('pheromones/get-pheromone-log', async ({ deviceId }, { rejectWithValue }) => {
  const organizationId = getOrganizationIdLocalStorage();

  try {
    const { data } = await axios.get<{ data: getPheromoneLogDTO }>(
      `/private/organizations/${organizationId}/devices/${deviceId}/pheromone-log`,
    );

    return data.data;
  } catch (err) {
    return rejectWithValue(ErrorChecker(err));
  }
});
