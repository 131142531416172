import { DocProperty, InheritedDoc } from 'typescript-openapi-router';
import { ActiveAlertSentModel } from '../model/active-alert-sent-model';
import { ResponseEnvelope } from './response-envelope';

@InheritedDoc()
export class AlertSentListResponse extends ResponseEnvelope {
  @DocProperty({
    type: 'array',
    objectType: ActiveAlertSentModel.name,
    isRequired: false,
  })
  data?: ActiveAlertSentModel[];
}
