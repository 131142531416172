import React, { FC, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import { DevicePage } from 'src/app/pages/Device';
import { CropsPage } from 'src/app/pages/Crops';
import { InsectsPage } from 'src/app/pages/Insect';
import { Dashboard } from '../../../pages/Dashboard';
import { DeviceData } from '../../../pages/DeviceData';
import { Alerts } from '../../../pages/Alerts';
import { ManageDevices } from '../../../pages/ManageDevices';
import { SetUpAlerts } from '../../../pages/SetUpAlerts';
import { Pheromones } from '../../../pages/Pheromones';
import { useAppDispatch, useAppSelector } from '../../../stores/hooks';
import { MainLayout } from '../../components/MainLayout';
import { getAllCropsAsync, getAllDiseasesAsync, getAllOrganizationsAsync } from "../../store/actions";
import { mainSelector } from '../../store/selectors';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { getAllDevicesAsync, getMyDevicesAsync } from '../../../pages/ManageDevices/store/actions';
import { UsersPage } from '../../../pages/Users';
import { ProtectedRoute } from '../../components/ProtectedRoute';
import { NotFoundPage } from '../../../components/NotFoundPage';
import { OrganizationPage } from 'src/app/pages/organization';

export const MainRouter: FC = () => {
  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector(mainSelector);

  useScrollToTop();

  useEffect(() => {
    dispatch(getAllCropsAsync());
    dispatch(getAllOrganizationsAsync());
    dispatch(getAllDevicesAsync());
    dispatch(getMyDevicesAsync());
    dispatch(getAllDiseasesAsync());
  }, []);

  return (
    <MainLayout isLoading={isLoading}>
      <Switch>
        <Route exact component={Dashboard} path="/" />
        <Route component={ManageDevices} path="/manage-devices" />
        <Route component={DeviceData} path="/device-data" />
        <Route component={Alerts} path="/alerts" />
        <Route component={SetUpAlerts} path="/set-up-alerts" />
        <Route component={Pheromones} path="/pheromones" />
        <ProtectedRoute component={UsersPage} path="/users" />
        <ProtectedRoute component={DevicePage} path="/devices" />
        <ProtectedRoute component={CropsPage} path="/crops" />
        <ProtectedRoute component={InsectsPage} path="/insects" />
        <ProtectedRoute component={OrganizationPage} path="/organization" />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </MainLayout>
  );
};
