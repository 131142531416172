import { DocProperty, InheritedDoc } from 'typescript-openapi-router';
import { ResponseEnvelope } from './response-envelope';
import { DeviceModel } from '../model';

@InheritedDoc()
export class OrganizationDevicesResponseBody extends ResponseEnvelope {

  @DocProperty({
    type: 'object',
    objectType: DeviceModel.name,
    isRequired: false
  })
  data?: DeviceModel[];
}
