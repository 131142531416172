import { DocProperty } from 'typescript-openapi-router';
import { PheromoneModel } from '../model/pheromone-model';


export class EditPheromoneRequestBody {
  @DocProperty({
    type: 'object',
    objectType: PheromoneModel.name,
    isRequired: true,
  })
  data: PheromoneModel;
}
