import { DocProperty, InheritedDoc } from 'typescript-openapi-router';
import { ResponseEnvelope } from '.';
import { DeviceResponseModel } from '../model/device-response-model';


@InheritedDoc()
export class ListOfDeviceResponseBody extends ResponseEnvelope {
  @DocProperty({
    type: 'array',
    objectType: DeviceResponseModel.name,
    isRequired: false,
  })
  data?: DeviceResponseModel[];
}
