import { DocProperty } from 'typescript-openapi-router';

export class EditDeviceRequestBody {
  @DocProperty({
    type: 'number',
    isRequired: false,
  })
  organizationId?: number;

  @DocProperty({
    type: 'string',
    isRequired: false,
  })
  comment: string;
}
