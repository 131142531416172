import {DocProperty} from 'typescript-openapi-router';
import {InsectModel} from './insect-model';
import {DiseaseModel} from './disease-model';

export class CropModel {
  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  id: string;

  @DocProperty({
    type: 'string',
    isRequired: true,
  })
  name: string;

  @DocProperty({
    type: 'array',
    objectType: InsectModel.name,
    isRequired: false,
  })
  insects?: InsectModel[];

  @DocProperty({
    type: 'array',
    objectType: DiseaseModel.name,
    isRequired: false,
  })
  diseases?: DiseaseModel[];
}
