import { useEffect, useState } from 'react';

import type { ICrops } from '../interfaces';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSeparateCropsPerPage = (data: any) => {
  const [cropsForAllPages, setCropsForAllPages] = useState<ICrops[][]>([]);

  useEffect(() => {
    const chunksArray: ICrops[][] = [];
    for (let i = 0; i < data.cropList?.length; i += data.rowsPerPage) {
      const chunk = data.cropList.slice(i, i + data.rowsPerPage);

      chunksArray.push(chunk);
    }

    setCropsForAllPages(chunksArray);
  }, [data.rowsPerPage, data.cropList]);

  return {
    cropForAllPages: cropsForAllPages,
  };
};
