import React, { useEffect, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { clearMyDevicesState } from '../../store/myDevicesListSlice';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../../components/Modal';
import { AddButtons } from '../../../../components/AddButtons';
import { Card } from '../../../../components/widgets/Card';
import { useAppDispatch, useAppSelector } from '../../../../stores/hooks';
import { myDeviseListSelector } from '../../store/selectors';
import { addMyDevicesAsync } from '../../store/actions';
import { addDeviceTranslatePaths } from '../../translations';
import { mainSelector } from '../../../../main/store/selectors';
import { MIN_CARD_WIDTH } from '../../constants';

import type { TCropIdUnion } from '../../../../interfaces/crop';

import QRCodeImage from '../../../../../assets/qr-code.png';
import NfcImage from '../../../../../assets/nfc.png';
import styles from './styles.module.scss';

export const AddDevice = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { t } = useTranslation();

  const queryParams = window.location.search.replace('?', '').split('=');
  const indexSerial = queryParams.findIndex((el) => el === 'serial_number');

  const { crops } = useAppSelector(mainSelector);
  const { isAdding, isSuccessAdded, isFailedToAdd } = useAppSelector(myDeviseListSelector);

  const [cropId, setCropId] = useState<TCropIdUnion>();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [serialNumber, setSerialNumber] = useState(
    indexSerial >= 0 ? queryParams[indexSerial + 1] : '',
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSerialNumber(event.target.value);
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleChangeCropId = (event: SelectChangeEvent<TCropIdUnion | ''>) => {
    setCropId(event.target.value as TCropIdUnion);
  };

  useEffect(
    () => () => {
      dispatch(clearMyDevicesState());
    },
    [],
  );

  useEffect(() => {
    if (isFailedToAdd) {
      dispatch(clearMyDevicesState());
    }

    if (isSuccessAdded) {
      setOpen(true);
      dispatch(clearMyDevicesState());
    }
  }, [isFailedToAdd, isSuccessAdded]);

  const handleCreate = () => {
    dispatch(
      addMyDevicesAsync({
        body: {
          serialNumber,
          alias: name,
          cropId,
        },
      }),
    );
  };
  const handleClear = () => {
    setSerialNumber('');
  };

  const handleClose = () => {
    setOpen(false);
    history.push('/manage-devices/my-devices');
  };

  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="baseline"
        spacing={1}
        className={styles.add_device}
      >
        <Card minWidth={MIN_CARD_WIDTH}>
          <p className={styles.header_text}>{t(addDeviceTranslatePaths.header)}</p>
          <div className={styles.add_item}>
            <span>{t(addDeviceTranslatePaths.nfc)}</span>
            <img className={styles.image} src={NfcImage} alt="nfc" />
          </div>

          <div className={styles.add_item}>
            <span>{t(addDeviceTranslatePaths.qr)}</span>
            <img className={styles.image} src={QRCodeImage} alt="qr" />
          </div>
        </Card>

        <Card minWidth={MIN_CARD_WIDTH}>
          <div className={styles.add_item}>
            <span className={styles.header_text}>{t(addDeviceTranslatePaths.serial_number)}</span>
            <TextField
              id="outlined-name"
              label={t(addDeviceTranslatePaths.serial_number_placeholder)}
              value={serialNumber}
              onChange={handleChange}
            />
          </div>
        </Card>

        <Card minWidth={MIN_CARD_WIDTH}>
          <div className={styles.add_item}>
            <span className={styles.header_text}>{t(addDeviceTranslatePaths.name_device)}</span>
            <TextField
              id="outlined-name"
              label={t(addDeviceTranslatePaths.name_device_placeholder)}
              value={name}
              onChange={handleChangeName}
            />
          </div>
        </Card>

        <Card minWidth={MIN_CARD_WIDTH}>
          <div className={styles.add_item}>
            <span className={styles.header_text}>{t(addDeviceTranslatePaths.crop)}</span>
            <FormControl style={{ width: 195 }}>
              <InputLabel id="simple-select-label">
                {t(addDeviceTranslatePaths.crop_select_label)}
              </InputLabel>
              <Select
                labelId="simple-select-label"
                id="simple-select"
                value={cropId ?? ''}
                label={t(addDeviceTranslatePaths.crop_select_label)}
                onChange={handleChangeCropId}
              >
                {crops.map((c, index) => (
                  <MenuItem key={`${c.id}_${index}`} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Card>

        <AddButtons
          toSave={Boolean(serialNumber)}
          pending={isAdding}
          clear={handleClear}
          handleCreate={handleCreate}
        />
      </Stack>

      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t(addDeviceTranslatePaths.added)}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {t(addDeviceTranslatePaths.device)}
            {`${serialNumber}`}
            {t(addDeviceTranslatePaths.great)}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t(addDeviceTranslatePaths.ok)}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};
