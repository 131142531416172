import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { getLanguageLocalStorage } from '../../../../helpers/localStorage';
import { ErrorChecker } from '../../../../stores/helpers/ErrorChecker';

import type { ThunkConfig } from '../../../../stores/types';
import { UserModel } from "@fly-workspace/lib-api-interface";

type sort = { sort?: string; sortDirection?: string; search?: string };
export const getUsersListAsync = createAsyncThunk<UserModel[], sort | void, ThunkConfig>(
  'users/getUsers',

  async (params: sort | void, { rejectWithValue }) => {
    try {
      const language = getLanguageLocalStorage();

      const { data } = await axios.get<{ data: UserModel[] }>(`/private/user/get`, {
        headers: {
          'Accept-Language': language,
        },
        params,
      });

      return data.data;
    } catch (err) {
      return rejectWithValue(ErrorChecker(err));
    }
  },
);
