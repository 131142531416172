import React, { FC, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import * as Styled from "./styled";
import { mainTranslatePaths } from "../../../translations/paths";
import { adminPageNames, EAdminPagesKeys, EPagesKeys, pageNames } from "./constants";
import { SelectDevice } from "./components/SelectDevice";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { NavbarDrawer } from "./components/NavbarDrawer";
import { mainAdminTranslatePaths } from "../../../translations/paths/main";

import logo from "../../../assets/logo.png";
import styles from "./styles.module.scss";

export const Navbar: FC = () => {
  const params = useParams();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();


  const [isOpen, setIsOpen] = useState(false);
  const [pageName, setPageName] = useState<{
    name: string;
    key: EPagesKeys | EAdminPagesKeys;
  }>();

  const cn = {
    header_burger: classNames(styles.header__burger, { [styles.disabled]: isOpen })
  };

  const handleDrawerOpen = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (pathname === "/") {
      setPageName(t(mainTranslatePaths.dashboard));
    }

    const newPageName = pageNames.find((p) => p.route.split("/")[1] === pathname.split("/")[1]);
    const newAdminPageName = adminPageNames.find((p) => p.route.startsWith(pathname));

    const updatedPageName = () => {
      if (newPageName) {
        return {
          name: t(mainTranslatePaths[newPageName.key]),
          key: newPageName.key
        };
      }

      if (newAdminPageName) {
        return {
          name: t(mainAdminTranslatePaths[newAdminPageName.key]),
          key: newAdminPageName.key
        };
      }

      return {
        name: "404",
        key: EPagesKeys.dashboard
      };
    };

    setPageName(updatedPageName());
  }, [t, params, pathname]);

  const renderSelectDevice = (isDesktop: boolean) => {
    if ((pageName?.key === EPagesKeys.device_data || pageName?.key === EPagesKeys.alerts) && isDesktop) {
      return <SelectDevice isDateRangeSelectorOn={true} isDeviceDeviceSelectorOn={true} />;
    }
    if ((pageName?.key === EPagesKeys.pheromones
        || pageName?.key === EPagesKeys.manage_devices
        || pageName?.key === EPagesKeys.dashboard) &&
      isDesktop) {
      return <SelectDevice />;
    }
    if ((pageName?.key === EPagesKeys.set_up_alerts) && isDesktop) {
      return <SelectDevice isDeviceDeviceSelectorOn={true} />;
    }
    return null;
  };

  return (
    <Box className={styles.header}>
      <Styled.AppBar isOpen={isOpen}>
        <Toolbar className={styles.header__toolbar}>
          <div className={styles.header__logo_wrapper}>
            <img className={styles.header__logo} src={logo} alt="logo" />
            {renderSelectDevice(width > 900)}
            <Typography className={styles.header__title} variant="h6">
              {pageName?.name}
            </Typography>
          </div>
          <IconButton
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            className={cn.header_burger}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
        {renderSelectDevice(width <= 900)}

      </Styled.AppBar>
      <NavbarDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
    </Box>
  );
};
